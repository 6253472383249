import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Card, Icon } from "@clevertrack/shared"
import { format } from "date-fns"
import orderBy from "lodash/orderBy"
import { Hint, HintIcon } from "app/Hint"
import { IconSizeEnum } from "lib/Icon"
import { useTranslation } from "react-i18next"
import {
  NotificationType,
  PlatformStatusNotificationProps,
} from "../notificationcenter.types"
import {
  PlatformStatusEnum,
  PlatformStatusHintEnum,
  PlatformStatusRed,
  PlatformStatusYellow,
} from "./platformstatus.types"

const StyledPlatformStatusNotification = styled(Card)`
  ${tw`overflow-hidden mt-4 p-0`}
  header {
    ${tw`m-0 bg-current p-4 text-xl`}
    h4 {
      ${tw`text-white m-0`}

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }

  .date {
    ${tw`text-lg flex justify-between`}
  }

  .content {
    ${tw`p-4 pt-6 text-xl text-brand-black-base`}

    > p {
      ${tw`text-xl leading-snug m-0 mb-4`}
    }

    .providerTitle {
    }

    .providerDescription {
      ${tw`bg-brand-gray-lighter p-4 mb-8`}
      > p {
        ${tw`text-lg text-brand-black-base leading-snug m-0 mb-4`}

        strong {
          ${tw`mb-4 text-xl`}
        }

        time {
          ${tw`mt-2 text-right block opacity-60`}
        }
      }
    }

    .hint {
      > p {
        ${tw`shrink grow-0`}
      }
    }
  }
`

export const PlatformStatus: React.FC<PlatformStatusNotificationProps> = ({
  ...props
}) => {
  const { t } = useTranslation()
  const icon =
    props.category === NotificationType.SYSTEM_MESSAGE ? "cog" : "signal-slash"

  return (
    <StyledPlatformStatusNotification
      size="sm"
      css={
        !props.isCurrent
          ? [tw`text-brand-gray-base`]
          : props.category === NotificationType.SYSTEM_MESSAGE
          ? [tw`text-brand-blue-50`]
          : PlatformStatusYellow.includes(props.platformStatus)
          ? [tw`text-yellow-500`]
          : PlatformStatusRed.includes(props.platformStatus)
          ? [tw`text-red-500`]
          : [tw`text-green-500`]
      }
    >
      {props.title && (
        <header>
          <h4 tw="flex items-center">
            <Icon icon={icon} size={IconSizeEnum.MD} />
            <span tw="ml-2">{t(props.title)}</span>
            {!props.isCurrent && (
              <span tw="italic ml-auto inline-block opacity-60">
                {t("common.archived")}
              </span>
            )}
          </h4>
        </header>
      )}
      <div className="content">
        {props.body && <p>{t(props.body)}</p>}
        {props.providerDescription && (
          <>
            <span tw="block opacity-80 my-8 text-xl">
              <span tw="font-bold">{t("notification.provider_update")}</span>
            </span>
            {typeof props.providerDescription === "string" ? (
              <div
                className="providerDescription"
                tw="block mt-2"
                dangerouslySetInnerHTML={{ __html: props.providerDescription }}
              />
            ) : (
              <div className="providerDescription" tw="block mt-2">
                <p>
                  <strong>{props.providerTitle}</strong>
                </p>
                {orderBy(props.providerDescription, "timestamp", "desc").map(
                  ({ body, timestamp }) => {
                    return (
                      <p key={`providerDesc_${timestamp}`}>
                        <span>{t(body)}</span>
                        <time>
                          {format(
                            new Date(timestamp),
                            `'${t("common.updated")}' dd.MM.yyyy '${t(
                              "common.at"
                            )}' HH:mm`
                          )}
                        </time>
                      </p>
                    )
                  }
                )}
              </div>
            )}
          </>
        )}
        {props.timestamp && (
          <span className="date">
            <span>
              {format(
                new Date(props.timestamp),
                `'${t("common.updated")}' dd.MM.yyyy '${t("common.at")}' HH:mm`
              )}
            </span>
            {props.category === "PlatformStatus" && (
              <HintIcon type={PlatformStatusHintEnum.DataSecurity} />
            )}
          </span>
        )}
        {props.category === "PlatformStatus" && (
          <Hint
            className="hint"
            tw="mt-4"
            type={PlatformStatusHintEnum.DataSecurity}
            toggled={true}
          />
        )}
      </div>
    </StyledPlatformStatusNotification>
  )
}
