import React, { useContext, useState } from "react"
import { UserTypes } from "../../app/User/actions"
import { navigate } from "gatsby"
import { UserContext } from "app/User/context"
import { Button, Icon } from "@clevertrack/shared"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import tw from "twin.macro"
import { useTranslation } from "react-i18next"
import { updateUser } from "services/user"
import { IconSizeEnum } from "lib/Icon"
const ModalOverlay = styled.div`
  ${tw`fixed inset-0 bg-black/50 z-40`}
`

const ModalContainer = styled.div`
  ${tw`fixed inset-0 w-screen h-screen flex items-center justify-center p-4 z-40`}
`

const StyledContent = styled.div`
  ${tw`bg-white shadow-xl w-[90%] mx-auto overflow-hidden min-h-[450px]`}

  ${(props) => props.theme.media.tablet_portrait_up`
    ${tw`w-[85%]`}
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    ${tw`w-[75%]`}
  `}

  ${(props) => props.theme.media.desktop_up`
    ${tw`w-[600px]`}
  `}
`

const ContentWrapper = styled.div`
  ${tw`p-12 flex flex-col items-center text-center justify-center min-h-[450px] relative`}
`

const FlagButton = styled.button.attrs({
  type: "button",
})<{ isSelected?: boolean }>`
  ${tw`cursor-pointer border-0 p-0 w-16 h-16 overflow-hidden rounded-full`}
  opacity: ${(props) => (props.isSelected ? 1 : 0.4)};
  &:hover {
    opacity: ${(props) => (props.isSelected ? 1 : 0.6)};
  }
  transition: opacity 0.2s ease-in-out;

  svg {
    ${tw`w-full h-full object-cover`}
  }
`

const FlagContainer = styled.div`
  ${tw`flex gap-6 items-center justify-center`}
`

const Title = styled.h2`
  ${tw`text-5xl font-bold text-gray-900 mb-8`}
`

const Description = styled.p`
  ${tw`text-2xl text-gray-600 mb-6 leading-relaxed max-w-[90%]`}

  ${(props) => props.theme.media.tablet_portrait_up`
    ${tw`text-2xl`}
  `}

  &:last-of-type {
    ${tw`mb-12`}
  }

  strong {
    ${tw`text-gray-900`}
  }
`

const LanguageSelection = styled.div`
  ${tw`flex flex-col items-center mb-12`}
`

const LanguageSelectionTitle = styled.h3`
  ${tw`text-2xl text-gray-600 mb-6 leading-relaxed`}
`

const StyledButton = styled(Button)`
  ${tw`w-full py-5 text-2xl font-semibold max-w-[90%]`}
`

export function Wizard() {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext)
  const { t, i18n } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState("da-DK")

  const handleWizardComplete = async () => {
    try {
      await updateUser(user.id, {
        ...user,
        last_login: new Date().toISOString(),
      })

      dispatch({
        type: UserTypes.SetUser,
        payload: {
          user: {
            ...user,
            last_login: new Date().toISOString(),
          },
        },
      })

      navigate("/app")
    } catch (error) {
      console.error("Failed to update user:", error)
    }
  }

  const handleLanguageChange = async (language: string) => {
    setSelectedLanguage(language)
    dispatch({
      type: UserTypes.UpdateUserLanguage,
      payload: language,
    })

    await i18n.changeLanguage(language)

    try {
      await updateUser(user.id, {
        ...user,
        language: language,
      })
    } catch (error) {
      console.error("Failed to update user:", error)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("wizard.title")}</title>
        <style type="text/css">{`
            nav, header { 
              display: none !important; 
            }
            body {
              background: white !important;
            }
          `}</style>
      </Helmet>
      <ModalOverlay aria-hidden="true" />
      <ModalContainer>
        <StyledContent>
          <ContentWrapper>
            <Title>
              {t("wizard.title")}, {user?.firstName || "User"}!
            </Title>
            <Description>{t("wizard.description")}</Description>
            <Description>
              {t("wizard.description_1")}{" "}
              <strong>{user?.is_admin ? "administrator" : "user"}</strong>,{" "}
              {t("wizard.description_2")}
            </Description>
            <LanguageSelection>
              <LanguageSelectionTitle>
                {t("wizard.description_3")}
              </LanguageSelectionTitle>
              <FlagContainer>
                <FlagButton
                  type="button"
                  isSelected={selectedLanguage === "da-DK"}
                  onClick={() => handleLanguageChange("da-DK")}
                >
                  <Icon icon="flag-danish" size={IconSizeEnum.XL} />
                </FlagButton>
                <FlagButton
                  type="button"
                  isSelected={selectedLanguage === "en-GB"}
                  onClick={() => handleLanguageChange("en-GB")}
                >
                  <Icon icon="flag-british" size={IconSizeEnum.XL} />
                </FlagButton>
              </FlagContainer>
            </LanguageSelection>
            <StyledButton variant="primary" onClick={handleWizardComplete}>
              {t("wizard.button")}
            </StyledButton>
          </ContentWrapper>
        </StyledContent>
      </ModalContainer>
    </>
  )
}
