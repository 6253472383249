import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Result } from "app/ResultListGroup/Result"
import Icon from "lib/Icon"
import { useUser } from "app/User/hooks"
import { useTranslation } from "react-i18next"
const StyledResult = styled(Result)`
  // font-weight: bold;
  box-shadow: none;
  position: relative;
  margin-top: 2rem;
  ${tw`border-dashed border border-gray-300 hover:border-transparent flex items-center text-xl py-4`}
  background: ${(props) => props.theme.colors.grayLighter};

  &:hover {
    box-shadow: ${(props) => props.theme.card.boxShadow};
    background: white;
  }
`

const StyledReparation = styled(Result)`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  padding: 1.2rem 2rem;
  column-gap: 1rem;
`

const StyledRightIcon = styled(Icon)`
  position: relative;
  margin-left: 1rem;
`

const StyledLeftIcon = styled(Icon)`
  position: relative;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  margin-left: 0.5rem;
`

function CreateInterval({ onClick, reverse, title, type, ...props }) {
  const { t } = useTranslation()
  if (type === "reparation") {
    return (
      <StyledReparation onClick={onClick} {...props}>
        <span tw="flex items-center">
          <strong>{title || t("service.create_interval")}</strong>
          <span tw="flex ml-auto items-center justify-center hover:(text-brand-500)">
            <span tw="text-lg">{t("common.register")}</span>
            <StyledIcon icon="chevron-right" size="xs" />
          </span>
        </span>
      </StyledReparation>
    )
  }
  return (
    <StyledResult onClick={onClick} {...props}>
      {reverse && <StyledLeftIcon icon="plus" size="sm" />}
      <span>{title || t("service.create_interval")}</span>
      {!reverse && <StyledRightIcon icon="plus" size="sm" />}
    </StyledResult>
  )
}

export default CreateInterval
