/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"
import { PlatformStatusNotificationProps } from "./notificationcenter.types"

export enum NotificationTypes {
  SetPlatformUpdates = "Notifications/SetPlatformUpdates",
  Reset = "Notifications/Reset",
}

type Actions = {
  [NotificationTypes.SetPlatformUpdates]: {
    updates: PlatformStatusNotificationProps[]
  }
  [NotificationTypes.Reset]: null
}

export type NotificationActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const NotificationActions = createActions<Actions>()
