import React, { useState, useMemo } from "react"
import "twin.macro"
import { DeviceUpdateCriterium } from "routes/Alarms/alarm.types"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { Select } from "lib/Select"
import { FormField, Input } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"

interface IDeviceUpdateCriteriaProps {
  criterium: DeviceUpdateCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceUpdateCriteriaElement extends IDeviceUpdateCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

const DurationOption = ({ innerProps, innerRef, data }) => {
  return (
    <div
      tw="px-4 py-4 hover:bg-brand-gray-lighter cursor-pointer"
      ref={innerRef}
      {...innerProps}
    >
      <span tw="block text-2xl">{data.label}</span>
    </div>
  )
}

export const DeviceUpdateCriteriaElement: React.FC<IDeviceUpdateCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  const { t } = useTranslation()
  const days = criterium.duration / (3600 * 24)
  return (
    <AlarmConfigurationComponent>
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">{t("alarms.device_update_title")}</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">{t("common.cancel")}</span>
            </>
          ) : (
            <>
              <span tw="text-lg">{t("common.edit")}</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">
        {t("alarms.update_more_than")} {days}{" "}
        {days === 1
          ? t("common.day_decapitalize")
          : t("common.days_decapitalize")}{" "}
        {t("common.ago_decapitalize")}
      </span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceUpdateCriteria: React.FC<IDeviceUpdateCriteriaProps> = ({
  criterium,
  onSave,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation()

  const durationOptions = useMemo(
    () => [
      {
        label: t("alarms.device_update_duration_1d"),
        value: 1,
      },
      {
        label: t("alarms.device_update_duration_3d"),
        value: 3,
      },
      {
        label: t("alarms.device_update_duration_7d"),
        value: 7,
      },
      {
        label: t("alarms.device_update_duration_14d"),
        value: 14,
      },
      {
        label: t("alarms.device_update_duration_30d"),
        value: 30,
      },
    ],
    [t]
  )

  const [selectedDuration, setSelectedDuration] = useState<number | null>(
    durationOptions.find(
      (duration) => duration.value === criterium.duration / (3600 * 24)
    )?.value ?? null
  )

  const onSaveHandler = () => {
    if (onSave && selectedDuration)
      onSave({
        ...criterium,
        duration: selectedDuration * 3600 * 24,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>{t("alarms.device_update_title")}</CriteriumTypeTitle>
      <p>{t("alarms.device_update_description")}</p>
      <FormField label={t("alarms.device_update_select_duration")} tw="mt-4">
        <Select
          components={{ Option: DurationOption }}
          options={durationOptions}
          value={durationOptions.find((opt) => opt.value === selectedDuration)}
          placeholder={t("alarms.device_update_select_duration")}
          onChange={(opt) => setSelectedDuration(opt.value)}
          style={{ zIndex: 600 }}
        />
      </FormField>
      <p>{t("alarms.device_update_or_manual")}</p>
      <FormField label={t("alarms.device_update_enter_duration")} tw="mt-4">
        <Input
          type="number"
          min="1"
          value={selectedDuration}
          onChange={(e) => setSelectedDuration(e.target.value)}
        />
      </FormField>
      {onSave && onCancel && (
        <EditorButtonGroup onSave={onSaveHandler} onCancel={onCancelHandler} />
      )}
    </>
  )
}
