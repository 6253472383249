import { formatFloat } from "utils"
import { Category, BatteryChargingStatusEnum } from "./widget.types"
import { formatSecondsToDuration, formatDecimalTime } from "utils/datetime"
import { isDate, isValid, parse, parseISO } from "date-fns"

const general_category: Category = [
  {
    sortOrder: 3,
    key: "lastSeenBy",
    translationKey: "lastSeenBy",
    icon: "search",
    unsetValue: null,
  },
  {
    sortOrder: 5,
    key: "batteryRange",
    translationKey: "batteryRange",
    icon: "battery-three-quarters",
    unsetValue: null,
  },
  {
    sortOrder: 6,
    key: "chargingStatus",
    translationKey: "chargingStatus",
    icon: "charging-station",
    unsetValue: null,
    formatDataFnc: (input: string) => {
      const [chargingStatus, connectionStatus] = input.split(
        ","
      ) as BatteryChargingStatusEnum[]
      if (chargingStatus === BatteryChargingStatusEnum.CHARGING) {
        return "Lader"
      } else if (chargingStatus === BatteryChargingStatusEnum.NOT_CHARGING) {
        if (connectionStatus === BatteryChargingStatusEnum.DISCONNECTED) {
          return "Ikke forbundet"
        } else if (connectionStatus === BatteryChargingStatusEnum.CONNECTED) {
          return "Forbundet, lader ikke"
        }
      }
    },
  },
  {
    sortOrder: 4,
    key: "batteryLongevity",
    translationKey: "batteryLongevity",
    icon: "battery-full-vertical",
    unsetValue: null,
    formatDataFnc: (input: string) => {
      const batteryLevel = parseInt(input, 0)
      if (batteryLevel >= 15) {
        return `OK`
      }
      return null
    },
  },
  {
    sortOrder: 7,
    key: "batteryLongevityVolt72",
    translationKey: "batteryLongevity",
    icon: "battery-full-vertical",
    unsetValue: null,
    formatDataFnc: (input: string) => {
      const batteryLevel = parseInt(input, 0)
      if (batteryLevel >= 15) {
        return `OK`
      }
      return null
    },
  },
]

const activity_today_category: Category = [
  {
    key: "firstStartToday",
    translationKey: "firstStartToday",
    icon: "play",
    unsetValue: "tracker.no_activity",
    sortOrder: 1,
  },
  {
    key: "lastIgnitionStop",
    translationKey: "latestStopToday",
    icon: "stop",
    unsetValue: "tracker.no_activity",
    sortOrder: 2,
  },
  {
    key: "drivingHoursToday",
    translationKey: "drivingHoursToday",
    icon: "steering-wheel",
    unsetValue: "tracker.no_driving",
  },
  {
    key: "idleHoursToday",
    translationKey: "idleHoursToday",
    icon: "slow-mo-video",
    unsetValue: "tracker.no_idle",
  },
  {
    key: "stopHoursToday",
    translationKey: "stopHoursToday",
    icon: "parking",
    unsetValue: "tracker.no_idle",
  },
  {
    key: "dailyDistanceToday",
    translationKey: "dailyDistanceToday",
    icon: "route",
    unit: " km",
    unsetValue: "tracker.no_distance",
    formatDataFnc: (input) => {
      return (+input).toFixed(1)
    },
  },
  {
    key: "engineHoursToday",
    translationKey: "engineHoursToday",
    icon: "timer",
    unsetValue: "tracker.no_engine_hours",
    formatDataFnc: (input) => formatDecimalTime(input),
  },
  {
    key: "fuelConsumptionToday",
    translationKey: "fuelConsumptionToday",
    icon: "droplet-solid",
    unit: " l",
    unsetValue: "tracker.no_fuel_consumption",
  },
]

interface ServiceStatusTranslations {
  hours?: string;
  notConfigured?: string;
}

const DEFAULT_TRANSLATIONS = {
  hours: 'timer',
  notConfigured: 'Ikke opsat'
} as const;

const createServiceStatusCategory = (translations: ServiceStatusTranslations = {}): Category => {
  const { hours, notConfigured } = { ...DEFAULT_TRANSLATIONS, ...translations };

  return [
    {
      sortOrder: 1,
      key: "totalDistance",
      translationKey: "totalDistance",
      icon: "sigma",
      unit: " km",
      unsetValue: null,
      formatDataFnc: (input) => {
        return formatFloat(Number(input).toFixed(1), "da")
      },
    },
    {
      sortOrder: 2,
      key: "totalEngineHours",
      translationKey: "totalEngineHours",
      icon: "timer",
      unsetValue: null,
      formatDataFnc: (input) => {
        return formatFloat(Number(input).toFixed(), "da") + ` ${hours}`
      },
    },
    {
      sortOrder: 3,
      key: "lastService",
      translationKey: "lastService",
      icon: "check",
      unsetValue: notConfigured,
    },
    {
      sortOrder: 4,
      key: "nextService",
      translationKey: "nextService",
      icon: "car-mechanic",
      unsetValue: notConfigured,
      formatDataFnc: (input) => {
        const stringParts = input.split(" ").map((str) => {
          if (!isNaN(Number(str))) {
            return formatFloat(Number(str).toFixed(), "da")
          }
          if (isValid(parse(str, "dd-MM-yyyy", new Date()))) {
            return `d. ${str.replace(/-/g, ".")}`
          }
          return str
        })
        return stringParts.join(" ")
      },
    },
  ]
}

const measurements_category: Category = [
  {
    key: "temperature",
    translationKey: "temperature",
    icon: "thermometer-half",
    unit: " °C",
    unsetValue: null,
  },
  {
    key: "humidity",
    translationKey: "humidity",
    icon: "humidity",
    unit: "%",
    unsetValue: null,
  },
]

const history_category: Category = [
  {
    key: "totalIdleHours",
    translationKey: "totalIdleHours",
    icon: "slow-mo-video",
    unsetValue: null,
    type: "string",
  },
  {
    key: "totalFuel",
    translationKey: "fuelConsumptionToday",
    icon: "droplet-solid",
    unit: " l",
    type: "string",
    unsetValue: null,
    formatDataFnc: (input) => {
      return (+input).toFixed(1)
    },
  },
  {
    key: "totalDrivingHours",
    translationKey: "totalDrivingHours",
    icon: "steering-wheel",
    type: "string",
    unsetValue: null,
  },
  {
    key: "totalEngineHours",
    translationKey: "totalEngineHours",
    icon: "timer",
    type: "string",
    unsetValue: null,
  },
  {
    key: "totalMovingHours",
    translationKey: "totalMovingHours",
    icon: "dolly-solid",
    type: "string",
    unsetValue: null,
  },
  {
    key: "totalDistance",
    translationKey: "totalTripDistance",
    icon: "route",
    unit: " km",
    unsetValue: null,
    formatDataFnc: (input) => {
      return (+input).toFixed(1)
    },
  },
]

const allCategories = (translations: ServiceStatusTranslations = {}): Category => [
  ...general_category,
  ...activity_today_category,
  ...createServiceStatusCategory(translations),
  ...measurements_category,
  ...history_category,
]

export {
  general_category,
  activity_today_category,
  createServiceStatusCategory,
  measurements_category,
  history_category,
  allCategories,
}
