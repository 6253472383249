import React, { useContext } from "react"
import { useMemo } from "react"
import "twin.macro"
import { IUser, UserTypeEnum } from "app/User/types"
import { StyledEditIcon } from "routes/Alarms/components/AlarmForm"
import { EditorPanelHeader } from "routes/Alarms/components/editor/Header"
import { Group as ListGroup } from "lib/List/Group"
import { StyledBaseResult } from "app/ResultListGroup/Result"
import FEATURE, { USERRIGHTS } from "data/featureFlags"
import { useTranslation } from "react-i18next"
import { UserContext } from "app/User/context"

type UserMeta = {
  title: string
  description: string
  icon: string
  type: UserTypeEnum
}

type DefaultUser = {
  user: IUser
  meta: UserMeta
}

export const UserTypeSelector: React.FC<{
  onSelectUserType: (user: IUser) => void
}> = ({ onSelectUserType }) => {
  const { t } = useTranslation()

  const {
    state: { user },
  } = useContext(UserContext)

  const defaultUserTypes = useMemo<DefaultUser[]>(
    () => [
      {
        user: {
          id: "default_admin",
          is_admin: 1,
          is_token_whitelisted: 1,
          active: 1,
          language: user?.language || "da-dk",
          feature: [],
        },
        meta: {
          title: t("user.type_admin"),
          description: t("user.type_admin_desc"),
          type: UserTypeEnum.Admin,
        },
      },
      {
        user: {
          id: "default_user",
          is_admin: 0,
          feature: [FEATURE.START, FEATURE.HISTORY, USERRIGHTS.WRITE],
          active: 1,
          language: user?.language || "da-dk",
        },
        meta: {
          title: t("user.type_user"),
          description: t("user.type_user_desc"),
          type: UserTypeEnum.User,
        },
      },
      {
        user: {
          id: "default_fitter",
          is_admin: 0,
          is_token_whitelisted: 1,
          active: 1,
          language: user?.language || "da-dk",
          feature: [
            FEATURE.START,
            FEATURE.SERVICE,
            FEATURE.GROUPS,
            FEATURE.DEVICEMOUNTING,
            USERRIGHTS.WRITE,
          ],
        },
        meta: {
          title: t("user.type_installer"),
          description: t("user.type_installer_desc"),
          type: UserTypeEnum.Installer,
        },
      },
    ],
    [t]
  )

  return (
    <>
      <EditorPanelHeader>{t("user.new_user")}</EditorPanelHeader>
      <ListGroup tw="space-y-4" as="div">
        {defaultUserTypes.map((user, i) => {
          return (
            <StyledBaseResult
              key={user.user.id}
              tw="border-b flex shadow-none p-4 px-6 border border-solid border-brand-gray-light hover:(shadow-card bg-white)"
              onClick={() => onSelectUserType(user.user)}
            >
              <span tw="flex flex-col flex-grow">
                <span tw="flex text-2xl">
                  <span tw="text-xl font-bold">{user.meta.title}</span>
                  <span
                    tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
                    onClick={() => onSelectUserType(user.user)}
                  >
                    <span tw="text-lg">{t("common.select")}</span>
                    <StyledEditIcon icon="chevron-right" size="sm" />
                  </span>
                </span>
                <span tw="block text-lg opacity-60">
                  {user.meta.description}
                </span>
              </span>
            </StyledBaseResult>
          )
        })}
      </ListGroup>
    </>
  )
}
