import React, { FC, useEffect } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { ZonesItem } from "./ZonesItem"
import { ZonesEmptyState } from "./ZonesEmptyState"
import { IZone } from "../zones.types"

interface ZonesVisibleSectionProps {
  visibleZones: IZone[]
  visibilityState: Record<string, boolean>
  isEditMode: boolean
  onToggleVisibility: (zoneId: string) => void
  onZoneClick: (zone: IZone) => void
  isZonesToggleOn: boolean
}

const VisibleZonesContainer = styled.div<{ isToggleOff: boolean }>`
  ${tw`flex flex-col flex-shrink-0`}
  ${({ isToggleOff }) => isToggleOff ? tw`opacity-50 pointer-events-none` : tw`opacity-100 pointer-events-auto`}
`

export const ZonesVisibleSection: FC<ZonesVisibleSectionProps> = ({
  visibleZones,
  visibilityState,
  isEditMode,
  onToggleVisibility,
  onZoneClick,
  isZonesToggleOn,
}) => {
  return (
    <VisibleZonesContainer isToggleOff={!isZonesToggleOn}>
      {visibleZones.length === 0 ? (
        <ZonesEmptyState isVisible={true} />
      ) : (
        visibleZones.map((zone) => (
          <ZonesItem
            key={zone.id}
            zone={zone}
            isVisible={visibilityState[zone.id!]}
            isEditMode={isEditMode}
            onToggleVisibility={onToggleVisibility}
            onZoneClick={onZoneClick}
          />
        ))
      )}
    </VisibleZonesContainer>
  )
}
