import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react"
import styled from "styled-components"
import tw from "twin.macro"
import isEqual from "lodash-es/isEqual"
import { forceCheck } from "react-lazyload"
import { Group } from "lib/List/Group"
import { Responsive } from "@clevertrack/shared"
import Search from "app/Search"
import TrackerGroups from "app/TrackerGroups"
import TileContainer from "app/TileContainer"
import Tile from "app/Tile"
import TrackerItem from "app/TrackerItem"
import { freetextSearch } from "app/Search/helper"
import { SearchContext } from "app/Search/context"
import PopUnder from "app/PopUnder"

import { IconFlip } from "app/IconFlip"
import { useTrackerSearch } from "./hooks"
import { useTrackerGroups } from "app/TrackerGroups/hooks"
import { SizeEnum } from "theme"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { useDevices } from "app/Device/hooks"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { resetSearch } from "app/Search/actions"
import useDevicesStore from "app/Device/store"
import { useTranslation } from "react-i18next"
import { sortTrackers } from "app/Device/helper"

const StyledSearch = styled(Search)`
  ${(props) => props.theme.media.tablet_landscape_up`

    .pad {
      padding: 1rem;
    }
  `}
`

const StyledPopUnder = styled(PopUnder)`
  opacity: 1;
  z-index: 100;
  background: white;

  ${(props) =>
    props.simple
      ? `top: 0; padding-top: ${props.theme.mobileContentOffset};`
      : `${props.show ? `top: 50vh; ` : `top: 50vh;`}
        ${props.show ? "margin-top: auto" : `margin-top: 0`}`}
`

const StyledTitle = styled.span`
  position: sticky;
  bottom: ${SizeEnum.TapBarHeight};
  ${tw`flex items-center justify-between`}
`

const StyledToggle = styled.span<{ toggled: boolean }>`
  ${tw`relative flex items-center text-left text-xl m-6 ml-8 mt-6 text-gray-400 hover:text-gray-800 cursor-pointer transition-colors`}
  max-width: max-content;

  ${(props) => (props.toggled ? tw`text-gray-800` : ``)}
`

const InitPhoneSearch = ({
  toggleSidebar,
  toggleOnInit,
  onReset,
  onFocus,
  ...props
}) => {
  useEffect(() => {
    if (
      window.location.pathname === "/app" ||
      window.location.pathname === "/app/"
    ) {
      if (toggleOnInit) toggleSidebar()
    }
  }, [])
  return (
    <StyledSearch
      sortFnc={sortTrackers}
      onReset={onReset}
      onFocus={onFocus}
      {...props}
    />
  )
}

interface TrackerSearchProps {
  simple?: boolean
  visibilityToggle?: boolean
  placeholder?: string
  toggleOnInit?: boolean
}

const TrackerSearch: React.FC<TrackerSearchProps> = ({
  simple = false,
  visibilityToggle,
  toggleOnInit = false,
  ...props
}) => {
  const {
    deviceGroupVisibilitySetupToggled,
    deviceGroups,
    syncAddress,
    dispatch,
  } = useDevicesStore((state) => ({
    deviceGroupVisibilitySetupToggled: state.deviceGroupVisibilitySetupToggled,
    deviceGroups: state.deviceGroups,
    syncAddress: state.syncAddress,
    dispatch: state.dispatch,
  }))

  const { t } = useTranslation()

  const { devices, toggledDevices, unsubscribeToDevices } = useDevices(
    syncAddress
  )
  const {
    state: { results, suggestions, query },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { sidebarToggled },
    dispatch: appDispatch,
  } = useContext(AppContext)
  const { getOffsetByRoute } = useTrackerSearch()
  const { invisibleTrackerGroups } = useTrackerGroups()

  function toggleSidebar(toggle?: boolean) {
    if (toggle) {
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled: toggle })
      )
    } else {
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, {
          toggled: !sidebarToggled,
        })
      )
    }
  }

  function onToggleVisibilitySetup() {
    dispatch(DeviceActions(DeviceTypes.ToggleGroupVisibilitySetup, null))
  }

  const onSearchReset = () => {
    // if (cancelPendingSubscription) cancelPendingSubscription()
    ongoingSubscriptions.current = []
    dispatch(DeviceActions(DeviceTypes.UntoggleAllDevices, null))
  }

  const dataset = freetextSearch(devices, {
    threshold: 0.1,
    distance: 20,
    ignoreLocation: true,
    includeScore: true,
    keys: [{ name: "name", weight: 3 }, { name: "note", weight: 1 }, { name: "imei", weight: 1.5 }, { name: "id", weight: 0.5 }],
  })

  const ongoingSubscriptions = useRef<number[]>([])

  const onSubscribeToSearchResults = useCallback(
    (searchResults) => {
      if (simple) return
      const idMap: number[] = searchResults.map((result) => result.item.id)
      if (!isEqual(idMap, ongoingSubscriptions.current)) {
        ongoingSubscriptions.current = idMap
      }
    },
    [simple]
  )

  useLayoutEffect(() => {
    if (simple) {
      dispatch(DeviceActions(DeviceTypes.SetSyncAddress, { sync: false }))
    } else {
      if (toggledDevices.length > 0)
        dispatch(DeviceActions(DeviceTypes.SetSyncAddress, { sync: true }))
      else dispatch(DeviceActions(DeviceTypes.SetSyncAddress, { sync: false }))
    }
  }, [toggledDevices, simple])

  useLayoutEffect(() => {
    return () => {
      searchDispatch(resetSearch())
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled: false })
      )
      // if (cancelPendingSubscription) cancelPendingSubscription()
      ongoingSubscriptions.current = []
    }
  }, [])

  useLayoutEffect(() => {
    forceCheck()
  }, [results])

  const offsetY = getOffsetByRoute()

  return (
    <Responsive
      phone={
        <InitPhoneSearch
          dataset={dataset}
          toggleSidebar={() => toggleSidebar(true)}
          onReset={onSearchReset}
          onFocus={() => toggleSidebar(true)}
          onSubscribe={onSubscribeToSearchResults}
          toggleOnInit={toggleOnInit}
          {...props}
        >
          <StyledPopUnder
            show={sidebarToggled}
            fromBottom
            simple={
              simple || (query.length >= 1 && toggledDevices.length === 0)
            }
            customYOffset={`${offsetY}%`}
          >
            <TileContainer>
              {results.length > 0 && query.length > 0 && (
                <Tile
                  title={`${results.length} ${results.length === 1
                    ? t("tracker.search_result")
                    : t("tracker.search_results")
                    }`}
                >
                  <Group>
                    {results.map((tracker) => {
                      const { id, group } = tracker.item
                      return (
                        <TrackerItem
                          key={id}
                          deviceID={id}
                          groupID={group[0]}
                          simple={simple}
                          device={tracker.item}
                        />
                      )
                    })}
                  </Group>
                </Tile>
              )}
              {results.length === 0 &&
                suggestions.filter(
                  (tracker) =>
                    !(
                      (query.length === 1 && tracker.score > 0.36) ||
                      (query.includes(" ") && tracker.score > 0.36)
                    )
                ).length > 0 &&
                query.length > 0 && (
                  <Tile
                    title={`${suggestions.filter(
                      (tracker) =>
                        !(
                          (query.length === 1 && tracker.score > 0.36) ||
                          (query.includes(" ") && tracker.score > 0.36)
                        )
                    ).length
                      } ${t("tracker.search_suggestions_plural")}`}
                  >
                    <Group>
                      {suggestions.map((tracker) => {
                        if (
                          (query.length === 1 && tracker.score > 0.36) ||
                          (query.includes(" ") && tracker.score > 0.36)
                        )
                          return null
                        const { id, group } = tracker.item
                        return (
                          <TrackerItem
                            key={id}
                            deviceID={id}
                            groupID={group[0]}
                            simple={simple}
                            device={tracker.item}
                          />
                        )
                      })}
                    </Group>
                  </Tile>
                )}
              {results.length === 0 &&
                suggestions.length === 0 &&
                query.length > 0 && (
                  <Tile wrap title={t("tracker.search_no_results")}>
                    {t("tracker.search_try_broader_search")}
                  </Tile>
                )}
              {results.length === 0 && query.length === 0 && (
                <>
                  <Tile
                    onToggle={() => toggleSidebar()}
                    toggled={sidebarToggled}
                    title={
                      <>
                        <StyledTitle>
                          <span tw="opacity-80">
                            {t("tracker.search_groups")}
                          </span>
                        </StyledTitle>
                      </>
                    }
                  >
                    <TrackerGroups simple={simple} />
                  </Tile>

                  {visibilityToggle &&
                    (deviceGroups.length > 1 ||
                      invisibleTrackerGroups.length > 0) ? (
                    <StyledToggle
                      onClick={onToggleVisibilitySetup}
                      toggled={deviceGroupVisibilitySetupToggled}
                    >
                      <IconFlip
                        toggled={deviceGroupVisibilitySetupToggled}
                        iconOff="eye"
                        iconOn="check"
                        size="sm"
                        tw="mr-2"
                      />
                      {`${deviceGroupVisibilitySetupToggled
                        ? t("tracker.search_save_visible_groups")
                        : `${t("tracker.search_edit_visible_groups")} ${invisibleTrackerGroups.length > 0
                          ? `(${invisibleTrackerGroups.length} ${t(
                            "tracker.search_hidden_groups_decapitalize"
                          )})`
                          : ""
                        }`
                        }`}
                    </StyledToggle>
                  ) : null}
                </>
              )}
            </TileContainer>
          </StyledPopUnder>
        </InitPhoneSearch>
      }
      tabletLandscape={
        <>
          <div tw="px-4 pt-4 bg-white">
            <StyledSearch
              sortFnc={sortTrackers}
              dataset={dataset}
              onSubscribe={onSubscribeToSearchResults}
              onReset={onSearchReset}
              withPhoneBorder
              {...props}
            />
          </div>
          {/** When there's search results */}
          {results.length > 0 && query.length > 0 && (
            <Tile
              title={`${results.length} ${results.length === 1
                ? t("tracker.search_result")
                : t("tracker.search_results")
                }`}
            >
              <Group>
                {results.map((tracker) => {
                  const { id, group } = tracker.item
                  return (
                    <TrackerItem
                      key={id}
                      deviceID={id}
                      groupID={group[0]}
                      simple={simple}
                      device={tracker.item}
                    />
                  )
                })}
              </Group>
            </Tile>
          )}
          {/** When there's suggestions */}
          {results.length === 0 &&
            suggestions.filter(
              (tracker) =>
                !(
                  (query.length === 1 && tracker.score > 0.36) ||
                  (query.includes(" ") && tracker.score > 0.36)
                )
            ).length > 0 &&
            query.length > 0 && (
              <Tile
                title={`${suggestions.filter(
                  (tracker) =>
                    !(
                      (query.length === 1 && tracker.score > 0.36) ||
                      (query.includes(" ") && tracker.score > 0.36)
                    )
                ).length
                  } ${t("tracker.search_suggestions_plural")}`}
              >
                <Group>
                  {suggestions.map((tracker) => {
                    if (
                      (query.length === 1 && tracker.score > 0.36) ||
                      (query.includes(" ") && tracker.score > 0.36)
                    )
                      return null
                    const { id, group } = tracker.item
                    return (
                      <TrackerItem
                        key={id}
                        deviceID={id}
                        groupID={group[0]}
                        simple={simple}
                        device={tracker.item}
                      />
                    )
                  })}
                </Group>
              </Tile>
            )}
          {/** When there's no results */}
          {suggestions.length === 0 &&
            results.length === 0 &&
            query.length > 0 && (
              <Tile wrap title={t("tracker.search_no_results")}>
                {t("tracker.search_try_broader_search")}
              </Tile>
            )}
          {/** Default */}
          {results.length === 0 && query.length === 0 && (
            <>
              <Tile>
                <TrackerGroups
                  simple={simple}
                  hideInvisibleGroups={visibilityToggle}
                />
              </Tile>
              {visibilityToggle &&
                (deviceGroups.length > 1 || invisibleTrackerGroups.length > 0) ? (
                <StyledToggle
                  onClick={onToggleVisibilitySetup}
                  toggled={deviceGroupVisibilitySetupToggled}
                >
                  <IconFlip
                    toggled={deviceGroupVisibilitySetupToggled}
                    iconOff="eye"
                    iconOn="check"
                    size="sm"
                    tw="mr-2"
                  />
                  {`${deviceGroupVisibilitySetupToggled
                    ? t("tracker.search_save_visible_groups")
                    : `${t("tracker.search_edit_visible_groups")} ${invisibleTrackerGroups.length > 0
                      ? `(${invisibleTrackerGroups.length} ${t(
                        "tracker.search_hidden_groups_decapitalize"
                      )})`
                      : ""
                    }`
                    }`}
                </StyledToggle>
              ) : null}
            </>
          )}
        </>
      }
    />
  )
}

export default React.memo(TrackerSearch)
