import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  ValidationInput,
} from "@clevertrack/shared"
import { UserContext } from "app/User/context"
import { UserActions, UserTypes } from "app/User/actions"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import { useTranslation } from "react-i18next"

const StyledPasswordForm = styled(Form)`
  padding-top: 2rem;
  width: 100%;
`

function PasswordForm({
  title,
  header,
  children,
  onSubmit,
  onCancel,
  ...props
}) {
  const { t } = useTranslation()
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext)
  const lang = user?.language ? user.language.split("-")[0] : "da"
  const [password, setPassword] = useState()
  const [repeatPassword, setRepeatPassword] = useState()
  const [revalidatePW, setRevalidatePW] = useState(false)
  const [revalidateRepeatPW, setRevalidateRepeatPW] = useState(false)

  function onPasswordChange(e, repeat) {
    if (repeat) {
      setRepeatPassword(e.target.value)
      setRevalidatePW(true)
      setRevalidateRepeatPW(false)
    } else {
      setPassword(e.target.value)
      setRevalidatePW(false)
      setRevalidateRepeatPW(true)
    }
  }

  useEffect(() => {
    if (password === repeatPassword) {
      const editedUser = { ...user, password }
      dispatch(UserActions(UserTypes.SetUser, { user: editedUser }))
    }
  }, [password, repeatPassword])

  const defaultValidationMessages = getDefaultValidationMessagesByLang(lang)
  const validationMessages = {
    ...defaultValidationMessages,
    patternMismatch: t("error.settings_password_form_mismatch"),
  }

  return (
    <>
      <StyledPasswordForm
        {...props}
        onSubmit={(event) => onSubmit(user, event)}
      >
        {title && <h1>{title}</h1>}
        {header && <div>{header}</div>}
        <FormField
          validationKey="password"
          label={t("setting.password_form_new")}
        >
          <ValidationInput
            required
            id="password"
            lang={lang}
            type="password"
            placeholder={t("setting.password_form_new_placeholder")}
            onChange={(e) => onPasswordChange(e)}
            pattern={repeatPassword}
            revalidate={revalidatePW}
            validationMessages={validationMessages}
          />
        </FormField>
        <FormField
          validationKey="repeatPassword"
          label={t("setting.password_form_repeat")}
        >
          <ValidationInput
            required
            id="repeatPassword"
            lang={lang}
            type="password"
            placeholder={t("setting.password_form_repeat_placeholder")}
            onChange={(e) => onPasswordChange(e, true)}
            pattern={password}
            revalidate={revalidateRepeatPW}
            validationMessages={validationMessages}
          />
        </FormField>
        {children && children}
        {!children && (
          <>
            <ButtonGroup tw="mt-8 md:hidden" position="between">
              <Button type="button" variant="transparent" onClick={onCancel}>
                {t("common.cancel")}
              </Button>
              <Button type="submit" variant="primary">
                {t("common.confirm")}
              </Button>
            </ButtonGroup>
            <ButtonGroup tw="hidden md:(flex mt-8)" position="between">
              <Button type="button" variant="transparent" onClick={onCancel}>
                {t("common.cancel")}
              </Button>
              <Button type="submit" variant="primary">
                {t("common.confirm")}
              </Button>
            </ButtonGroup>
          </>
        )}
      </StyledPasswordForm>
    </>
  )
}

export default PasswordForm

PasswordForm.defaultProps = {}
PasswordForm.propTypes = {
  children: PropTypes.node.isRequired,
}
