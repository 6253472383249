import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { Button, Card, Tag } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import CrudList from "app/CrudList"
import { CrudListItem } from "app/CrudList/Item"
import FEATURE from "data/featureFlags"

const StyledTags = styled.div`
  display: flex;
  position: absolute;
  top: 0;
`

const StyledCard = styled(Card)`
  position: relative;

  ${(props) => props.theme.media.tablet_landscape_up`
    position: relative;
    transition: all .2s ease-out;

    .edit-mode & {
      opacity: 0.5;

      &.selected {
        opacity: 1;
        z-index: 100;
      }
    }
  `}
`

function UserSelector({
  myUser,
  users,
  onToggleUser,
  onCancel,
  hideTags,
  onDelete,
  customTagsRenderer,
  selectedUserID,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <CrudList {...props}>
      {myUser && (
        <li
          id="my-user"
          className={`${
            selectedUserID && selectedUserID !== myUser.id ? "edit-mode" : ""
          }`}
        >
          <StyledCard size="sm">
            <div>
              <h4>
                {myUser.firstName} {myUser.lastName} {t("user.my_user")}
              </h4>
              {myUser.email !== "" && <small>{myUser.email}</small>}
            </div>
            <div className="actions">
              <Link to="/app/settings">
                <Button variant="icon" size="sm">
                  {t("navigation.my_settings")}
                </Button>
              </Link>
            </div>
          </StyledCard>
        </li>
      )}
      {users &&
        users.map((user) => {
          const {
            id,
            firstName,
            lastName,
            email,
            is_admin,
            is_token_whitelisted,
            active,
            feature,
            count,
          } = user
          return (
            <CrudListItem
              key={id}
              editID={selectedUserID}
              onEdit={onToggleUser}
              onDelete={onDelete}
              onCancel={onCancel}
              item={user}
            >
              <div>
                <h4>
                  {firstName} {lastName}
                  {count ? ` (${count})` : ``}
                </h4>
                {email !== "" && <small>{email}</small>}
                {!hideTags && (
                  <StyledTags>
                    {is_admin === 1 ? (
                      <Tag title={t("user.tag_admin")} variant="info" show />
                    ) : null}
                    {is_token_whitelisted === 1 &&
                    feature.includes(FEATURE.DEVICEMOUNTING) ? (
                      <Tag
                        title={t("user.tag_installer")}
                        variant="warning"
                        show
                        tw="text-white"
                      />
                    ) : null}
                    {active === 1 ? null : (
                      <Tag
                        title={t("user.tag_disabled")}
                        variant="danger"
                        show
                      />
                    )}
                  </StyledTags>
                )}
                {customTagsRenderer && (
                  <StyledTags>{customTagsRenderer(user)}</StyledTags>
                )}
              </div>
            </CrudListItem>
          )
        })}
    </CrudList>
  )
}

export default UserSelector

UserSelector.defaultProps = {
  onToggleUser: () => {},
  onDelete: () => {},
  users: null,
}

UserSelector.propTypes = {
  onToggleUser: PropTypes.func,
  onDelete: PropTypes.func,
  users: PropTypes.array,
}
