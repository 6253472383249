import { DisplayKeyEnum, IDisplayKey } from "app/TrackerKPI/kpi.types"
import { allCategories } from "app/TrackerKPI/Widget/helper"
import { Category } from "app/TrackerKPI/Widget/widget.types"
import { DeviceTypeEnum } from "./types"
import { getTranslationText } from "utils/i18n"

export const assertDeviceTypeByAvailableDisplayKeys = (
  displayKeys: IDisplayKey[]
) => {
  const displayKeyNameMap = displayKeys.map((x) => x.name)
  if (displayKeyNameMap.includes(DisplayKeyEnum.EngineHoursToday)) {
    return DeviceTypeEnum.Machine
  } else if (displayKeyNameMap.includes(DisplayKeyEnum.DrivingHoursToday)) {
    return DeviceTypeEnum.Car
  } else if (displayKeyNameMap.includes(DisplayKeyEnum.BatteryLongevity)) {
    return DeviceTypeEnum.Beacon
  } else if (
    displayKeyNameMap.includes(DisplayKeyEnum.BatteryLongevityVolt72)
  ) {
    return DeviceTypeEnum.Asset
  } else return DeviceTypeEnum.MaterialScanner
}

export const assertDeviceCANAvailability = (displayKeys: IDisplayKey[]) => {
  const displayKeyNameMap: DisplayKeyEnum[] = displayKeys.map((x) => x.name)
  const CANIdentifiers = [
    DisplayKeyEnum.FuelConsumptionToday,
    DisplayKeyEnum.MachineWithCAN,
  ]
  return displayKeyNameMap.some((x) => CANIdentifiers.includes(x))
}

export const getDisplayKey = (
  displayKeys: IDisplayKey[],
  target: DisplayKeyEnum,
  category?: Category,
  language?: string
) => {
  const translations = {
    hours: getTranslationText("common.hour_plural_decapitalize", language),
    notConfigured: getTranslationText("service.not_configured", language)
  }
  const targetFromDisplayKeys = displayKeys.find((key) => key.name === target)
  const targetFromAllCategories = category
    ? category.find((key) => key.key === target)
    : allCategories(translations).find((key) => key.key === target)

  return { ...targetFromDisplayKeys, ...targetFromAllCategories }
}

export const sortTrackers = (a, b) => {
  // Check if we're dealing with Fuse.js search results (which have item and score properties)
  const hasScoreA = 'score' in a && typeof a.score === 'number';
  const hasScoreB = 'score' in b && typeof b.score === 'number';

  // If both items have scores, sort by score first (lower score = better match)
  if (hasScoreA && hasScoreB) {
    const scoreDiff = a.score - b.score;

    // If scores are significantly different, sort by score
    // Using a small threshold to avoid floating point comparison issues
    if (Math.abs(scoreDiff) > 0.001) {
      return scoreDiff; // Lower score (better match) comes first
    }
    // If scores are effectively equal, fall through to name-based sorting
  }

  // Get the name from either direct property or item property
  const nameA = a.name || (a.item && a.item.name) || '';
  const nameB = b.name || (b.item && b.item.name) || '';

  // Helper function to determine the category of the first character
  const getCategory = (char: string): number => {
    if (!char) return 3; // Empty strings last
    if (/[^a-zA-Z0-9]/.test(char)) return 0; // Symbols first
    if (/[0-9]/.test(char)) return 1; // Numbers second
    return 2; // Letters last
  };

  const categoryA = getCategory(nameA.charAt(0));
  const categoryB = getCategory(nameB.charAt(0));

  // If categories are different, sort by category
  if (categoryA !== categoryB) {
    return categoryA - categoryB;
  }

  // If both start with numbers, sort numerically
  if (categoryA === 1) {
    // Extract numbers from the beginning of the string, handling formats like "108 - Volvo"
    // This regex captures the numeric part at the start, even if followed by a dash or space
    const numRegexA = /^(\d+)(?:\s*-)?/;
    const numRegexB = /^(\d+)(?:\s*-)?/;

    const matchA = nameA.match(numRegexA);
    const matchB = nameB.match(numRegexB);

    const numA = matchA ? parseInt(matchA[1], 10) : 0;
    const numB = matchB ? parseInt(matchB[1], 10) : 0;

    if (numA !== numB) {
      return numA - numB; // Ascending numerical order
    }
  }

  // Default alphabetical sort for same category
  return nameA.localeCompare(nameB);
}