import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { useDropzone } from "react-dropzone"
import { Loading, ButtonGroup, Responsive } from "@clevertrack/shared"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { IconFlip } from "app/IconFlip"
import { UploadProgressMap } from "routes/Service/Panels/SubComponents/DeviceDocumentUpload"
import { uniqueId } from "lodash-es"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useTranslation } from "react-i18next"

type FileUploadProps = {
  templateFilename?: string // Place in static folder on the /import-templates-path
  onAccept?: <T>(data: T) => void
  onCancel?: () => void
  onImport?: () => void
  acceptResult: React.ReactElement
  cancelResult: React.ReactElement
  progressMap: UploadProgressMap
  allowedMimeTypes: MimeType[]
  uploadCompleted: boolean
}

type MimeType = {
  mime: string
  ext: string
}

export const MimeTypes: { [key: string]: MimeType } = {
  PDF: { mime: "application/pdf", ext: ".pdf" },
  JPEG: { mime: "image/jpeg", ext: ".jpeg" },
  PNG: { mime: "image/png", ext: ".png" },
  XLS: { mime: "application/vnd.ms-excel", ext: ".xls" },
  XLSX: {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ext: ".xlsx",
  },
  DOC: { mime: "application/msword", ext: ".doc" },
  DOCX: {
    mime:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ext: ".docx",
  },
  CSV: { mime: "text/csv", ext: ".csv" },
}

const StyledDropZone = styled.div<{ dragActive: boolean }>`
  ${tw`relative p-8 flex flex-col items-center justify-center border border-dashed border-brand-gray-base transition`}
  ${(props) => (props.dragActive ? tw`border-solid` : ``)}
  .inner {
  }

  svg {
    max-height: none;
    height: 4rem;
    width: 4rem;
    margin-right: 0;
  }
`

const StyledResults = styled.section``

type UploadResultMeta = {
  aborted: boolean
  cursor: number
  delimiter: string
  fields: string[]
  linebreak: string
  truncated: boolean
}

type UploadResult = {
  data: any[]
  errors: any[]
  meta: UploadResultMeta
}

export type FileUploadResponseType = {
  data: UploadResult
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onImport,
  onAccept,
  onCancel,
  acceptResult,
  cancelResult,
  progressMap,
  allowedMimeTypes,
  uploadCompleted,
  ...props
}) => {
  const { t } = useTranslation()
  const [uploadData, setUploadData] = useState<any[]>([])
  const [fileTypeError, setFileTypeError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<boolean>(false)

  const onDrop = (acceptedFiles) => {
    setLoading(true)
    const filetypes = acceptedFiles.map((x) => x.type)

    if (
      filetypes.some(
        (type) => !allowedMimeTypes.map((x) => x.mime).includes(type)
      )
    ) {
      setFileTypeError(true)
      return
    }

    setUploadData(
      acceptedFiles.map((x) => {
        return {
          id: uniqueId(),
          path: x.path,
          size: (x.size / (1024 * 1024)).toFixed(2),
          file: x,
        }
      })
    )
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  const renderDropZone = () => {
    return (
      <StyledDropZone
        {...getRootProps()}
        multiple={false}
        dragActive={isDragActive}
      >
        {loading ? (
          <Loading loadingText={t("file.processing")} />
        ) : (
          <>
            <input {...getInputProps()} />
            <IconFlip
              tw="h-24 w-24"
              size={IconSizeEnum.XL}
              iconOff="file-import"
              iconOn="upload"
              toggled={isDragActive}
            />
            <h4 tw="my-0 text-center">
              {!isDragActive ? t("common.upload") : t("common.slip")}
              <Responsive
                phone={
                  <small tw="block">
                    {t("file.or_take_picture_decapitalize")}
                  </small>
                }
                desktop={<></>}
              />
            </h4>
            <p tw="mt-4 mb-0 text-xl">
              {t("file.allowed_file_types")}:{" "}
              {allowedMimeTypes.map((x) => x.ext).join(", ")}
            </p>
          </>
        )}

        {fileTypeError ? (
          <span tw="text-xl text-brand-red-500 block mb-8">
            {t("error.file_invalid_file_type_identified")}
          </span>
        ) : null}
        {uploadError ? (
          <span tw="text-xl text-brand-red-500 block mb-8">
            {t("error.file_error_occurred_while_processing_file")}
          </span>
        ) : null}
      </StyledDropZone>
    )
  }

  const onAcceptHandler = () => {
    if (onAccept) onAccept(uploadData)
  }

  const onCancelHandler = () => {
    setUploadData([])
    if (onCancel) onCancel()
  }

  const onDeleteRowHandler = (removeIndex) => {
    const newUploadData = [...uploadData].filter((x, i) => i !== removeIndex)
    setUploadData(newUploadData)
  }

  useEffect(() => {
    if (uploadCompleted) {
      cogoToast.success(
        `${uploadData.length} ${t("common.file")}${
          uploadData.length > 1 ? t("common.are_decapitalize") : ``
        } ${t("file.uploaded_correctly_decapitalize")}`
      )
      setUploadData([])
    }
  }, [uploadCompleted])

  const renderResults = () => {
    return (
      <StyledResults>
        <div tw="my-0 text-2xl space-y-4">
          {uploadData.map((entry, i) => {
            return (
              <div tw="flex" key={`row_${i}`}>
                <span>
                  {entry.path} ({entry.size} {t("file.mb")})
                  {progressMap[entry.id] && (
                    <span>{progressMap[entry.id].progress}</span>
                  )}
                </span>
                <span
                  tw="flex items-center text-brand-red-500 cursor-pointer cursor-pointer hover:opacity-60 transition ml-4"
                  onClick={() => onDeleteRowHandler(i)}
                >
                  <Icon size={IconSizeEnum.SM} icon="close" />
                </span>
              </div>
            )
          })}
        </div>
        <ButtonGroup position="center" tw="mt-8">
          <cancelResult.type
            key={cancelResult.key}
            {...cancelResult.props}
            onClick={onCancelHandler}
          />
          <acceptResult.type
            key={acceptResult.key}
            {...acceptResult.props}
            onClick={onAcceptHandler}
          />
        </ButtonGroup>
      </StyledResults>
    )
  }

  return uploadData?.length > 0 ? renderResults() : renderDropZone()
}
