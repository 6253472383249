import React, { useContext, useEffect, useMemo, useRef } from "react"
import tw from "twin.macro"
import Icon from "lib/Icon"
import Checkbox from "lib/Checkbox"
import FormField from "lib/FormField"
import styled from "styled-components"
import { format, subDays } from "date-fns"
import DateFilter from "app/History/DateFilter"
import { HistoryContext } from "../context"
import { Prefilter } from "../history.types"
import { HistoryActions, HistoryTypes } from "../actions"
import { useTranslation } from "react-i18next"

const StyledFilters = styled.div`
  ${tw`print:(flex items-baseline)`}
  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 1rem;
    padding-bottom: 1rem;
    opacity: 0.8;

    ${tw`print:(font-normal opacity-100 gap-0)`}
  }
  .dateFilters {
    display: flex;
    /* gap: 1rem; */
    flex-wrap: wrap;
    ${tw`print:(flex items-baseline justify-end)`}

    @media print {
      input {
        border: none;
      }
    }

    > div {
      margin-top: 0;

      &:first-child {
        margin-right: 1rem;
      }

      flex-basis: calc(50% - 0.5rem);
      max-width: 40%;
    }

    button {
      margin-top: 1rem;
      flex-basis: 100%;
    }

    ${(props) => props.theme.media.big_desktop_up`

      > div {
        margin-right: 1rem;
        flex-basis: auto;
        flex: 1;
      }

      button {
        margin-top: 0;
        flex-basis: auto;
      }
    `}
  }

  .quick-filters {
    ${tw`print:hidden`}
    padding: 3rem 0;
    padding-bottom: 0;

    .filters {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 32rem;
    }

    > div > label {
      ${tw`static py-0 px-0 lg:absolute lg:-mt-5`}
      font-weight: bold;
      font-size: 90% !important;
      opacity: 0.8;
      ${(props) => props.theme.media.tablet_landscape_up`
        font-size: 85% !important;
      `}
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    // display: grid;
    // grid-template-columns: 1fr;
  `}
`

export const HistoryFilters: React.FC<{ onConfirmFilters: () => void }> = ({
  onConfirmFilters,
  children,
  ...props
}) => {
  const {
    state: {
      filters: { date },
    },
    dispatch,
  } = useContext(HistoryContext)
  const startDate = useRef(date.start)
  const stopDate = useRef(date.stop)
  const quickfilter = useRef(date.quickfilter)

  const { t } = useTranslation()

  const preFilters = useMemo<Prefilter[]>(
    () => [
      { days: 1, label: t("common.today") },
      { days: 7, label: t("history.last_7_days") },
      { days: 30, label: t("history.last_30_days") },
    ],
    [t]
  )

  const onSelectDateHandler = (d, start, stop) => {
    if (start) startDate.current = format(new Date(d), "yyyy-MM-dd")
    if (stop) stopDate.current = format(new Date(d), "yyyy-MM-dd")
    if (d) quickfilter.current = null

    updateDateFilter()
  }

  const updateDateFilter = () => {
    dispatch(
      HistoryActions(HistoryTypes.SetFilters, {
        filters: {
          date: {
            start: startDate.current,
            stop: stopDate.current,
            quickfilter: quickfilter.current,
          },
        },
      })
    )
  }

  const onQuickFilterSelect = (x: Prefilter, selected) => {
    if (selected) {
      onUpdatePrefilter(x)
    } else if (!selected && quickfilter.current?.days === x.days) {
      quickfilter.current = null
      updateDateFilter()
    }
  }

  /* const onFocusHandler = useCallback((identifier) => {
    setDateFocused(identifier)
  }, []) */

  const onUpdatePrefilter = (filter: Prefilter) => {
    if (filter) {
      const today = new Date()
      const stop = format(today, "yyyy-MM-dd")
      const start = format(subDays(new Date(today), filter.days), "yyyy-MM-dd")
      stopDate.current = stop
      startDate.current = filter.days === 1 ? stop : start
      quickfilter.current = filter
      updateDateFilter()
    }
  }

  useEffect(() => {
    onConfirmFilters()
  }, [date])

  useEffect(() => {
    updateDateFilter()
  }, [quickfilter, startDate, stopDate])

  return (
    <StyledFilters>
      <div className="title">
        <Icon tw="hidden md:block" icon="calendar-week-solid" />
        {t("common.period")}
      </div>
      <div className="dateFilters">
        <DateFilter
          key="from"
          date={new Date(date.start)}
          label={t("common.from_date")}
          onSelectDate={(d) => onSelectDateHandler(d, true, false)}
        />
        <DateFilter
          key="to"
          date={new Date(date.stop)}
          label={t("common.to_date")}
          onSelectDate={(d) => onSelectDateHandler(d, false, true)}
          dropdownAttachment="right"
        />
      </div>

      <div className="quick-filters">
        <FormField label={t("history.quick_filters")} tw="h-full">
          <div className="filters">
            {preFilters.map((x) => {
              return (
                <Checkbox
                  key={x.label}
                  checked={date.quickfilter?.days === x.days}
                  onChange={(selected) =>
                    selected && onQuickFilterSelect(x, selected)
                  }
                  tw="flex items-center"
                >
                  <span tw="text-xl">{x.label}</span>
                </Checkbox>
              )
            })}
          </div>
        </FormField>
      </div>
    </StyledFilters>
  )
}
