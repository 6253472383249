import React, {
  useRef,
  useContext,
  useState,
  useMemo,
  useLayoutEffect,
} from "react"
import styled, { css, keyframes } from "styled-components"
import tw from "twin.macro"
import format from "date-fns/format"
import da from "date-fns/locale/da"
import isToday from "date-fns/isToday"
import isSameHour from "date-fns/isSameHour"
import isURL from "validator/lib/isURL"
import { UserContext } from "app/User/context"
import { IUser } from "app/User/types"
import { IMessage, IContact, MessageStatusEnum } from "../conversations.types"
import Accordion from "lib/Accordion"
import last from "lodash-es/last"
import Icon, { IconSizeEnum } from "lib/Icon"
import { MessageLink } from "./MessageLink"
import useConversationStore from "../store"
import { useTranslation } from "react-i18next"

interface IConversationMessagesProps {
  messages: IMessage[]
  contact: IContact
  onGetOlderMessages: () => void
}

const StyledConversationMessages = styled.section`
  ${tw`p-6`}
  min-height: 100%;
  overflow-y: scroll;

  > .inner {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Highlight = keyframes`
  to {
    background: transparent;
  }
`

const HighlightRule = css`
  ${Highlight} 1.7s cubic-bezier(0, 0, 0.14, 1);
`

const StyledMessage = styled.article`
  ${tw`py-2 px-4 text-2xl`}
  // border-radius: 1.5rem;

  .inner {
    cursor: pointer;
    white-space: pre-line;
    ${tw`max-w-md md:(max-w-xl) overflow-ellipsis overflow-hidden`}
  }

  .highlight & {
    ${tw`bg-yellow-100`}
    animation: 10s ${Highlight} forwards cubic-bezier(0, 0, 0.14, 1);
  }

  &:last-child {
    ${tw`mb-0`}
  }
`

const StyledReceivedMessage = styled(StyledMessage)`
  ${tw`self-start flex flex-col`}

  & + & {
    ${tw`mt-2`}
  }

  .highlight & {
    ${tw`bg-yellow-100`}
    animation: ${HighlightRule};
  }

  .inner {
    border-radius: 1.5rem;
    ${tw`bg-gray-200 py-2 px-4 self-start`}
  }
`

const StyledSentMessage = styled(StyledMessage)`
  ${tw`bg-transparent self-end flex flex-col`}

  & + & {
    ${tw`mt-2`}
  }

  .inner {
    border-radius: 1.5rem;
    ${tw`bg-brand-500 text-white py-2 px-4 self-end transition-all`}

    &.enroute {
      ${tw`opacity-60`}
    }
  }
`

type LastReadByType = {
  [key: string]: Partial<IUser>[]
}

const timeSinceSentErrorTreshold = 60 * 60 * 5 // 5 Minutes

const ConversationMessages: React.FC<IConversationMessagesProps> = ({
  messages,
  contact,
  onGetOlderMessages,
  ...props
}) => {
  const {
    state: { users },
  } = useContext(UserContext)
  const { goToMessageID } = useConversationStore((state) => ({
    goToMessageID: state.goToMessageID,
  }))
  const { t } = useTranslation()
  const [showMessageDetailsID, setShowMessageDetailsID] = useState(null)

  const scrollContainerRef = useRef(null)
  const lastMessage = messages[messages.length - 1]

  const lastReadByMap = useMemo(
    () =>
      contact && contact.last_read_by
        ? Object.entries(contact.last_read_by).reduce(
            (curr: LastReadByType, readBy) => {
              const [userID, timestamp] = readBy
              const lastMessageRead = last(
                messages.filter((message) => message.time <= timestamp)
              )
              const user = users.find((u) => u.id?.toString() === userID)
              if (user && lastMessageRead) {
                curr[lastMessageRead.id] = curr[lastMessageRead.id]
                  ? [...curr[lastMessageRead.id], user]
                  : [user]
              }
              return curr
            },
            {}
          )
        : {},
    [contact, messages]
  )

  const renderSent = (msg: IMessage) => {
    const bodyLinks = msg.message
      .split(" ")
      .filter((part) =>
        isURL(part, { require_protocol: true, require_tld: true })
      )
    const lastReadBy = lastReadByMap[msg.id]
    const latestMessageDeliveryError = [
      MessageStatusEnum.EXPIRED,
      MessageStatusEnum.DELETED,
      MessageStatusEnum.UNDELIVERABLE,
      MessageStatusEnum.SKIPPED,
      MessageStatusEnum.REJECTED,
    ].includes(msg.status)
    return (
      <StyledSentMessage
        onClick={() =>
          setShowMessageDetailsID((prev) => (prev === msg.id ? null : msg.id))
        }
      >
        <Accordion
          toggled={msg.sent && [showMessageDetailsID].includes(msg.id)}
        >
          <span tw="text-right text-lg opacity-60 block">
            <span>
              {isToday(msg.time)
                ? `${
                    latestMessageDeliveryError
                      ? t("conversation.tried_to_send")
                      : t("common.sent")
                  } ${t("common.today_decapitalize")} ${t("common.at")} `
                : `${
                    latestMessageDeliveryError
                      ? t("conversation.tried_to_send")
                      : t("common.sent")
                  }  ${format(msg.time, "d. LLLL", {
                    locale: da,
                  })} ${t("common.at")} `}
            </span>
            <span>{format(msg.time, "HH:mm", { locale: da })}</span>
          </span>
        </Accordion>
        {msg.isGroupMessage && (
          <span tw="text-right text-brand-gray-dark opacity-60 mt-2 text-lg block">
            <span>
              {msg.sent_to_group
                ? `${t("conversation.sent_to_group")} ${msg.sent_to_group}`
                : `${t("conversation.sent_as_group_message")}`}
            </span>
          </span>
        )}
        <div tw="ml-auto flex flex-col items-end mt-2 md:(flex-row-reverse items-center justify-end)">
          <div
            className={
              msg.status === MessageStatusEnum.ENROUTE && messages.length > 1
                ? "inner enroute"
                : "inner"
            }
          >
            {msg.message}
            {bodyLinks.length > 0
              ? bodyLinks.map((link, i) => (
                  <MessageLink key={`${msg.id}_link_${i}`} url={link} />
                ))
              : null}
          </div>
          {latestMessageDeliveryError && (
            <span tw="block md:(flex mr-4 mt-0) align-baseline text-brand-red-500 whitespace-nowrap">
              <span tw="text-base mr-2">{t("conversation.failed")}</span>
              <Icon icon="exclamation-triangle" size={IconSizeEnum.SM} />
            </span>
          )}
        </div>

        {lastReadBy && (
          <div tw="text-right self-end">{renderLastReadBy(lastReadBy)}</div>
        )}
        {msg.status && (
          <Accordion
            toggled={
              msg.status &&
              [showMessageDetailsID, lastMessage.id].includes(msg.id)
            }
          >
            <span tw="text-right text-brand-gray-dark opacity-60 block mt-2 text-lg">
              {t(`messenger.${msg.status.toLowerCase()}`)}
            </span>
          </Accordion>
        )}
      </StyledSentMessage>
    )
  }

  const renderReceived = (msg: IMessage) => {
    const lastReadBy = lastReadByMap[msg.id]
    const bodyLinks = msg.message.split(" ").filter((part) => isURL(part))
    return (
      <StyledReceivedMessage
        onClick={() =>
          setShowMessageDetailsID((prev) => (prev === msg.id ? null : msg.id))
        }
      >
        <Accordion
          toggled={msg.received && [showMessageDetailsID].includes(msg.id)}
        >
          <span tw="text-left text-lg opacity-60 block">
            <span>
              {isToday(msg.time)
                ? `${t("common.received_today")} ${t("common.at")} `
                : `${t("common.received")} ${format(msg.time, "d. LLLL ", {
                    locale: da,
                  })} ${t("common.at")} `}
            </span>
            <span>{format(msg.time, "HH:mm", { locale: da })}</span>
          </span>
        </Accordion>
        <div className="inner" tw="mt-2">
          {msg.message}
          {bodyLinks.length > 0
            ? bodyLinks.map((link, i) => (
                <MessageLink key={`${msg.id}_link_${i}`} url={link} />
              ))
            : null}
        </div>
        {lastReadBy && (
          <div tw="self-start">{renderLastReadBy(lastReadBy)}</div>
        )}
      </StyledReceivedMessage>
    )
  }

  const renderLastReadBy = (lastReadBy: Partial<IUser>[]) => {
    const count = lastReadBy.length
    const contactsInGroup = lastReadBy.map((x) => x.shortName)
    const lastContact = contactsInGroup[contactsInGroup.length - 1]
    const lastReadByStr =
      count > 1
        ? [
            contactsInGroup.slice(0, contactsInGroup.length - 1).join(", "),
            lastContact,
          ].join(` ${t("common.and_decapitalize")} `)
        : contactsInGroup.join(", ")
    return lastReadBy.length > 0 ? (
      <span tw="text-brand-gray-dark opacity-60 text-lg">
        {t("common.seen_by")} {lastReadByStr}
      </span>
    ) : null
  }

  useLayoutEffect(() => {
    if (messages.length > 0 && scrollContainerRef.current !== null) {
      scrollContainerRef.current.scrollTop =
        goToMessageID &&
        scrollContainerRef.current.querySelector(`#msg_${goToMessageID}`)
          ? scrollContainerRef.current.querySelector(`#msg_${goToMessageID}`)
              ?.offsetTop
          : scrollContainerRef.current.scrollHeight
    }
  }, [goToMessageID, messages, scrollContainerRef.current])

  return (
    <StyledConversationMessages ref={scrollContainerRef}>
      <div className="inner">
        {/* <Button size="sm" variant="default" onClick={onGetOlderMessages}>
          Indlæs ældre beskeder
        </Button> */}
        {messages.map((msg, i) => {
          return (
            <article
              key={msg.internal_id || msg.id}
              id={`msg_${msg.id}`}
              className={goToMessageID === msg.id ? `highlight` : ""}
            >
              {((messages[i - 1] &&
                !isSameHour(messages[i - 1].time, msg.time)) ||
                i === 0 ||
                i === messages.length - 1) && (
                <span tw="text-center text-lg opacity-60 block mb-4 mt-8">
                  <span tw="font-bold">
                    {isToday(msg.time)
                      ? `${t("common.today")} `
                      : `${format(msg.time, "iii d. LLL", {
                          locale: da,
                        })} `}
                  </span>
                  <span>{format(msg.time, "HH:mm", { locale: da })}</span>
                </span>
              )}
              {msg.received ? renderReceived(msg) : renderSent(msg)}
            </article>
          )
        })}
      </div>
    </StyledConversationMessages>
  )
}

export { ConversationMessages }
