import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Icon from "lib/Icon"
import { Result as DefaultResult } from "app/ResultListGroup/Result"
import BarChartAsPercentage from "app/BarChart/AsPercentage"
import { hoverContentByUnit } from "../helper"
import { criticalSorting } from "../criticalSorting"
import { getDisplayKey } from "app/Device/helper"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import KPIWidget from "app/TrackerKPI/Widget"
import { DeviceTypeEnum } from "app/Device/types"
import useDevicesStore from "app/Device/store"
import { useTranslation } from "react-i18next"
import { useUser } from "app/User/hooks"

const ServiceResult = styled(DefaultResult)`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3rem;
  column-gap: 1rem;
  padding: 1.2rem 2rem;
  padding-right: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 98%;
  `}
`

const StyledKPIWidget = styled(KPIWidget)`
  padding: 0 !important;

  .item {
    display: grid;
    align-items: center;
    padding: 0;
    grid-template-columns: 0.4fr 0.6fr;

    svg {
      display: none;
    }

    .title,
    .value {
      ${tw`text-lg leading-snug whitespace-nowrap`}
    }

    .title {
      display: flex;
    }

    .value {
      ${tw`text-lg text-ellipsis overflow-hidden p-0`}
    }
  }
`

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.gray};
  margin: auto;
`

const StyledAnimatedIcon = styled(StyledIcon)`
  position: relative;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "all" : "none")};
  transition: ease-out 0.2s all;
  transform: ${(props) => (props.show ? "rotateZ(0)" : "rotateZ(-90deg)")};
`

/* const Counter = styled.div`
  background: ${(props) => props.theme.colors.primary};
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0.7;

  span {
    color: white;
    font-size: 50%;
    font-weight: bold;
  }
` */

function Result({ onClick, toggle, tracker, ...props }) {
  const { user } = useUser()
  const { devices } = useDevicesStore((state) => ({
    devices: state.devices,
  }))
  const { name, intervals, deviceMeta } = tracker
  const first = !!intervals[0]
    ? intervals[0]
    : { index: 0, current: 0, next: 0, unit: "" }
  const { index, unit } = first

  const setColor = (index, a, b) => {
    if (index >= b) return "red"
    if (index >= a) return "yellow"
    return "success"
  }

  const displayKey = useMemo(() => {
    if (!tracker.deviceMeta) return null
    return tracker.deviceMeta
      ? getDisplayKey(
          tracker.deviceMeta.values,
          DisplayKeyEnum.NextService,
          undefined,
          user?.language
        )
      : null
  }, [tracker.deviceMeta, devices])

  const nextServiceDisplayKey = useMemo(() => {
    if (!tracker.deviceMeta) return null
    return tracker.deviceMeta.deviceType === DeviceTypeEnum.Car
      ? getDisplayKey(
          tracker.deviceMeta.values,
          DisplayKeyEnum.TotalDistance,
          undefined,
          user?.language
        )
      : getDisplayKey(
          tracker.deviceMeta.values,
          DisplayKeyEnum.TotalEngineHours,
          undefined,
          user?.language
        )
  }, [tracker.deviceMeta, devices, user?.language])

  const { t } = useTranslation()

  return (
    <ServiceResult onClick={onClick} {...props}>
      <div>
        <div tw="flex items-center">
          <span>
            <strong>{name}</strong>{" "}
            {deviceMeta && deviceMeta.note && (
              <small tw="block opacity-60 text-lg flex items-center">
                <span tw="block">{deviceMeta.note}</span>
              </small>
            )}
          </span>
        </div>
        <small tw="block opacity-80 text-lg">
          {nextServiceDisplayKey && (
            <StyledKPIWidget tw="block" {...nextServiceDisplayKey} />
          )}
          {displayKey && <StyledKPIWidget tw="block" {...displayKey} />}
        </small>
        <BarChartAsPercentage
          icon="car-mechanic"
          index={index}
          hoverContent={hoverContentByUnit(unit, first, {
            formatLang: user?.language ? user.language.split("-")[0] : "da",
            timeLeftText: t("common.back_decapitalize"),
            exceededText: t("common.time_exceeded_decapitalize"),
          })}
          color={setColor(index, criticalSorting[0], criticalSorting[1])}
        />
      </div>
      {/* <StyledIcon icon="chevron-right" size="sm" /> */}
      <div style={{ display: "inherit" }}>
        <StyledAnimatedIcon
          icon="chevron-right"
          size="sm"
          style={{ top: "50%" }}
          show={!toggle}
        />
        <StyledAnimatedIcon
          icon="chevron-left"
          size="sm"
          style={{ top: "-50%" }}
          show={toggle}
        />
      </div>
    </ServiceResult>
  )
}

export default Result

Result.defaultProps = {}
Result.propTypes = {}
