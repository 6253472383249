import React, { useReducer, createContext } from "react"
import { IZone, IZoneMeta } from "./zones.types"
import { ZonesActionMap, ZonesTypes } from "./actions"

type InitialZonesStateType = {
  zones: IZone[]
  displayZones: IZone[]
  editZone: IZone | IZoneMeta | null
  zoneOverlayToggled: boolean
  zoneSettingsToggled: boolean
}

const initialState: InitialZonesStateType = {
  zones: [],
  displayZones: [],
  editZone: {
    name: "",
    description: "",
    active: true,
  },
  zoneOverlayToggled: false,
  zoneSettingsToggled: false,
}

const ZonesContext = createContext<{
  state: InitialZonesStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const reducer = (state: InitialZonesStateType, action: ZonesActionMap) => {
  switch (action.type) {
    case ZonesTypes.SetDisplayZoneList:
      return { ...state, displayZones: action.payload.list }
    case ZonesTypes.SetZoneList:
      return { ...state, zones: action.payload.list }
    case ZonesTypes.SetCurrentEditingZone:
      return { ...state, editZone: action.payload.zone }
    case ZonesTypes.ToggleZoneOverlay:
      return { ...state, zoneOverlayToggled: action.payload.toggled }
    case ZonesTypes.ToggleZoneSettings:
      return { ...state, zoneSettingsToggled: action.payload.toggled }
    case ZonesTypes.ResetZoneForm:
      return { ...state, editZone: initialState.editZone }
    default:
      return state
  }
}

const ZonesProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ZonesContext.Provider value={{ state, dispatch }}>
      {children}
    </ZonesContext.Provider>
  )
}

export { ZonesContext, ZonesProvider }
