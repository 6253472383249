import React, { useContext, useState } from "react"
import "twin.macro"
import styled from "styled-components"
import { DeviceZoneCriterium } from "routes/Alarms/alarm.types"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { ZonesContext } from "routes/Zones/context"
import { FormField } from "@clevertrack/shared"
import { Select } from "lib/Select"
import { Map } from "routes/Zones/components/Map"
import { IZone } from "routes/Zones/zones.types"
import Checkbox from "lib/Checkbox"
import { useTranslation } from "react-i18next"

interface IDeviceZoneCriteriaProps {
  criterium: DeviceZoneCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceZoneCriteriaElement extends IDeviceZoneCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

const StyledGoogleMapsWrapper = styled.div`
  height: 40rem;
  width: 100%;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;
  }
`

const ZoneOption = ({ innerProps, innerRef, data }) => {
  return (
    <div
      tw="px-4 py-4 hover:bg-brand-gray-lighter cursor-pointer"
      ref={innerRef}
      {...innerProps}
    >
      <span tw="block text-2xl">{data.value.name}</span>
      <span tw="block text-lg opacity-60">{data.value.description}</span>
    </div>
  )
}

export const DeviceZoneCriteriaElement: React.FC<IDeviceZoneCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  const { t } = useTranslation()
  const {
    state: { zones },
  } = useContext(ZonesContext)

  const renderDescription = () => {
    const selectedZone = zones.find((zone) => zone.id === criterium.zoneID)
    if (selectedZone) {
      const parts: string[] = []
      if (criterium.triggerOnEnter) parts.push(t("alarms.device_zone_arrives"))
      if (criterium.triggerOnLeave) parts.push(t("alarms.device_zone_leaves"))
      if (criterium.triggerOnAtLocation)
        parts.push(t("alarms.device_zone_is_inside"))
      if (criterium.triggerOnOutsideLocation)
        parts.push(t("alarms.device_zone_is_outside"))
      return `${t("alarms.device_zone_fulfilled_when")} ${parts.join(
        ` ${t("alarms.device_zone_and")} `
      )} ${t("alarms.device_zone_the_zone")} "${selectedZone.name}"`
    }
    return (
      <span tw="p-1 px-2 inline-block mt-2 text-lg border border-solid text-brand-red-500 border-brand-red-500 bg-red-100">
        {t("alarms.device_zone_missing")}
      </span>
    )
  }

  return (
    <AlarmConfigurationComponent tw="border-b-0">
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">{t("alarms.device_zone_title")}</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">{t("common.cancel")}</span>
            </>
          ) : (
            <>
              <span tw="text-lg">{t("common.edit")}</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">{renderDescription()}</span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceZoneCriteria: React.FC<IDeviceZoneCriteriaProps> = ({
  criterium,
  onSave,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    state: { zones },
  } = useContext(ZonesContext)
  const [selectedZone, setSelectedZone] = useState<IZone | null>(
    zones.find((zone) => zone.id === criterium.zoneID) ?? null
  )
  const [triggerOnLeave, setTriggerOnLeave] = useState<boolean>(
    criterium?.triggerOnLeave
  )
  const [triggerOnEnter, setTriggerOnEnter] = useState<boolean>(
    criterium?.triggerOnEnter
  )
  const [triggerOnAtLocation, setTriggerOnAtLocation] = useState<boolean>(
    criterium?.triggerOnAtLocation
  )
  const [triggerOnOutsideLocation, setTriggerOnOutsideLocation] = useState<
    boolean
  >(criterium?.triggerOnOutsideLocation)

  const onSaveHandler = () => {
    if (onSave && selectedZone)
      onSave({
        ...criterium,
        triggerOnEnter,
        triggerOnLeave,
        triggerOnAtLocation,
        triggerOnOutsideLocation,
        zoneID: selectedZone.id,
      })
  }

  const zoneOptions = zones.map((zone) => ({
    label: zone.name,
    value: zone,
  }))

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>{t("alarms.device_zone_title")}</CriteriumTypeTitle>
      <p tw="text-xl mt-0 text-brand-gray-base">
        {t("alarms.device_zone_description")}
      </p>
      <FormField label={t("alarms.device_zone_title")} tw="mt-4">
        <Select
          components={{ Option: ZoneOption }}
          options={zoneOptions}
          value={zoneOptions.find((opt) => opt.value.id === selectedZone?.id)}
          placeholder={t("alarms.device_zone_select")}
          onChange={(opt) => setSelectedZone(opt.value)}
          style={{ zIndex: 600 }}
        />
      </FormField>
      {selectedZone && (
        <>
          <div tw="mt-8">
            <CriteriumTypeTitle tw="mb-4 block">
              {t("alarms.device_zone_trigger_title")}
            </CriteriumTypeTitle>
            <div tw="text-xl">
              <Checkbox
                checked={triggerOnLeave}
                onChange={(checked) => setTriggerOnLeave(checked)}
              >
                {t("alarms.device_zone_trigger_leave")}
              </Checkbox>
              <Checkbox
                checked={triggerOnEnter}
                onChange={(checked) => setTriggerOnEnter(checked)}
              >
                {t("alarms.device_zone_trigger_enter")}
              </Checkbox>
              <Checkbox
                checked={triggerOnAtLocation}
                onChange={(checked) => setTriggerOnAtLocation(checked)}
              >
                {t("alarms.device_zone_trigger_inside")}
              </Checkbox>
              <Checkbox
                checked={triggerOnOutsideLocation}
                onChange={(checked) => setTriggerOnOutsideLocation(checked)}
              >
                {t("alarms.device_zone_trigger_outside")}
              </Checkbox>
            </div>
          </div>
          <StyledGoogleMapsWrapper tw="py-8">
            <Map viewZone={selectedZone} />
          </StyledGoogleMapsWrapper>
        </>
      )}
      {onSave && onCancel && (
        <EditorButtonGroup
          tw="mt-8"
          onSave={onSaveHandler}
          onCancel={onCancelHandler}
          saveDisabled={
            !selectedZone ||
            (!triggerOnEnter &&
              !triggerOnLeave &&
              !triggerOnAtLocation &&
              !triggerOnOutsideLocation)
          }
        />
      )}
    </>
  )
}
