import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import Search from "app/Search"
import { freetextSearch } from "app/Search/helper"
import { SearchContext } from "app/Search/context"
import GroupTrackerSearchResults from "app/GroupTrackerSearchResults"
import uniqBy from "lodash-es/uniqBy"
import { useUser } from "app/User/hooks"
import { useTranslation } from "react-i18next"
import { sortTrackers } from "app/Device/helper"

/* const StyledUserAssignedTrackers = styled.div`
  ${tw`bg-white pb-32`}
` */

const StyledPanel = styled.div`
  display: none;

  ${(props) =>
    props.toggled &&
    `
    display: block;
  `}
`

const StyledPanelToggle = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  margin-top: 2rem;

  span {
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
`

const StyledTab = styled.span`
  transition: all ease-out 0.15s;
  display: flex;
  padding: 0.5rem 1.5rem;
  margin-left: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.grayLight};
  border-bottom: none;
  background: ${(props) => props.theme.colors.grayLight};
  transform: translateY(1px);
  font-size: 1.2rem;
  cursor: pointer;

  ${(props) =>
    props.toggled &&
    `
    font-weight: bold;
    background: ${props.theme.colors.white};
  `}
`

function AssignTrackers({
  availableGroups,
  availableTrackers,
  assignedGroups,
  assignedTrackers,
  onToggleAssignment,
  hideTitle = false,
  children,
  ...props
}) {
  const { t } = useTranslation()
  const {
    state: { results, suggestions, query },
  } = useContext(SearchContext)
  const [toggledPanel, setToggledPanel] = useState("groups")
  const [groupResultCount, setGroupResultCount] = useState(null)
  const [trackerResultCount, setTrackerResultCount] = useState(null)
  const [trackers, setTrackers] = useState(() => filterAvailableTrackers())
  const [groups, setGroups] = useState(() => filterAvailableGroups())
  const { getGroupTrackerCountMap, groupTrackerCountMap } = useUser()

  const dataset = freetextSearch([...availableGroups, ...availableTrackers], {
    threshold: 0.1,
    distance: 20,
    ignoreLocation: true,
    includeScore: true,
    keys: [{ name: "name", weight: 3 }, { name: "note", weight: 1 }, { name: "imei", weight: 1.5 }, { name: "id", weight: 0.5 }],
  })

  function filterAvailableTrackers() {
    return availableTrackers.filter(
      (tracker) => !assignedTrackers.find((item) => item.id === tracker.id)
    )
  }

  function filterAvailableGroups() {
    return availableGroups.filter(
      (group) => !assignedGroups.find((item) => item.id === group.id)
    )
  }

  function toggleGroup(id, toggleType) {
    const newGroups = !!assignedGroups.find((group) => group.id === id)
      ? assignedGroups.filter((group) => group.id !== id)
      : [...assignedGroups, availableGroups.find((group) => group.id === id)]

    const newTrackers = []

    const condition = (tracker) => tracker.group.includes(id)
    switch (toggleType) {
      case "add":
        // Assign trackers of the group
        const trackersInGroup = availableTrackers.filter(condition)
        const filtered = trackersInGroup.filter(
          (t) => !assignedTrackers.some((ut) => ut.id === t.id)
        )
        newTrackers.push(...assignedTrackers, ...filtered)
        break
      case "remove":
        // Unassign trackers of that group
        newTrackers.push(...assignedTrackers.filter((x) => !condition(x)))
        break
    }

    onToggleAssignment([uniqBy(newTrackers, "id"), newGroups])
  }

  function toggleTracker(id) {
    const newTrackers = !!assignedTrackers.find((tracker) => tracker.id === id)
      ? assignedTrackers.filter((tracker) => tracker.id !== id)
      : [
        ...assignedTrackers,
        availableTrackers.find((tracker) => tracker.id === id),
      ]

    onToggleAssignment([newTrackers, assignedGroups])
  }

  useEffect(() => {
    const newTrackers = filterAvailableTrackers()
    setTrackers(newTrackers)
    const newGroups = filterAvailableGroups()
    setGroups(newGroups)
    getGroupTrackerCountMap(assignedTrackers, assignedGroups)
  }, [assignedTrackers, assignedGroups])

  useEffect(() => {
    if (groupResultCount === 0 && trackerResultCount > 0)
      setToggledPanel("trackers")
    if (groupResultCount > 0 && trackerResultCount === 0)
      setToggledPanel("groups")
  })

  return (
    <div>
      {children ? (
        children
      ) : !hideTitle ? (
        <header>
          <h3>{t("common.assigned_trackers_and_groups")}</h3>
        </header>
      ) : null}
      <form>
        <Search
          sortFnc={sortTrackers}
          type="text"
          dataset={dataset}
          placeholder={t("search.input_placeholder")}
          withPhoneBorder
        >
          <StyledPanelToggle>
            <StyledTab
              onClick={() => setToggledPanel("groups")}
              toggled={toggledPanel === "groups"}
            >
              {`${t("common.group_plural")} ${groupResultCount ? `(${groupResultCount})` : ``
                }`}
            </StyledTab>
            <StyledTab
              onClick={() => setToggledPanel("trackers")}
              toggled={toggledPanel === "trackers"}
            >
              {`${t("common.tracker_plural")} ${trackerResultCount ? `(${trackerResultCount})` : ``
                }`}
            </StyledTab>
          </StyledPanelToggle>
          <StyledPanel toggled={toggledPanel === "groups"}>
            <GroupTrackerSearchResults
              query={query}
              results={results}
              suggestions={suggestions}
              inSelection={assignedGroups}
              availableForSelection={groups}
              inSelectionTitle={
                !hideTitle ? (
                  <h4>{t("common.assigned_groups")}</h4>
                ) : (
                  <h4>{t("common.assigned_groups")}</h4>
                )
              }
              availableForSelectionTitle={
                !hideTitle ? (
                  <h4>
                    {t("common.add")} {t("common.group_decapitalize")}{" "}
                    {t("common.to_decapitalize")} {t("common.user")}
                  </h4>
                ) : (
                  <h4>{t("common.available_groups")}</h4>
                )
              }
              onAddToSelection={(id) => toggleGroup(id, "add")}
              onRemoveFromSelection={(id) => toggleGroup(id, "remove")}
              onMatch={(count) => setGroupResultCount(count)}
              groupTrackerCountMap={groupTrackerCountMap}
            />
          </StyledPanel>
          <StyledPanel toggled={toggledPanel === "trackers"}>
            <GroupTrackerSearchResults
              query={query}
              results={results}
              suggestions={suggestions}
              inSelection={assignedTrackers}
              availableForSelection={trackers}
              inSelectionTitle={
                !hideTitle ? (
                  <h4>{t("common.assigned_trackers")}</h4>
                ) : (
                  <h4>{t("common.available_trackers")}</h4>
                )
              }
              availableForSelectionTitle={
                !hideTitle ? (
                  <h4>
                    {t("common.add")} {t("common.unit")} {t("common.to")}{" "}
                    {t("common.user")}
                  </h4>
                ) : (
                  <h4>{t("common.available_trackers")}</h4>
                )
              }
              onAddToSelection={toggleTracker}
              onRemoveFromSelection={toggleTracker}
              onMatch={(count) => setTrackerResultCount(count)}
            />
          </StyledPanel>
        </Search>
      </form>
    </div>
  )
}

export default AssignTrackers

AssignTrackers.defaultProps = {}
AssignTrackers.propTypes = {
  children: PropTypes.node,
}
