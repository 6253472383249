import React, { useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import { isEqual } from "lodash-es"
import type Fuse from "fuse.js"
import Icon from "app/Icon"
import { useThrottle } from "react-use"
import { Button, Input, Responsive } from "@clevertrack/shared"
import { SearchContext } from "./context"
import {
  setSearchQuery,
  setSearchResults,
  setSearchSuggestions,
  resetSearch,
} from "./actions"
import useOnClickOutside from "hooks/useOnClickOutside"
import { useTranslation } from "react-i18next"

const StyledSearch = styled.div`
  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.media.tablet_landscape_up`
    /* display: grid;
    grid-template-rows: 5rem 1fr;
    position: sticky; */
    top: 0;
    z-index: 10;
    ${props.noPad ? `` : `padding: 0 0 1rem;`}
  `}
`

const StyledSearchInputWrapper = styled.div`
  ${tw`flex items-center h-full relative`}
`

const StyledSearchIcon = styled(Icon)`
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 1.6rem;
  width: 1.6rem;
  margin: 0;
  opacity: 0.8;

  ${(props) => props.theme.media.tablet_landscape_up`
    left: auto;
    right: 1.5rem;
  `}
`

const StyledResetButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 48%;
  transform: translateY(-50%);
  height: 2.2rem;
  width: 2.2rem;
  margin: 0;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.grayLight};
  opacity: 1;

  &:hover {
    background: ${(props) => props.theme.colors.red};
  }

  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`

const StyledSearchInput = styled(Input)`
  font-size: initial;
  margin-top: 0;
  margin-bottom: 0;

  ${(props) =>
    props.withPhoneBorder === false
      ? "border: none;"
      : `border: 1px solid ${props.theme.colors.grayLight};`};

  ${(props) => (props.noPad === false ? "" : `padding-left: 4rem;`)};

  // line-height: 0;

  &:focus {
    outline: 0;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    ${
      props.withPhoneBorder === false
        ? "border: none; padding-left: 0; margin: 0 1rem;"
        : `border: 1px solid ${props.theme.colors.grayLight}; padding-left: 1rem;`
    };

    padding-right: 4rem;
  `}
`

export interface ISearchProps {
  dataset: Fuse<unknown>
  onFocus?: (...args) => void
  onReset?: (...args) => void
  onBlur?: (...args) => void
  onKeyUp?: (...args) => void
  onKeyDown?: (...args) => void
  onSubscribe?: (...args) => void
  sortFnc?: (...args) => number
  toggled?: boolean
  title?: string
  children?: React.ReactNode
  withPhoneBorder?: boolean
  placeholder?: string
  noPad?: boolean
  hideSearchIcon?: boolean
}

const Search: React.FC<ISearchProps> = ({
  dataset,
  onFocus,
  onReset,
  onBlur,
  onKeyUp,
  onKeyDown,
  onSubscribe,
  sortFnc,
  toggled,
  title,
  children,
  withPhoneBorder = true,
  placeholder,
  hideSearchIcon = false,
  noPad,
  ...props
}) => {
  const {
    state: { query, results, suggestions },
    dispatch,
  } = useContext(SearchContext)
  const debouncedSearchQuery = useThrottle(query, 0)
  const wrapperRef = useRef(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  useOnClickOutside(wrapperRef, () => {
    if (inputRef.current) inputRef.current.blur()
  })

  const onSearch = async (q) => {
    const searchResults = await dataset.search(q)
    const returnResults = {
      exact_matches: searchResults.filter((result) => {
        return q.length === 1
          ? +result.score.toFixed(3) <= 0.01
          : +result.score.toFixed(3) <= 0.04
      }),

      suggestions: searchResults.filter((result) => {
        return q.length === 1
          ? +result.score.toFixed(3) > 0.01
          : +result.score.toFixed(3) > 0.04
      }),
    }
    if (sortFnc) {
      return {
        exact_matches: returnResults.exact_matches.sort(sortFnc),
        suggestions: returnResults.suggestions.sort(sortFnc),
      }
    }
    return returnResults
  }

  function onChangeHandler(e) {
    dispatch(setSearchQuery(e.target.value))
  }

  function onResetQueryHandler() {
    dispatch(setSearchQuery(""))
    dispatch(resetSearch())
    onReset()
  }

  useEffect(() => {
    if (debouncedSearchQuery) {
      onSearch(debouncedSearchQuery).then(
        ({ exact_matches, suggestions: newSuggestions }) => {
          if (debouncedSearchQuery.length > 0) {
            dispatch(setSearchQuery(debouncedSearchQuery))
            if (!isEqual(results, exact_matches))
              dispatch(setSearchResults(exact_matches))
            if (!isEqual(suggestions, newSuggestions))
              dispatch(setSearchSuggestions(newSuggestions))

            if (onSubscribe) onSubscribe([...exact_matches, ...newSuggestions])
          }
        }
      )
    } else {
      dispatch(resetSearch())
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    onSearch(debouncedSearchQuery).then(
      ({ exact_matches, suggestions: newSuggestions }) => {
        if (!isEqual(results, exact_matches))
          dispatch(setSearchResults(exact_matches))
        if (!isEqual(suggestions, newSuggestions))
          dispatch(setSearchSuggestions(newSuggestions))
      }
    )
  }, [dataset])

  return (
    <Responsive
      phone={
        <>
          <StyledSearchInputWrapper ref={wrapperRef}>
            {!hideSearchIcon && <StyledSearchIcon size="sm" icon="search" />}
            <StyledSearchInput
              value={query}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChangeHandler}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              focusShadow={false}
              placeholder={placeholder ?? t("search.input_placeholder")}
              withPhoneBorder={withPhoneBorder}
              ref={inputRef}
              noPad={noPad}
              {...props}
            />
            {query.length > 0 && (
              <StyledResetButton
                type="button"
                variant="danger"
                icon
                size="sm"
                onClick={onResetQueryHandler}
              >
                <Icon size="sm" icon="close" />
              </StyledResetButton>
            )}
          </StyledSearchInputWrapper>
          {children}
        </>
      }
      tabletLandscape={
        <>
          <StyledSearch noPad={noPad}>
            <StyledSearchInputWrapper>
              {!hideSearchIcon && <StyledSearchIcon size="sm" icon="search" />}
              <StyledSearchInput
                value={query}
                onChange={onChangeHandler}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                placeholder={placeholder ?? t("search.input_placeholder")}
                withPhoneBorder={withPhoneBorder}
                {...props}
              />
              {query.length > 0 && (
                <StyledResetButton
                  type="button"
                  variant="danger"
                  icon
                  size="sm"
                  onClick={onResetQueryHandler}
                >
                  <Icon size="sm" icon="close" />
                </StyledResetButton>
              )}
            </StyledSearchInputWrapper>
          </StyledSearch>
          {children}
        </>
      }
    />
  )
}

export default Search

Search.defaultProps = {
  dataset: {},
  onReset: () => {},
  withPhoneBorder: false,
}
Search.propTypes = {
  children: PropTypes.node,
  dataset: PropTypes.object,
  onReset: PropTypes.func,
  withPhoneBorder: PropTypes.bool,
}
