import React, { useContext } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Button } from "@clevertrack/shared"
import { ModalPage } from "app/Dialog/dialog.types"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { Dialog } from "app/Dialog"
import { useTranslation } from "react-i18next"

const StyledContent = styled.div`
  ${tw`text-xl leading-snug`}

  p {
    ${tw`text-xl leading-snug`}
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    width: 60rem;
  `}
`

export const AutoOffDislcaimer: React.FC = ({
  onChangeDOutOnIgnition,
  dOutOnIgnitionChecked,
  defaultPage,
  children,
  ...props
}) => {
  const {
    dispatch,
    state: { modalPage },
  } = useContext(AppContext)
  const { t } = useTranslation()

  const setToggledPage = (modal) => {
    dispatch(GlobalAppActions(GlobalAppTypes.SetCurrentModal, { modal }))
  }

  const renderContent = () => {
    switch (modalPage) {
      case ModalPage.ABOUT:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              {t("auto-shutdown.titles.how_it_works")}
            </Dialog.Title>
            <p>{t("auto-shutdown.descriptions.1")}</p>
            <ul>
              <li>{t("auto-shutdown.descriptions.2")}</li>
              <li>{t("auto-shutdown.descriptions.3")}</li>
              <li>{t("auto-shutdown.descriptions.4")}</li>
            </ul>
            <p>{t("auto-shutdown.descriptions.5")}</p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              {t("common.close")}
            </Button>
          </StyledContent>
        )
      case ModalPage.DOUTONIGNITION:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              {t("auto_off_title_dout_on_ignition")}
            </Dialog.Title>
            <p>{t("auto-shutdown.descriptions.6")}</p>
            <p>{t("auto-shutdown.descriptions.7")}</p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              {t("common.close")}
            </Button>
          </StyledContent>
        )
      case ModalPage.ENGINELOWTEMPERATURE:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              {t("auto-shutdown.titles.engine_low_temperature")}
            </Dialog.Title>
            <p>{t("auto-shutdown.descriptions.8")}</p>
            <p>
              <strong>{t("common.recommendation")} </strong>
              {t("auto-shutdown.descriptions.9")}
            </p>
            <p>
              {t("auto-shutdown.descriptions.10")}{" "}
              <strong>{t("auto-shutdown.descriptions.11")}</strong>{" "}
              {t("auto-shutdown.descriptions.12")}
              <strong>{t("auto-shutdown.descriptions.13")}</strong>{" "}
              {t("auto-shutdown.descriptions.14")}{" "}
              <strong>{t("auto-shutdown.descriptions.15")}</strong>{" "}
              {t("auto-shutdown.descriptions.16")}
            </p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              Luk
            </Button>
          </StyledContent>
        )
      case ModalPage.FIRSTACTIVATION:
        return (
          <StyledContent>
            <Dialog.Title tw="my-0 text-4xl">
              {t("auto_off_title_first_activation")}
            </Dialog.Title>
            <p>{t("auto-shutdown.descriptions.17")}</p>
            <p>
              <strong>{t("auto-shutdown.descriptions.18")}: </strong>
              {t("auto-shutdown.descriptions.19")}
            </p>
            <p>{t("auto-shutdown.descriptions.20")}</p>
            <Button variant="default" onClick={() => setToggledPage(null)}>
              {t("common.close")}
            </Button>
          </StyledContent>
        )
      default:
        return null
    }
  }

  return modalPage === ModalPage.DISCLAIMER ? (
    <StyledContent>
      <Dialog.Title tw="my-0">
        {t("auto-shutdown.titles.disclaimer")}
      </Dialog.Title>
      <h3>{t("auto-shutdown.descriptions.21")}</h3>
      <p>
        <span>
          {t("auto-shutdown.descriptions.22")}{" "}
          <strong>{t("auto-shutdown.descriptions.23")}</strong>.{" "}
          {t("auto-shutdown.descriptions.24")}
        </span>
      </p>
      <h3>{t("auto-shutdown.descriptions.25")}</h3>
      <ol>
        <li>{t("auto-shutdown.descriptions.26")}</li>
        <li>{t("auto-shutdown.descriptions.27")}</li>
        <li>{t("auto-shutdown.descriptions.28")}</li>
        <li>
          {t("auto-shutdown.descriptions.29")}{" "}
          <strong>{t("auto-shutdown.descriptions.30")}</strong>{" "}
          {t("auto-shutdown.descriptions.31")}
        </li>
        <li>
          {t("auto-shutdown.descriptions.32")}{" "}
          <strong tw="text-brand-red-500">
            {t("auto-shutdown.descriptions.33")}
          </strong>{" "}
          {t("auto-shutdown.descriptions.34")}
        </li>
      </ol>
      <h3>{t("auto-shutdown.descriptions.35")}</h3>
      <p>{t("auto-shutdown.descriptions.36")}</p>
      <p tw="text-center font-bold">{t("auto-shutdown.descriptions.37")}</p>
      {children}
    </StyledContent>
  ) : (
    renderContent()
  )
}
