import React, { FC, useContext } from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import styled from "styled-components"
import Icon from "lib/Icon"
import { Button } from "@clevertrack/shared"
import { navigate } from "gatsby"
import FeatureComponent from "app/FeatureComponent"
import FEATURE from "data/featureFlags"
import { UserContext } from "app/User/context"

interface ZonesFooterProps {
  className?: string
}

const SetupZonesButton = styled(Button)`
  ${tw`mt-1 min-w-[60px] h-14 m-0 border-0 flex items-center justify-center border-t border-gray-200 border-solid`}
`

const FooterText = styled.p`  ${tw`text-xl`}
  color: ${(props) => props.theme.colors.primary};
`

const FooterIcon = styled(Icon)`
  ${tw`ml-2 w-4 h-4 `}
  color: ${(props) => props.theme.colors.primary};
`

export const ZonesFooter: FC<ZonesFooterProps> = ({ className }) => {
  const { t } = useTranslation()
  const {
    state: { user },
  } = useContext(UserContext)

  const handleAddZoneClick = () => {
    navigate("/app/settings/zones")
  }

  return (
    <FeatureComponent user={user} feature={FEATURE.ZONES}>
      <SetupZonesButton
        variant="transparent"
        size="sm"
        onClick={handleAddZoneClick}
        className={className}
      >
        <FooterText>{t("zones.setup_zones")}</FooterText>
        <FooterIcon icon="chevron-right" />
      </SetupZonesButton>
    </FeatureComponent>
  )
}
