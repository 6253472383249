import React, { useState, useContext, useMemo, useCallback } from "react"
import "twin.macro"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { useUser } from "app/User/hooks"
import { CompanyContext } from "app/Company/context"
import { Button, ButtonGroup } from "@clevertrack/shared"
import {
  DocumentUpload as DocumentUploadType,
  FileObj,
} from "app/DocumentUpload/documentupload.types"
import { useFirebaseStorage } from "services/storage"
import { StorageReference, getDownloadURL } from "firebase/storage"
import { FileUpload, MimeTypes } from "app/Documents/FileUpload"
import { FileList } from "app/Documents/List"
import OffCanvas from "lib/OffCanvas"
import cogoToast from "@clevertrackdk/cogo-toast"
import { useTranslation } from "react-i18next"

type DeviceDocumentUploadProps = {
  deviceID: number
  path: DeviceFilePathEnum
  childPath: string[] // Typically an ID like ServiceID
  toggleUploadActionText: string
  fileListHeadingText: string
  offCanvasRootSelector: string
}

type DeviceUploadProgressItem = {
  progress?: string
  filename: string
  downloadURL?: string
}

export type DeviceDocumentDirectoryItem = {
  ref: StorageReference
  name: string
  downloadURL: string
  uploaded: Date
}

export type UploadProgressMap = {
  [key: string]: DeviceUploadProgressItem
}

export enum DeviceFilePathEnum {
  VehicleDocuments = "vehicleDocs",
  VehicleServiceDocuments = "vehicleServiceDocs",
  VehiclePhoto = "vehiclePhoto",
  DeviceMountPhoto = "deviceMountPhoto",
  DevicePowerSupplyMountPhoto = "devicePowerSupplyMountPhoto",
  DeviceECanMountPhoto = "deviceECanMountPhoto",
  DeviceAutoOffRelayPhoto = "deviceAutoOffRelayPhoto",
  DeviceImmobilizerMountPhoto = "deviceImmobilizerMountPhoto",
  MiscPhoto = "miscPhoto",
}

const Title = styled.div`
  padding-bottom: 2rem;
  font-weight: bold;
  h3 {
    margin-bottom: 0;
  }
`

export const DeviceDocumentUpload: React.FC<DeviceDocumentUploadProps> = ({
  deviceID,
  path: mainPath,
  childPath = [],
  toggleUploadActionText,
  fileListHeadingText,
  offCanvasRootSelector,
  ...props
}) => {
  const {
    state: { company },
  } = useContext(CompanyContext)
  const [showOffCanvas, setShowOffcanvas] = useState(false)
  const { uploadFile, getDirectoryList, deleteFile } = useFirebaseStorage()
  const [uploadMap, setUploadMap] = useState<UploadProgressMap>({})
  const [inProgress, setInProgress] = useState(false)
  const [deviceFiles, setDeviceFiles] = useState<
    DeviceDocumentDirectoryItem[] | []
  >([])
  const { canWrite } = useUser()
  const { t } = useTranslation()

  const path = useMemo(() => {
    return company?.id && deviceID
      ? [
          company.id.toString(),
          deviceID.toString(),
          mainPath,
          ...childPath,
        ].join("/")
      : ""
  }, [company, deviceID, mainPath, childPath])

  const onToggleEditHandler = () => {
    setShowOffcanvas(true)
  }

  const onCloseHandler = () => {
    setShowOffcanvas(false)
  }

  const getDirectory = async (dir) => {
    const directory = await getDirectoryList(dir)
    if (directory) {
      setDeviceFiles(directory)
    }
  }

  const uploadCompleted = useMemo(() => {
    const uploadsInProgress = Object.keys(uploadMap)
    const count = Object.keys(uploadMap).map(
      (val) => uploadMap[val].downloadURL
    )
    if (
      uploadsInProgress.length > 0 &&
      uploadsInProgress.length === count.length
    ) {
      setInProgress(false)
      getDirectory(path)
      return true
    }
    return false
  }, [uploadMap, path, inProgress])

  const onDeleteHandler = async (ref: StorageReference) => {
    try {
      const result = await deleteFile(ref)
      getDirectory(path)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  useMemo(async () => {
    if (path !== "") {
      getDirectory(path)
    } else {
      setDeviceFiles([])
    }
  }, [uploadCompleted, path])

  const onUploadDocumentHandler = useCallback(
    (data: FileObj[]) => {
      if (company && company.id) {
        setInProgress(true)
        for (const file of data) {
          const fileID = file.id
          const filePath = [path, file.file.name]
          const uploadTask = uploadFile(filePath.join("/"), file.file)
          setUploadMap((prev) => ({
            ...prev,
            [fileID]: {
              progress: `0%`,
              filename: file.file.name,
            },
          }))
          if (uploadTask) {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setUploadMap((prev) => ({
                  ...prev,
                  [fileID]: {
                    ...prev[`${filePath.join("/")}`],
                    progress: `${progress}%`,
                  },
                }))
              },
              (error) => {
                // Handle unsuccessful uploads
                cogoToast.error(`${t("error.uploading_file")} ${error}`)
              },
              () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setUploadMap((prev) => ({
                    ...prev,
                    [fileID]: {
                      ...prev[`${filePath.join("/")}`],
                      downloadURL,
                    },
                  }))
                })
              }
            )
          }
        }
      }
    },
    [path]
  )

  const renderUploadFilelistAction = () => {
    const actionStringBuilder: string[] = []
    if (!canWrite && deviceFiles.length === 0) return null

    if (deviceFiles.length > 0) {
      actionStringBuilder.push(
        `${t("common.see")} ${deviceFiles.length} ${
          deviceFiles.length === 1
            ? t("common.file_decapitalize")
            : t("common.files_decapitalize")
        }`
      )
    }

    if (canWrite && deviceFiles.length === 0) {
      actionStringBuilder.push(toggleUploadActionText)
    }

    return (
      <span
        tw="flex items-center text-brand-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
        onClick={onToggleEditHandler}
      >
        <span tw="mr-2 text-xl border-dotted border-0 border-b ">
          {actionStringBuilder.join(" ")}
        </span>
        <Icon size={IconSizeEnum.SM} icon="plus" />
      </span>
    )
  }

  return (
    <div tw="my-8">
      {renderUploadFilelistAction()}
      <OffCanvas
        tw="absolute z-70"
        selector={offCanvasRootSelector}
        fromRight
        show={showOffCanvas}
      >
        <div tw="bg-white sticky top-0 h-screen overflow-y-scroll p-8 lg:(h-full)">
          {canWrite && (
            <>
              <h3 tw="my-0 mb-8">{toggleUploadActionText}</h3>
              <FileUpload
                progressMap={uploadMap}
                uploadCompleted={uploadCompleted}
                allowedMimeTypes={[
                  MimeTypes.JPEG,
                  MimeTypes.PNG,
                  MimeTypes.XLS,
                  MimeTypes.PDF,
                  MimeTypes.XLSX,
                  MimeTypes.DOC,
                  MimeTypes.DOCX,
                ]}
                cancelResult={
                  <Button variant="transparent" type="button">
                    {t("common.cancel")}
                  </Button>
                }
                acceptResult={
                  <Button variant="primary" type="button">
                    {t("common.upload")}
                  </Button>
                }
                onAccept={onUploadDocumentHandler}
              />
            </>
          )}
          {deviceFiles.length > 0 && (
            <>
              <Title tw="pb-0 mb-4">
                <h3>{fileListHeadingText}</h3>
              </Title>
              <FileList directory={deviceFiles} onDelete={onDeleteHandler} />
            </>
          )}
          <ButtonGroup sticky="bottom" position="center">
            <Button variant="default" type="button" onClick={onCloseHandler}>
              {t("common.close")}
            </Button>
          </ButtonGroup>
        </div>
      </OffCanvas>
    </div>
  )
}
