import React, { useContext } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Icon } from "@clevertrack/shared"
import format from "date-fns/format"
import { DeviceStatusEnum, DeviceTypeEnum, IDevice } from "../types"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { getDisplayKey } from "../helper"
import isToday from "date-fns/isToday"
import { AppContext } from "context"
import { useTranslation } from "react-i18next"

const StyledHealthIcon = styled.span<{
  includeSlash?: boolean
  showDetails: boolean
}>`
  .details {
    ${tw`opacity-60 text-xl md:(text-lg) lg:(text-xl)`}
  }

  .icon {
    ${tw`relative border border-brand-gray-lighter border-solid flex items-center justify-center bg-white shadow-mapButton rounded-full ml-4 w-8 h-8 opacity-80`}

    ${(props) =>
      props.includeSlash
        ? `
        &:after {
        content: "";
        display: block;
        width: 1px;
        height: 80%;
        transform: rotateZ(-45deg);
        z-index: 1;
        position: absolute;
        background: currentColor;
        filter: drop-shadow(1px 0 0 white) drop-shadow(-1px 0 0 white);
      }
    `
        : ``}
  }

  ${(props) =>
    props.showDetails
      ? `
        display: grid;
        grid-template-columns: 3rem auto;
        padding: .5rem 0;

        .icon {
          justify-self: center;
          margin-left: 0;
        }
      `
      : ``}
`

type DeviceHealthProps = {
  device: IDevice
  showDetails: boolean
}

export const LostGSM: React.FC<DeviceHealthProps> = ({
  device,
  showDetails = false,
  ...props
}) => {
  const { t } = useTranslation()
  const { values, status } = device
  const currentTime = +new Date()
  if (values && [1, 2, 3, 4].includes(+status)) {
    const treshold = 60 * 5 * 1000 // 5 Minutes
    const lastConfirmationTimestamp = getDisplayKey(
      values,
      DisplayKeyEnum.LastConfirmationTime
    )
    const lastSignal = lastConfirmationTimestamp?.value
      ? +new Date(lastConfirmationTimestamp.value)
      : null

    if (lastSignal) {
      const lostGSMSignal = currentTime - lastSignal > treshold

      if (lostGSMSignal) {
        return (
          <StyledHealthIcon showDetails={showDetails} includeSlash>
            <span className="icon">
              <Icon tw="w-5 h-5" icon="signal-gsm" />
            </span>
            {showDetails && (
              <span>
                <span className="details" tw="block">{`${t(
                  "device.health_lost_gsm_no_update_since"
                )}${
                  lastSignal
                    ? isToday(lastSignal)
                      ? format(
                          lastSignal,
                          `' ${t("common.today")} ${t("common.at")} ' HH:mm`
                        )
                      : format(
                          lastSignal,
                          `' d.' dd.MM.yyyy '${t("common.at")} ' HH:mm`
                        )
                    : null
                }`}</span>
              </span>
            )}
          </StyledHealthIcon>
        )
      }
    }
  }
  return null
}

export const LostGPS: React.FC<DeviceHealthProps> = ({
  device,
  showDetails,
  ...props
}) => {
  const { t } = useTranslation()
  const { lastsatcountvalid, GPSFailure } = device
  const lastSatCountValidDate = lastsatcountvalid
    ? new Date(`${lastsatcountvalid}Z`)
    : null
  return !!GPSFailure ? (
    <StyledHealthIcon showDetails={showDetails} includeSlash>
      <span className="icon">
        <Icon tw="w-5 h-5" icon="signal" />
      </span>
      {showDetails && (
        <span>
          <span className="details" tw="block">{`${t(
            "device.health_lost_gps_no_signal"
          )}`}</span>
          <span className="details" tw="block">
            {`(${t("device.health_lost_gps_last_known_position")}${
              lastSatCountValidDate
                ? isToday(lastSatCountValidDate)
                  ? format(
                      lastSatCountValidDate,
                      `' ${t("common.today")} ${t("common.at")} ' HH:mm`
                    )
                  : format(
                      lastSatCountValidDate,
                      ` dd.MM.yyyy '${t("common.at")} ' HH:mm`
                    )
                : null
            }
            `}
          </span>
        </span>
      )}
    </StyledHealthIcon>
  ) : null
}

export const BatteryState: React.FC<DeviceHealthProps> = ({
  device,
  showDetails,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    deviceType,
    internalVoltageLevel,
    batteryLongevityVolt72,
    batteryLongevity,
    status,
    externalVoltageLost,
  } = device

  const voltageLevel = [DeviceTypeEnum.Beacon, DeviceTypeEnum.Asset].includes(
    deviceType
  )
    ? deviceType === DeviceTypeEnum.Asset
      ? batteryLongevityVolt72
      : batteryLongevity
    : internalVoltageLevel

  const resolveDetailText = () => {
    switch (deviceType) {
      case DeviceTypeEnum.Asset:
        return `${t(
          "device.health_battery_state_tracker_battery"
        )} ${voltageLevel}% ${t(
          "device.health_battery_state_tracker_battery_remaining"
        )}`
      case DeviceTypeEnum.Beacon:
        return `${t(
          "device.health_battery_state_beacon_battery"
        )} ${voltageLevel}% ${t(
          "device.health_battery_state_beacon_battery_remaining"
        )}`
      default:
        return `${t("device.health_internal_backup_battery")} ${voltageLevel}%`
    }
  }

  const resolveOfflineText = () => {
    switch (deviceType) {
      case DeviceTypeEnum.Beacon:
        return `${t("device.health_battery_state_beacon_offline")}, ${t(
          "device.health_battery_state_last_known_battery_level"
        )} ${voltageLevel}%`
      default:
        return `${t("device.health_battery_state_tracker_offline")}, ${t(
          "device.health_battery_state_last_known_battery_level"
        )} ${voltageLevel}%`
    }
  }

  const resolveRenderCondition = () => {
    if (
      [DeviceTypeEnum.Asset, DeviceTypeEnum.Beacon].includes(deviceType) &&
      voltageLevel < 15
    ) {
      return true
    }

    if (
      [
        DeviceTypeEnum.Car,
        DeviceTypeEnum.Machine,
        DeviceTypeEnum.MaterialScanner,
      ].includes(deviceType) &&
      externalVoltageLost
    ) {
      return true
    }

    return false
  }

  if (voltageLevel >= 0 && resolveRenderCondition()) {
    let icon
    if (voltageLevel < 5) {
      icon = "battery-slash"
    } else if (voltageLevel >= 5 && voltageLevel < 50) {
      icon = "battery-quarter"
    } else if (voltageLevel >= 50 && voltageLevel < 75) {
      icon = "battery-half"
    } else if (voltageLevel >= 75 && voltageLevel < 100) {
      icon = "battery-three-quarters"
    } else if (voltageLevel === 100 || voltageLevel > 100) {
      icon = "battery-full"
    }

    if (status === DeviceStatusEnum.UNKNOWN) {
      icon = "battery-empty"
    }

    const detailText =
      status === DeviceStatusEnum.UNKNOWN
        ? resolveOfflineText()
        : resolveDetailText()

    return (
      <StyledHealthIcon
        showDetails={showDetails}
        includeSlash={status === DeviceStatusEnum.UNKNOWN}
      >
        <span className="icon">
          <Icon tw="w-5 h-5" icon={icon} />
        </span>
        {showDetails && <span className="details">{detailText}</span>}
      </StyledHealthIcon>
    )
  } else {
    return null
  }
}

export const ExternalPowerLost: React.FC<DeviceHealthProps> = ({
  device,
  showDetails,
  ...props
}) => {
  const { t } = useTranslation()
  const { externalVoltageLost } = device
  if (!!externalVoltageLost) {
    return (
      <StyledHealthIcon includeSlash showDetails={showDetails}>
        <span className="icon">
          <Icon tw="w-5 h-5" icon="bolt" />
        </span>
        {showDetails && (
          <span className="details">{`${t(
            "device.health_external_power_lost"
          )}`}</span>
        )}
      </StyledHealthIcon>
    )
  }
  return null
}
