import React, { useContext, FC } from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import styled from "styled-components"
import Search from "app/Search"
import { SearchContext } from "app/Search/context"
import { freetextSearch } from "app/Search/helper"

interface ZonesSearchProps {
  zones: any[]
  visibleZones: Record<string, boolean>
}

const SearchContainer = styled.div`
  ${tw`border-b border-t-0 border-l-0 border-r-0 mb-4 border-gray-200 border-solid`}

  ${(props) => props.theme.media.tablet_landscape_up`
  `}
`

export const ZonesSearch: FC<ZonesSearchProps> = ({ zones, visibleZones }) => {
  const { t } = useTranslation()
  const {
    state: { query },
  } = useContext(SearchContext)

  const getActiveDataSet = () => {
    const filteredZones = zones.filter((zone) => visibleZones[zone.id!])

    return freetextSearch(filteredZones, {
      threshold: 0.0,
      location: 0,
      distance: 0,
      ignoreLocation: query?.length > 1,
      includeScore: true,
      keys: ["name"],
    })
  }

  return (
    <SearchContainer>
      <Search
        placeholder={t("zones.search")}
        dataset={getActiveDataSet()}
        withPhoneBorder={true}
        onReset={() => {}}
      />
    </SearchContainer>
  )
}
