import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react"
import {
  DrawingManager,
  Polygon,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api"
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url"
import styled from "styled-components"
import { ICoordinates } from "app/GoogleMap/map.types"
import {
  getBounds,
  getBoundsFromGoogleMapsBounds,
  getLatLngLiteralFromTurfBbox,
} from "app/GoogleMap/helper"
import { bbox } from "@turf/bbox"
import { polygons } from "@turf/helpers"

import { IZone } from "../zones.types"
import { ZonesContext } from "../context"
import { usePointsAndClusters } from "app/GoogleMap/usePointsAndClusters"
import { MapContext } from "app/GoogleMap/context"
import { setGoogleMapsInstance } from "app/GoogleMap/actions"
import { CustomMapsButton } from "lib/CustomMapsButton"
import { IconFlip } from "app/IconFlip"
import { CompanyContext } from "app/Company/context"
import useDevicesStore from "app/Device/store"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import Icon from "app/Icon"
import { Form, Button, Input } from "@clevertrack/shared"
import { Responsive } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"
const StyledTrackersToggle = styled(CustomMapsButton)`
  position: absolute;
  z-index: 120;
  top: 6rem;
  margin-top: 0;
  right: 1rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 6rem;
    right: 1rem;
    margin-top: 0;
  `}
`

const StyledAddressSearchButton = styled(CustomMapsButton)`
  position: absolute;
  z-index: 120;
  top: 11rem;
  margin-top: 0;
  right: 1rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 11rem;
    right: 1rem;
    margin-top: 0;
  `}
`

const StyledSearchInputWrapper = styled.div`
  position: absolute;
  z-index: 120;
  top: 11rem;
  right: 5.5rem;
  display: flex;
  width: 300px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  form {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 11rem;
    right: 5.5rem;
  `}
`

const StyledSearchInput = styled(Input)`
  flex-grow: 1;
  margin: 0;
  border: none;
  font-size: 14px;
  height: 40px;
  border-radius: 0;

  &:focus {
    outline: 0;
  }
`

const StyledSearchButton = styled(Button)`
  height: 40px;
  min-width: 60px;
  margin: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`

const StyledLargeDesktopSearchInputWrapper = styled.div`
  position: absolute;
  z-index: 120;
  top: 1rem;
  left: 50%;
  transform: translateX(-25%);
  display: flex;
  width: 250px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  form {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  ${(props) => props.theme.media.xxl_desktop_up`
    width: 350px;
  `}
`

interface IMapProps {
  zone: ICoordinates[] | null
  viewZone: IZone
  onSetZone: (coords: ICoordinates[]) => void
  zonePolygon: google.maps.Polygon | null
  onSetZonePolygon: (polygon: google.maps.Polygon) => void
}

class Libs {
  static libs: Libraries = ["drawing"]
}

const mapsLoaderOptions = {
  googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY as string,
  libraries: Libs.libs,
  language: "da",
  mapIds: ["49f51d48b88a1ee1"],
}

export const Map: React.FC<IMapProps> | null = React.memo(
  ({
    zone,
    onSetZone,
    zonePolygon,
    onSetZonePolygon,
    viewZone,
    children,
    ...props
  }) => {
    const {
      state: { editZone },
    } = useContext(ZonesContext)
    const [usedZone, setUsedZone] = useState<IZone | null>(null)
    const mapsRef = useRef<google.maps.Map | null>(null)
    const [showAddressInput, setShowAddressInput] = useState(false)
    const [address, setAddress] = useState("")
    const [isSearching, setIsSearching] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
      null
    )

    const { t } = useTranslation()

    const { getCoordinatesFromAddress } = useFirebaseFunctions()

    useEffect(() => {
      setUsedZone(viewZone ?? (editZone as IZone))
    }, [editZone, viewZone])

    const {
      dispatch,
      state: { mapsInstance },
    } = useContext(MapContext)
    const {
      state: {
        companySettings: { map },
      },
    } = useContext(CompanyContext)

    const [defaultZoom, defaultCenter] = useMemo(() => {
      if (map)
        return [map.defaultZoom, { lat: map.center[0], lng: map.center[1] }]
      return [
        7,
        {
          lat: 56.0,
          lng: 9.85,
        },
      ]
    }, [map])
    const [center, setCenter] = useState<ICoordinates>(
      defaultCenter ?? {
        lat: 56.0,
        lng: 9.85,
      }
    )
    const [zoom, setZoom] = useState(defaultZoom)
    const [showTrackers, setShowTrackers] = useState(true)
    const { isLoaded } = useJsApiLoader(mapsLoaderOptions)

    const onPolygonCompleteHandler = (
      polygon: google.maps.Polygon,
      isNew: boolean
    ) => {
      const path: google.maps.MVCArray = polygon.getPath()
      const zoneCoordinates = path
        .getArray()
        .map(({ lat, lng }) => ({ lat: lat(), lng: lng() }))

      if (isNew) zoneCoordinates.push(zoneCoordinates[0])

      if (onSetZonePolygon) onSetZonePolygon(polygon)
      if (onSetZone) onSetZone(zoneCoordinates)
    }

    useEffect(() => {
      if ((usedZone && usedZone.hasOwnProperty("coordinates")) || zone) {
        const z = usedZone as IZone
        const coords = z?.coordinates ?? zone
        debugger
        const polygon = polygons([[coords.map((x) => [x.lng, x.lat])]])
        const bounds = bbox(polygon)
        const googleMapsBounds = getLatLngLiteralFromTurfBbox(bounds)
        mapsRef.current?.fitBounds(googleMapsBounds)
        // setZoom(13)
      }
      if (zone === null) {
        mapsRef.current?.setZoom(defaultZoom ?? 7)
        mapsRef.current?.setCenter(
          defaultCenter ?? {
            lat: 56.0,
            lng: 9.85,
          }
        )
      }
    }, [usedZone, zone, defaultCenter, defaultZoom])

    const onToggleTrackers = () => {
      setShowTrackers((prev) => !prev)
    }

    const onToggleAddressInput = () => {
      setShowAddressInput((prev) => !prev)
    }

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddress(e.target.value)
    }

    async function placeMarker(location) {
      if (markerRef.current) {
        markerRef.current.map = null
      }

      if (mapsRef.current && location && window.google) {
        try {
          const markerLibrary = (await google.maps.importLibrary(
            "marker"
          )) as any
          const { AdvancedMarkerElement, PinElement } = markerLibrary

          const pinElement = new PinElement({
            borderColor: "#ffffff",
            glyphColor: "#ffffff",
            scale: 1.2,
          })

          const newMarker = new AdvancedMarkerElement({
            map: mapsRef.current,
            position: location,
            content: pinElement.element,
          })

          markerRef.current = newMarker
        } catch (error) {
          console.error("Error creating advanced marker:", error)
        }
      }
    }

    const handleAddressSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      if (!address.trim()) return

      try {
        setIsSearching(true)

        const result = await getCoordinatesFromAddress({ address })

        const responseData = result.data as {
          results: Array<{
            geometry: {
              location: {
                lat: number
                lng: number
              }
            }
          }>
          status: string
        }

        if (responseData.status === "OK" && responseData.results.length > 0) {
          const location = responseData.results[0].geometry.location

          if (location && location.lat && location.lng) {
            mapsRef.current?.setCenter(location)
            mapsRef.current?.setZoom(15)
            await placeMarker(location)
          }
        } else {
          console.error("No results found for the address")
        }
      } catch (error) {
        console.error("Error getting coordinates:", error)
      } finally {
        setAddress("")
        setIsSearching(false)
        setShowAddressInput(false)
      }
    }

    const onLoadHandler = (map: google.maps.Map) => {
      dispatch(setGoogleMapsInstance(map, google.maps))
      mapsRef.current = map
    }

    console.log(zone)

    useEffect(() => {
      return () => {
        if (markerRef.current) {
          markerRef.current.map = null
        }
      }
    }, [])

    return isLoaded ? (
      <GoogleMap
        center={center}
        zoom={zoom}
        onLoad={onLoadHandler}
        options={{
          mapId: "49f51d48b88a1ee1",
          gestureHandling: "greedy",
          isFractionalZoomEnabled: false,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.HYBRID,
            ],
          },
        }}
      >
        <StyledTrackersToggle onClick={onToggleTrackers}>
          <IconFlip
            toggled={showTrackers}
            iconOn="map-marker-alt"
            iconOff="map-marker-alt-slash"
          />
        </StyledTrackersToggle>

        <Responsive
          phone={
            <>
              <StyledAddressSearchButton onClick={onToggleAddressInput}>
                <Icon icon="search" size="sm" />
              </StyledAddressSearchButton>

              {showAddressInput && (
                <StyledSearchInputWrapper>
                  <Form
                    onSubmit={handleAddressSubmit}
                    forwardRef={formRef}
                    tw="flex"
                  >
                    <StyledSearchInput
                      type="text"
                      placeholder="Indtast adresse..."
                      value={address}
                      onChange={handleAddressChange}
                      disabled={isSearching}
                      required
                    />
                    <StyledSearchButton
                      variant="default"
                      type="submit"
                      size="sm"
                      disabled={isSearching}
                    >
                      {isSearching ? (
                        <Icon icon="spinner" size="sm" />
                      ) : (
                        t("common.search")
                      )}
                    </StyledSearchButton>
                  </Form>
                </StyledSearchInputWrapper>
              )}
            </>
          }
          largeDesktop={
            <StyledLargeDesktopSearchInputWrapper>
              <Form
                onSubmit={handleAddressSubmit}
                forwardRef={formRef}
                tw="flex"
              >
                <StyledSearchInput
                  type="text"
                  placeholder="Indtast adresse..."
                  value={address}
                  onChange={handleAddressChange}
                  disabled={isSearching}
                  required
                />
                <StyledSearchButton
                  variant="default"
                  type="submit"
                  size="sm"
                  disabled={isSearching}
                >
                  {isSearching ? (
                    <Icon icon="spinner" size="sm" />
                  ) : (
                    t("common.search")
                  )}
                </StyledSearchButton>
              </Form>
            </StyledLargeDesktopSearchInputWrapper>
          }
        />

        {children}
        {showTrackers && <MapPointsAndClusters />}
        {zone === null ? (
          <DrawingManager
            options={{
              drawingControl: false,
              drawingControlOptions: {
                drawingModes: [google.maps.drawing.OverlayType.POLYGON],
              },
            }}
            drawingMode={google.maps.drawing.OverlayType.POLYGON}
            onPolygonComplete={(polygon) =>
              onPolygonCompleteHandler(polygon, true)
            }
          />
        ) : null}
        {usedZone && usedZone.hasOwnProperty("coordinates") ? (
          <Polygon
            onLoad={(polygon) => onPolygonCompleteHandler(polygon, false)}
            path={(usedZone as IZone)?.coordinates}
            options={{
              zIndex: 1000,
              geodesic: true,
            }}
          />
        ) : null}
      </GoogleMap>
    ) : null
  }
)

const MapPointsAndClusters = () => {
  const { devices } = useDevicesStore((state) => ({
    devices: state.devices,
  }))

  const {
    state: { mapsInstance },
  } = useContext(MapContext)

  const [bounds, setBounds] = useState<any>(null)
  const [zoom, setZoom] = useState<number>(7)
  const eventListenerRefs = useRef<google.maps.MapsEventListener[]>([])

  const onBoundsChangedHandler = useCallback(() => {
    if (mapsInstance) {
      const newBounds = getBoundsFromGoogleMapsBounds(
        mapsInstance.map.getBounds()
      )
      const newZoom = mapsInstance.map.getZoom()
      setZoom(newZoom)
      setBounds(newBounds.multibounds)
    }
  }, [mapsInstance])

  useEffect(() => {
    if (mapsInstance) {
      setZoom(mapsInstance.map.getZoom())
      eventListenerRefs.current.push(
        mapsInstance.map.addListener("idle", onBoundsChangedHandler)
      )
    }
    return () => {
      if (eventListenerRefs.current && eventListenerRefs.current.length > 0) {
        for (const listener of eventListenerRefs.current) {
          listener.remove()
        }
      }
    }
  }, [mapsInstance])

  const { mapOptions, mappedTrackers } = useMemo(() => {
    const trackers = devices.map(({ id, position, ...rest }) => {
      const modifiedPosition = {
        id,
        ...position,
        properties: rest,
      }
      return modifiedPosition
    })

    const options = {
      bounds: bounds ?? getBounds(trackers)?.multibounds,
      zoom,
    }

    return { mapOptions: options, mappedTrackers: trackers }
  }, [devices, zoom, bounds])

  const { renderPointsAndClusters } = usePointsAndClusters(
    mappedTrackers,
    mapOptions
  )

  return <>{renderPointsAndClusters({ wrapInOverlayView: true })}</>
}
