import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Icon from "lib/Icon"
import { Result as DefaultResult } from "app/ResultListGroup/Result"
import BarChartAsPercentage from "app/BarChart/AsPercentage"
import { hoverContentByUnit } from "../helper"
import { formatFloat } from "utils"
import { criticalSorting } from "../criticalSorting"
import { useUser } from "app/User/hooks"
import { useTranslation } from "react-i18next"

const StyledServiceItem = styled(DefaultResult)`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  padding: 1.2rem 2rem;
  column-gap: 1rem;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  margin-left: 0.5rem;
`

const StyledEdit = styled.span`
  margin-left: 0.6rem;
  color: ${(props) => props.theme.colors.gray};
  transition: ease-out all 0.2s;
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const DetailGrid = styled.div`
  display: grid;
  grid-gap: 0rem;
  grid-template-columns: 1fr;
`

const Detail = styled.div`
  opacity: 0.6;
`

function ServiceItem({ data, onClick, onServiceEntry, ...props }) {
  const { canWrite, user } = useUser()
  const { t } = useTranslation()
  const {
    type,
    index,
    unit,
    startValue,
    endValue,
    startDate,
    endDate,
    intervalDate,
    intervalValue,
  } = data

  const setColor = (index, a, b) => {
    if (index >= b) return "red"
    if (index >= a) return "yellow"
    return "success"
  }

  return (
    <StyledServiceItem
      onClick={canWrite ? onServiceEntry : () => ({})}
      css={!canWrite ? [tw`hover:(cursor-default bg-white)`] : []}
      {...props}
    >
      <section>
        <div>
          <span tw="flex items-center">
            <strong tw="truncate max-w-max w-64" title={type}>
              {type}
            </strong>
            {canWrite && (
              <>
                <StyledEdit
                  onClick={(e) => {
                    e.stopPropagation()
                    onClick(e)
                  }}
                >
                  <StyledIcon tw="opacity-50 ml-2" icon="cog" />
                </StyledEdit>
                <span tw="flex ml-auto items-center justify-center hover:(text-brand-500)">
                  <span tw="text-lg">{t("common.register")}</span>
                  <StyledIcon icon="chevron-right" size="xs" />
                </span>{" "}
              </>
            )}
          </span>
          <DetailGrid tw="text-lg flex items-center">
            {unit !== "mdr" ? (
              <Detail>
                {t("service.interval_date")}{" "}
                {formatFloat(intervalValue, "da-DK")} {t(`global_${unit}`)}
              </Detail>
            ) : (
              <Detail>
                {t("service.interval_date")} {intervalDate}{" "}
                {t(`global_${unit}`)}
              </Detail>
            )}

            {/* <Detail>{current}</Detail> */}
            {/* <Detail>{next}</Detail> */}
            {/* <Detail>{recent}</Detail> */}
          </DetailGrid>
        </div>

        <BarChartAsPercentage
          index={index}
          hoverContent={hoverContentByUnit(unit, data, {
            formatLang: user?.language ? user.language.split("-")[0] : "da",
            timeLeftText: t("common.back_decapitalize"),
            exceededText: t("common.time_exceeded_decapitalize"),
          })}
          color={setColor(index, criticalSorting[0], criticalSorting[1])}
        />
      </section>
    </StyledServiceItem>
  )
}

export default ServiceItem

ServiceItem.defaultProps = {}
ServiceItem.propTypes = {}
