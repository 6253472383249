import React from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"

interface ZonesEmptyStateProps {
  isVisible: boolean
}

const EmptyStateContainer = styled.div`
  ${tw`flex flex-col items-center bg-white justify-center text-gray-500`}
  height: 80px;

  ${(props) => props.theme.media.tablet_landscape_up`
    ${tw`p-6 h-[80px]`}
  `}

  svg {
    font-size: 2rem;

    ${(props) => props.theme.media.tablet_landscape_up`
      ${tw`mb-3 text-gray-400`}
    `}
  }

  p {
    ${tw`text-center m-2`}
  }
`

export const ZonesEmptyState: React.FC<ZonesEmptyStateProps> = ({
  isVisible,
}) => {
  const { t } = useTranslation()

  return (
    <EmptyStateContainer>
      <Icon icon={isVisible ? "eye" : "eye-slash"} size={IconSizeEnum.XL} />
      <p>
        {isVisible ? t("zones.no_visible_zones") : t("zones.no_hidden_zones")}
      </p>
    </EmptyStateContainer>
  )
}
