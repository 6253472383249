import { StorageReference } from "firebase/storage"
import React from "react"
import tw from "twin.macro"
import { DeviceDocumentDirectoryItem } from "app/DocumentUpload/DeviceDocumentUpload"
import styled from "styled-components"
import { Icon } from "@clevertrack/shared"

const StyledFileList = styled.div`
  ${tw`space-y-2 mb-4`}
  .item {
    svg {
      margin-left: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`

type FileListProps = {
  directory?: DeviceDocumentDirectoryItem[] | []
  onDelete?: (ref: StorageReference) => void
}

export const FileList: React.FC<FileListProps> = ({
  directory,
  onDelete
}) => {
  const onDeleteHandler = (ref: StorageReference) => {
    if (onDelete) {
      onDelete(ref)
    }
  }

  return (
    <StyledFileList>
      {directory?.map((item: DeviceDocumentDirectoryItem, x) => {
        return (
          <div key={`item_${x}`} tw="flex items-center w-full" className="item">
            <a
              tw="transition-all cursor-pointer block hover:text-brand-500"
              target="_blank"
              href={item.downloadURL}
            >
              <span tw="text-xl text-gray-500 mr-2">
                D. {item.uploaded.toLocaleDateString()} kl. {item.uploaded.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -
              </span>
              <span tw="text-xl leading-tight">{item.name}</span>
            </a>
            {onDelete && (
              <span
                tw="text-brand-red-500 ml-auto"
                onClick={(e) => onDeleteHandler(item.ref)}
              >
                <Icon icon="close" />
              </span>
            )}
          </div>
        )
      })}
    </StyledFileList>
  )
}
