import React, { FC, useEffect, useState } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { IconFlip } from "app/IconFlip"
import { CustomMapsButton } from "lib/CustomMapsButton"
import { useLocation } from "@reach/router"
import { useUser } from "app/User/hooks"
import { UserVisibilitySettings } from "app/User/types"
import { IconSizeEnum } from "lib/Icon"

interface ZonesToggleProps {
  toggled: boolean
  onClick: () => void
  className?: string
}

export const StyledZonesToggle = styled(CustomMapsButton)<{
  isHistoryRoute: boolean
}>`
  position: absolute;
  z-index: 120;
  top: ${(props) => (props.isHistoryRoute ? "1.5rem" : props.theme.mobileContentOffset)};
  right: 1rem;
  ${tw`print:hidden `}

  svg {
    ${tw`text-brand-black-base fill-current`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 6rem;  
    right: 1rem;
    margin-top: 0;
  `}
`

const VisibilityIndicator = styled.div<{ isEnabled: boolean }>`
  ${tw`absolute rounded-full w-3 h-3`}
  ${({ isEnabled }) =>
    isEnabled ? tw`bg-green-500` : tw`bg-red-500`}
  top: 4px;
  right: 4px;
`

export const ZonesIconToggle: FC<ZonesToggleProps> = ({
  toggled,
  onClick,
  className,
}) => {
  const location = useLocation()
  const [isHistoryRoute, setIsHistoryRoute] = useState(false)
  const { getUserVisibilitySetting } = useUser()
  const isUserVisibilitySettingEnabled = getUserVisibilitySetting(
    UserVisibilitySettings.MapZones
  )

  useEffect(() => {
    setIsHistoryRoute(location.pathname.includes("/history"))
  }, [location])

  return (
    <StyledZonesToggle
      onClick={onClick}
      className={className}
      isHistoryRoute={isHistoryRoute}
    >
      <IconFlip
        toggled={toggled}
        iconOn="layers-stack"
        iconOff="layers-stack"
        size={IconSizeEnum.MD}
      />
      <VisibilityIndicator isEnabled={isUserVisibilitySettingEnabled} />
    </StyledZonesToggle>
  )
}
