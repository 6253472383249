import React from "react"
import { Router } from "@reach/router"
import { GlobalStyles, FontFamilies } from "lib/ui/theme"
import { ThemeProvider } from "styled-components"
import PrivateRoute from "app/PrivateRoute"
import { Helmet } from "react-helmet"
import { SearchProvider } from "app/Search/context"
import { UserProvider } from "app/User/context"
import { TrackerGroupProvider } from "app/TrackerGroups/context"
import { MapProvider } from "app/GoogleMap/context"
import { SettingsProvider } from "routes/Settings/context"
import { HistoryProvider } from "routes/HistoryV2/context"
import { Zones } from "routes/Zones"
import TrackerSearch from "app/TrackerSearch"
import Start from "routes/Start"
import DashboardRoute from "routes/Dashboard"
import Service from "routes/Service"
import { History } from "routes/HistoryV2"
import Reports from "routes/Reports"
import Settings from "routes/Settings"
import Groups from "routes/Groups"
import Users from "routes/Users"
import ResetPassword from "routes/ResetPassword"
import NewPassword from "routes/NewPassword"
import FEATURE, { COMPANYFEATURES } from "data/featureFlags"
import { ZonesProvider } from "routes/Zones/context"
import { siteTheme, CustomGlobalStyles, AppGlobalStyles } from "theme"
import { ViewportContext } from "context"
import useViewport from "hooks/useViewport"
import { CompanyProvider } from "app/Company/context"
import { Alarms } from "routes/Alarms"
import { AlarmsProvider } from "routes/Alarms/context"
import { Conversation } from "app/Conversations"
import { Drivers } from "routes/Drivers"
import { ErrorBoundary } from "app/ErrorBoundary"
import useDevicesStore from "app/Device/store"
import useConversationStore from "app/Conversations/store"
import { useTranslation } from "react-i18next"
import { FirstTimeUserWrapper } from "../components/FirstTimeUserWrapper"
import { Wizard } from "routes/Wizard"
import useNotificationCenterStore from "app/NotificationCenter/store"

const StartComponent = ({ user }) => {
  return (
    <>
      <Helmet title="Kort | Clevertrack" />
      <ErrorBoundary>
        <Start />
      </ErrorBoundary>
    </>
  )
}

const SettingsComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("nav_my_settings") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Settings />
      </ErrorBoundary>
    </>
  )
}

const ZonesComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.zones") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Zones />
      </ErrorBoundary>
    </>
  )
}

const AlarmsComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.alarms") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Alarms />
      </ErrorBoundary>
    </>
  )
}

const GroupsComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.group_settings") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Groups />
      </ErrorBoundary>
    </>
  )
}

const UsersComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.users") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Users myUser={user} />
      </ErrorBoundary>
    </>
  )
}

const DashboardComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.dashboard") + ` | Clevertrack`} />
      <ErrorBoundary>
        <DashboardRoute />
      </ErrorBoundary>
    </>
  )
}

const ServiceComponent = ({ user }) => {
  // Service doesn't utilize groups at this point. As such, we give it it's own SearchProvider,
  // so it doesn't leak in to the other search widgets.
  const { t } = useTranslation()
  return (
    <SearchProvider>
      <Helmet title={t("navigation.service") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Service user={user} />
      </ErrorBoundary>
    </SearchProvider>
  )
}

const HistoryComponent = ({ user, ...props }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.history") + ` | Clevertrack`} />
      <ErrorBoundary>
        <History {...props} />
      </ErrorBoundary>
    </>
  )
}

const DriversComponent = ({ user, ...props }) => {
  const { t } = useTranslation()
  return (
    <SearchProvider>
      <Helmet title={t("navigation.driver") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Drivers user={user} {...props} />
      </ErrorBoundary>
    </SearchProvider>
  )
}

const ReportsComponent = ({ user }) => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={t("navigation.reports") + ` | Clevertrack`} />
      <ErrorBoundary>
        <Reports />
      </ErrorBoundary>
    </>
  )
}

function App() {
  const viewport = useViewport()
  const { t, ready } = useTranslation()
  useNotificationCenterStore()
  useDevicesStore()
  useConversationStore()

  if (typeof window === "undefined" || !ready) return null

  return (
    <ThemeProvider theme={siteTheme}>
      <FontFamilies />
      <GlobalStyles />
      <CustomGlobalStyles />
      <AppGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        <SettingsProvider>
          <CompanyProvider>
            <UserProvider>
              <ZonesProvider>
                <SearchProvider>
                  <TrackerGroupProvider>
                    <MapProvider>
                      <AlarmsProvider>
                        <HistoryProvider>
                          {/* <FirstTimeUserWrapper> */}
                          <Router basepath="/app">
                            <PrivateRoute
                              path="/"
                              feature={FEATURE.START}
                              component={StartComponent}
                              dashboardSidebar={
                                <TrackerSearch
                                  visibilityToggle={true}
                                  toggleOnInit={true}
                                />
                              }
                              showPlatformStatus={true}
                            />
                            <PrivateRoute
                              path="/dashboard"
                              feature={COMPANYFEATURES.DASHBOARD}
                              component={DashboardComponent}
                              dashboardTitle="Dashboard"
                            />
                            <PrivateRoute
                              path="/service"
                              feature={FEATURE.SERVICE}
                              component={ServiceComponent}
                            />
                            <PrivateRoute
                              path="/history/*trackerId"
                              feature={FEATURE.HISTORY}
                              component={HistoryComponent}
                              allowOverflowScroll
                              dashboardSidebar={
                                <TrackerSearch
                                  simple
                                  placeholder={t("history.choose_device")}
                                />
                              }
                            />
                            <PrivateRoute
                              path="/reports/*reportId"
                              feature={COMPANYFEATURES.REPORTS}
                              component={ReportsComponent}
                              dashboardTitle="Rapporter"
                              allowOverflowScroll
                            />
                            <PrivateRoute
                              path="/settings"
                              allowAllAuth
                              component={SettingsComponent}
                              dashboardTitle={t("navigation.my_settings")}
                            />
                            <PrivateRoute
                              path="/settings/groups"
                              feature={FEATURE.GROUPS}
                              component={GroupsComponent}
                              dashboardTitle={t("navigation.group_settings")}
                            />
                            <PrivateRoute
                              path="/settings/users"
                              feature={FEATURE.USERS}
                              component={UsersComponent}
                              dashboardTitle="Brugere"
                            />
                            <PrivateRoute
                              path="/settings/zones"
                              feature={FEATURE.ZONES}
                              component={ZonesComponent}
                              dashboardTitle={t("navigation.zones")}
                            />
                            <PrivateRoute
                              path="/settings/alarms"
                              feature={FEATURE.ALARMS}
                              component={AlarmsComponent}
                              dashboardTitle={t("navigation.alarms")}
                            />
                            <PrivateRoute
                              path="/settings/drivers"
                              component={DriversComponent}
                              dashboardTitle="Fører ID"
                            />
                            <PrivateRoute
                              path="/wizard"
                              component={Wizard}
                              dashboardTitle="Welcome"
                              allowAllAuth
                            />
                            <ResetPassword path="/reset-password" />
                            <NewPassword path="/new-password" />
                          </Router>
                          {/* </FirstTimeUserWrapper> */}
                        </HistoryProvider>
                        <Conversation />
                      </AlarmsProvider>
                    </MapProvider>
                  </TrackerGroupProvider>
                </SearchProvider>
              </ZonesProvider>
            </UserProvider>
          </CompanyProvider>
        </SettingsProvider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

export default App

App.defaultProps = {}

App.propTypes = {}
