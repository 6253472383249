import React, { useContext } from "react"
import "twin.macro"
import styled from "styled-components"
import CrudList from "app/CrudList"
import { Button, ButtonGroup, Card, Tag } from "@clevertrack/shared"
import Icon from "app/Icon"
import { IZone } from "../zones.types"
import { useZones } from "../hooks"
import { ZonesContext } from "../context"
import { ZonesActions, ZonesTypes } from "../actions"
import { CrudListItem } from "app/CrudList/Item"
import { useTranslation } from "react-i18next"

const StyledTags = styled.div`
  display: flex;
  position: absolute;
  top: 0;
`

const StyledCard = styled(Card)`
  ${(props) => props.theme.media.tablet_landscape_up`
    position: relative;
    transition: all .2s ease-out;

    .edit-mode & {
      opacity: 0.5;

      &.selected {
        opacity: 1;
        z-index: 100;
      }
    }
  `}
`

export const ZoneSelector: React.FC<{
  onNewZone?: () => void
  onEditZone?: () => void
  onDelete?: () => void
  onCancel?: () => void
  editing?: boolean
}> = ({ onNewZone, onEditZone, onDelete, onCancel, editing, ...props }) => {
  const {
    dispatch,
    state: { zones, editZone },
  } = useContext(ZonesContext)
  const { deleteZone } = useZones()
  const { t } = useTranslation()

  async function onDeleteZone(id) {
    await deleteZone(id)
    if (onDelete) onDelete()
  }

  function onEditHandler(zone: IZone) {
    dispatch(ZonesActions(ZonesTypes.SetCurrentEditingZone, { zone }))
    if (onEditZone) onEditZone()
  }

  return (
    <>
      <header tw="hidden md:block">
        <h3>{t("common.zones_plural")}</h3>
        <Button icon="right" variant="primary" size="sm" onClick={onNewZone}>
          <span>{t("zones.new")}</span>
          <Icon size="sm" icon="plus" />
        </Button>
      </header>
      <CrudList {...props}>
        {zones &&
          zones
            .sort((a, b) => (a?.name > b?.name ? 1 : -1))
            .map((zone) => {
              const { id, name, description, active } = zone
              return (
                <CrudListItem
                  key={id}
                  item={zone}
                  editID={editZone && editZone.id}
                  onDelete={onDeleteZone}
                  onCancel={onCancel}
                  onEdit={onEditHandler}
                >
                  <div>
                    <h4>{name}</h4>
                    {description !== "" && <small>{description}</small>}
                    <StyledTags>
                      {active ? null : (
                        <Tag title="Disabled" variant="danger" show />
                      )}
                    </StyledTags>
                  </div>
                </CrudListItem>
              )
            })}
      </CrudList>
      <ButtonGroup tw="mt-auto md:hidden px-8 bg-white" sticky="bottom">
        <Button icon="right" variant="primary" size="md" onClick={onNewZone}>
          <span>{t("zones.new")}</span>
          <Icon size="sm" icon="plus" />
        </Button>
      </ButtonGroup>
    </>
  )
}
