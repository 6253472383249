import React, { FC } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { ZonesVisibleSection } from "./ZonesVisibleSection"
import { ZonesHiddenSection } from "./ZonesHiddenSection"
import { IZone } from "../zones.types"

interface ZonesListProps {
  visibleZonesList: IZone[]
  hiddenZonesList: IZone[]
  visibleZones: Record<string, boolean>
  isEditMode: boolean
  toggleZoneVisibility: (zoneId: string) => void
  toggleEditMode: () => void
  onZoneClick: (zone: IZone) => void
  isHistoryRoute: boolean
  isZonesToggleOn: boolean
}

const ListContainer = styled.div<{ isHistoryRoute: boolean }>`
  ${tw`overflow-y-auto flex flex-col`}

  ${(props) => props.isHistoryRoute ? tw`max-h-[40rem]` : tw`max-h-[15rem]`}

  ${(props) => props.theme.media.tablet_landscape_up`
    ${tw`max-h-[40rem]`}
  `}
`

export const ZonesList: FC<ZonesListProps> = ({
  visibleZonesList,
  hiddenZonesList,
  visibleZones,
  isEditMode,
  toggleZoneVisibility,
  toggleEditMode,
  onZoneClick,
  isHistoryRoute,
  isZonesToggleOn,
}) => {
  return (
    <ListContainer isHistoryRoute={isHistoryRoute}>
      <ZonesVisibleSection
        visibleZones={visibleZonesList}
        visibilityState={visibleZones}
        isEditMode={isEditMode}
        onToggleVisibility={toggleZoneVisibility}
        onZoneClick={onZoneClick}
        isZonesToggleOn={isZonesToggleOn}
      />

      <ZonesHiddenSection
        hiddenZones={hiddenZonesList}
        editMode={isEditMode}
        toggleEditMode={toggleEditMode}
        onToggleVisibility={toggleZoneVisibility}
        onZoneClick={onZoneClick}
        isZonesToggleOn={isZonesToggleOn}
      />
    </ListContainer>
  )
}
