import React, {
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
  useCallback,
} from "react"
import PropTypes from "prop-types"
import styled, { css, keyframes } from "styled-components"
import Icon from "lib/Icon"
import tw from "twin.macro"
import { format } from "date-fns"
import AnimatedPosition from "./AnimatedPosition"
import TrackerStatus from "app/TrackerStatus"
import TrackerHeading from "app/TrackerHeading"
import useOnClickOutside from "hooks/useOnClickOutside"
import { getStatusCode, statusMap } from "app/TrackerStatus/helper"
import { Radar } from "@clevertrack/shared"
import {
  LostGSM,
  LostGPS,
  ExternalPowerLost,
  BatteryState,
} from "app/Device/Health"
import { useDevices } from "app/Device/hooks"
import useDevicesStore from "app/Device/store"
import { useTranslation } from "react-i18next"

const StyledPoint = styled.span<{ clickable: boolean; toggled: boolean }>`
  width: 1px;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${(props) => (props.clickable ? `pointer` : `default`)};
  z-index: ${(props) => (props.toggled ? `500` : `120`)};

  &:hover {
    z-index: 600;
  }
`

const StyledAnimatedPosition = styled(AnimatedPosition)`
  z-index: 140;
`

const StyledIcon = styled(Icon)`
  width: 1.4rem;
  height: 1.4rem;
  fill: white;
  transform: translate3d(0.2rem, 0, 0);
`

const StyledInformation = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-1.3rem, -50%);
  -webkit-font-smoothing: antialiased;
  background: white;
  padding: ${(props) =>
    props.showDeviceHealth
      ? `0.4rem 1rem 0.4rem 2.8rem`
      : `0.4rem 0.4rem 0.4rem 2.8rem`};
  border-radius: 1.5rem 0 0 1.5rem;
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  z-index: 100;
  white-space: nowrap;
  display: flex;
  // flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  /* &:after {
    width: 0;
    height: 0;
    border-right: 5px solid #fff;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    position: absolute;
    content: "";
    display: block;
    left: -5px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    filter: drop-shadow(-1px 0 1px rgba(0, 0, 0, 0.2));
  } */
`

const StyledPointDetails = styled.div<{ emphasize: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 100%;
  width: max-content;
  ${(props) =>
    props.emphasize
      ? `transform: translate(0, 0); padding: 0.75rem 2rem 0.5rem 1.25rem;`
      : `transform: translate(0, 0); padding: 0.5rem 2rem 0.5rem 1rem;`}
  -webkit-font-smoothing: antialiased;
  background: white;
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  z-index: 30;
  font-size: 1.4rem;
`

const CustomPulse = keyframes`
  to {
    transform: scale(1.5);
    background: currentColor;
    border-color: currentColor;
  }
`

const CustomPulseRule = css`
  ${CustomPulse} 1.2s;
`

const StyledRadar = styled(Radar)`
  width: 2rem;
  height: 2rem;
  z-index: 100;

  &:before {
    border: none;
    animation: ${CustomPulseRule};
    background: white;
  }
  &:after {
    display: none;
  }
`

function Point({
  children,
  onClick,
  onMouseOver,
  toggleChildrenOnClick,
  disableEnlargeIfEmphasized = false,
  hideInfo,
  emphasize,
  emphasizeLast,
  onChildrenVisibilityChange,
  currentTripDuration,
  ...props
}) {
  const {
    id,
    properties: {
      name,
      heading,
      status,
      speed,
      address,
      timestamp,
      duration,
      in_progress,
      is_part_of_ongoing_trip,
    },
    lat,
    lng,
  } = props
  const { toggledDevices } = useDevicesStore((state) => ({
    toggledDevices: state.toggledDevices,
  }))

  const { t } = useTranslation()

  const [showChildren, setShowChildren] = useState(false)
  const timestampRef = useRef(null)
  const { showDeviceHealth } = useDevices()

  useLayoutEffect(() => {
    timestampRef.current = timestamp
    return () => {
      timestampRef.current = null
    }
  }, [])

  const pointInfoRef = useRef(null)
  useOnClickOutside(pointInfoRef, () => {
    setShowChildren(false)
  })

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation()
      if (onClick) onClick({ lat, lng }, id, props.properties)
      if (toggleChildrenOnClick) setShowChildren((prev) => !prev)
    },
    [onClick, toggleChildrenOnClick, setShowChildren, props.properties, id]
  )

  const onMouseEnterHandler = useCallback(
    (e) => {
      e.stopPropagation()
      if (onMouseOver) onMouseOver(e)
    },
    [onMouseOver]
  )

  function renderChildren() {
    if (children) return children
    const kmPerHours = `${speed} ${t("common.kmh")}`
    const time = format(
      new Date(`${timestamp.indexOf("Z") > 0 ? timestamp : `${timestamp}Z`}`),
      "HH:mm:ss"
    )
    const mappedStatus = getStatusCode(status)
    const { strKey } = statusMap.find((s) => s.code === mappedStatus)
    const mappedStatusText = t(strKey)
    return (
      <StyledPointDetails ref={pointInfoRef} emphasize={emphasize}>
        <span tw="mb-2 text-xl opacity-80 font-bold">{`${mappedStatusText} ${t(
          "common.at"
        )} ${time}${duration ? ` ${t("common.in")} ${duration}` : ``}`}</span>
        <span tw="opacity-80 text-xl">{address}</span>
      </StyledPointDetails>
    )
  }

  const renderIcon = () => {
    switch (status) {
      case 0:
        return <StyledIcon icon="stop" />
      case 1:
      case 4:
        return (
          <StyledIcon
            css={disableEnlargeIfEmphasized ? [tw`w-4 h-4`] : []}
            icon="play"
          />
        )
      default:
        return null
    }
  }

  const currentDevice = toggledDevices.find((x) => +x.id === +id)

  const toggleDeviceHealth = useMemo(
    () => currentDevice && showDeviceHealth(currentDevice),
    [currentDevice]
  )

  return (
    <StyledPoint
      onClick={onClickHandler}
      clickable={!hideInfo || toggleChildrenOnClick}
      toggled={showChildren}
      {...props}
    >
      <StyledAnimatedPosition
        onMouseEnter={onMouseEnterHandler}
        timestamp={timestamp}
      >
        {is_part_of_ongoing_trip ? (
          <TrackerStatus status={status} tw="overflow-hidden">
            {in_progress && <StyledRadar />}
          </TrackerStatus>
        ) : (
          <TrackerStatus
            status={status}
            emphasize={
              (emphasize || (emphasizeLast && [0, 1, 4].includes(status))) &&
              !disableEnlargeIfEmphasized
            }
            {...props.properties}
          >
            {[1, 3, 4, 6].some((num) => num === status) && heading && (
              <TrackerHeading speed={speed} heading={heading} />
            )}
            {emphasize && renderIcon()}
            {emphasizeLast && renderIcon()}
          </TrackerStatus>
        )}
      </StyledAnimatedPosition>
      {toggleChildrenOnClick && showChildren ? renderChildren() : null}
      {!toggleChildrenOnClick && children}
      {!hideInfo && (
        <StyledInformation showDeviceHealth={toggleDeviceHealth}>
          <span tw="block">{name}</span>
          {currentTripDuration && (
            <span tw="flex flex-col md:(flex-row) items-baseline text-lg opacity-80 border-solid border-0 border-l border-brand-gray-light ml-4 pl-4">
              {/* <Icon icon="clock" size={IconSizeEnum.SM} /> */}
              <span tw="md:(inline)">{currentTripDuration}</span>
              <span tw="md:(ml-1 inline)">
                siden {[0, 5].includes(status) ? `stop` : `start`}
              </span>
            </span>
          )}
          {toggleDeviceHealth && (
            <span tw="border-solid flex border-0 border-l border-brand-gray-light ml-4">
              <LostGSM device={currentDevice} />
              <LostGPS device={currentDevice} />
              <ExternalPowerLost device={currentDevice} />
              <BatteryState device={currentDevice} />
            </span>
          )}
        </StyledInformation>
      )}
    </StyledPoint>
  )
}

export default Point

Point.defaultProps = {
  onClick: () => {},
}

Point.propTypes = {
  children: PropTypes.node,
}
