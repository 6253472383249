import React, { useState } from "react"
import styled from "styled-components"
import "twin.macro"
import { Button, Card } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"
const StyledCrudListItem = styled.li`
  transition: all 0.2s ease-out;
`

const StyledCard = styled(Card)`
  position: relative;
  transition: all 0.2s ease-out;
  ${(props) => props.theme.media.tablet_landscape_up`
    .edit-mode & {
      opacity: 0.5;

      &.selected {
        opacity: 1;
        z-index: 100;
      }
    }
  `}
`

interface ICrudListItemProps {
  item: any
  onDelete: (...args) => void
  onCancel: (...args) => void
  onEdit: (...args) => void
}

export const CrudListItem: React.FC<ICrudListItemProps> = ({
  item,
  editID,
  children,
  onDelete,
  onCancel,
  onEdit,
  ...props
}) => {
  const [confirmDeleteID, setConfirmDeleteID] = useState<null | string>(null)
  const { t } = useTranslation()
  const onDeleteHandler = (id?: string) => {
    if (id) {
      setConfirmDeleteID(id)
    } else setConfirmDeleteID(null)
  }

  const onConfirmDelete = async () => {
    await onDelete(confirmDeleteID)
    // setConfirmDeleteID(null)
  }

  const onEditHandler = (editItem: any) => {
    onEdit(editItem)
  }

  const onCancelHandler = () => {
    setConfirmDeleteID(null)
    onCancel()
  }

  return (
    <StyledCrudListItem className={`${editID ? "edit-mode" : ""}`} {...props}>
      <StyledCard
        size="sm"
        className={`${item && item.id === editID ? "selected" : ""}`}
      >
        {children}
        {item.name !== t("common.no_groups") && (
          <div className="actions" tw="space-x-8">
            {confirmDeleteID === item.id ? (
              <>
                <Button
                  appearance="link"
                  variant="transparent"
                  size="sm"
                  onClick={() => onDeleteHandler()}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => onConfirmDelete()}
                >
                  {t("common.confirm")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  appearance="link"
                  variant="transparent"
                  tw="text-brand-red-400"
                  size="sm"
                  onClick={() => onDeleteHandler(item.id)}
                >
                  {t("common.delete")}
                </Button>
                {item && item.id === editID ? (
                  <Button variant="default" size="sm" onClick={onCancelHandler}>
                    {t("common.cancel")}
                  </Button>
                ) : (
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => onEditHandler(item)}
                  >
                    {t("common.edit")}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </StyledCard>
    </StyledCrudListItem>
  )
}
