import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { IZone } from "../zones.types"

interface ZonesItemProps {
  zone: IZone
  isVisible: boolean
  isEditMode?: boolean
  onToggleVisibility: (zoneId: string) => void
  onZoneClick: (zone: IZone) => void
}

const ZoneItem = styled.div`
  ${tw`py-3 px-4 bg-white border-b border-gray-200 flex justify-between items-center hover:bg-gray-50 cursor-pointer`}
  &:last-child {
    ${tw`border-b-0`}
  }
`

const ZoneInfo = styled.div<{ isVisible?: boolean }>`
  ${tw`flex-grow pr-2`}
  ${({ isVisible }) => !isVisible && tw`opacity-75`}
`

const ZoneName = styled.span<{ isVisible?: boolean }>`
  ${tw`block font-medium text-gray-900 truncate text-xl`}
  ${({ isVisible }) => !isVisible && tw`text-gray-500`}
`

const ZoneAddress = styled.span<{ isVisible?: boolean }>`
  ${tw`block text-base text-gray-500 max-w-96 break-words`}
  ${({ isVisible }) => !isVisible && tw`text-gray-400`}
`

const VisibilityButton = styled.button`
  ${tw`cursor-pointer border-0 p-2 w-10 h-10 overflow-hidden rounded-full border-none outline-none flex items-center justify-center`}
  transition: all 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.grayDark};

  &:hover {
    ${tw`text-black`}
  }

  &:focus {
    ${tw`outline-none ring-2 ring-blue-400`}
  }
`

const LocationIcon = styled(Icon)<{ isVisible?: boolean }>`
  ${tw`mr-3 flex-shrink-0`}
  ${({ isVisible }) => !isVisible && tw`text-gray-500`}
`

export const ZonesItem: React.FC<ZonesItemProps> = ({
  zone,
  isVisible,
  isEditMode = false,
  onToggleVisibility,
  onZoneClick,
}) => {
  const handleItemClick = () => {
    onZoneClick(zone)
  }

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onToggleVisibility(zone.id!)
  }

  return (
    <ZoneItem onClick={handleItemClick}>
      {isEditMode ? (
        <>
          <ZoneInfo isVisible={isVisible}>
            <ZoneName isVisible={isVisible}>{zone.name}</ZoneName>
            {zone.description && (
              <ZoneAddress isVisible={isVisible}>
                {zone.description}
              </ZoneAddress>
            )}
          </ZoneInfo>
          <VisibilityButton onClick={handleToggleClick}>
            <Icon
              icon={isVisible ? "eye" : "eye-slash"}
              size={IconSizeEnum.MD}
            />
          </VisibilityButton>
        </>
      ) : (
        <>
          <LocationIcon
            icon="map-marker-alt"
            size={IconSizeEnum.MD}
            isVisible={isVisible}
          />
          <ZoneInfo isVisible={isVisible}>
            <ZoneName isVisible={isVisible}>{zone.name}</ZoneName>
            {zone.description && (
              <ZoneAddress isVisible={isVisible}>
                {zone.description}
              </ZoneAddress>
            )}
          </ZoneInfo>
        </>
      )}
    </ZoneItem>
  )
}
