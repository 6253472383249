import React, { useState, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Button, Responsive } from "@clevertrack/shared"
import Icon, { IconSizeEnum } from "lib/Icon"
import { navigate } from "gatsby"
import tw from "twin.macro"
import PopOver from "app/PopOver"
import Settings from "./Panels/Settings"
import {
  general_category,
  activity_today_category,
  measurements_category,
} from "app/TrackerKPI/Widget/helper"
import KPIWidget from "app/TrackerKPI/Widget"
import { UserContext } from "app/User/context"
import { useDevices } from "app/Device/hooks"
import FEATURE from "data/featureFlags"
import {
  LostGSM,
  LostGPS,
  ExternalPowerLost,
  BatteryState,
} from "app/Device/Health"
import useDevicesStore from "app/Device/store"
import { createServiceStatusCategory } from "app/TrackerKPI/Widget/helper"
import { useTranslation } from "react-i18next"

const StyledTrackerDetails = styled.div`
  max-width: 100%;
  // margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  // box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
  padding: 0 0.5rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `}

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .minidashboard {
    padding: 1rem;
    .category {
      background: ${(props) => props.theme.colors.white};


      &:first-child {
        margin-top: 0;
      }
    }

    .category_title {
      display: flex;
      padding: 0.5rem 0 0.5rem 1rem;
      font-weight: bold;
      opacity: 0.8;
      // background: ${(props) => props.theme.colors.blackOpaque};
      color: ${(props) => props.theme.colors.blackOpaque};
      font-size: 1.2rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};

      button {
        display: flex;
        align-self: flex-end;
        background: none;
        border: none;
        cursor: pointer;
        // font-weight: bold;
        margin-left: auto;
        padding: 0;
        ${tw`transition hover:(text-brand-500 opacity-100) text-xl md:(text-lg) lg:(text-lg) text-brand-500`}

        svg:first-of-type {
          margin-right: 0.5rem;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .category_items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      ${tw`pt-4 pb-0`}

      .item {
        grid-template-rows: 1fr 1fr;
        margin-bottom: 1rem;
      }

      &.full {
        grid-template-columns: 1fr;
      }
    }
  }
`

const StyledButtonContent = styled.span`
  display: flex;
  align-items: center;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: currentColor;

    &:last-child {
      width: 0.8rem;
      height: 0.8rem;
      margin-top: 0.1rem;
      margin-left: 0.4rem;
    }

    ${(props) => props.theme.media.tablet_landscape_up`
        width: 1.0rem;
        height: 1.0rem;
      `}

    ${(props) => props.theme.media.desktop_up`
        width: 1.2rem;
        height: 1.2rem;
      `}
  }
`

const StyledPopOver = styled(PopOver)`
  top: 0;
  z-index: 200;

  ${(props) =>
    !props.show &&
    css`
      box-shadow: none;
    `}

  ${(props) => props.theme.media.tablet_portrait_up`
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
 `}

 ${(props) => props.theme.media.tablet_landscape_up`
    top: 8rem;
    left: 30rem;
    position: absolute;
    width: 37rem;
 `}


  ${(props) => props.theme.media.desktop_up`
    left: 32rem;
    width: 40rem;
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    left: 35.5rem;
    width: 40rem;
  `}
`

function TrackerDetails({ children, groupID, ...props }) {
  const { syncAddress } = useDevicesStore((state) => ({
    syncAddress: state.syncAddress,
  }))

  const [toggleSettings, setToggleSettings] = useState(false)
  const {
    state: { user },
  } = useContext(UserContext)
  const { devices, showDeviceHealth } = useDevices(syncAddress)
  const { t } = useTranslation()

  const serviceStatusCategory = createServiceStatusCategory({
    hours: t("common.hour_plural"),
    notConfigured: t("service.not_configured"),
  })

  const [device, categories] = useMemo(() => {
    const dev = devices.find((d) => d.id === props.device.id)
    if (!dev) return []
    const { values: data } = dev
    return [
      dev,
      [
        {
          category: "general",
          data: data
            .filter((item) => general_category.find((x) => x.key === item.name))
            .map((item) => {
              return {
                ...general_category.find((x) => x.key === item.name),
                ...item,
              }
            })
            .filter((item) => {
              return item.formatDataFnc
                ? item.formatDataFnc(item.value) !== null
                  ? true
                  : false
                : true
            }),
        },
        {
          category: "activity_today",
          data: data
            .filter((item) =>
              activity_today_category.find((x) => x.key === item.name)
            )
            .map((item) => {
              return {
                ...activity_today_category.find((x) => x.key === item.name),
                ...item,
              }
            })
            .sort((a, b) =>
              a?.sortOrder > b?.sortOrder || (!b.sortOrder && a.sortOrder)
                ? -1
                : 1
            ),
        },
        {
          category: "service_status",
          data: data
            .filter((item) =>
              serviceStatusCategory.find((x) => x.key === item.name)
            )
            .map((item) => {
              return {
                ...serviceStatusCategory.find((x) => x.key === item.name),
                ...item,
              }
            }),
        },
        {
          category: "measurements",
          data: data
            .filter((item) =>
              measurements_category.find((x) => x.key === item.name)
            )
            .map((item) => {
              return {
                ...measurements_category.find((x) => x.key === item.name),
                ...item,
              }
            }),
        },
      ],
    ]
  }, [devices])

  function onActionClick(e, action) {
    e.stopPropagation()
    switch (action) {
      case "settings":
        setToggleSettings((prev) => !prev)
        break
      case "history":
        navigate(`/app/history/${device.id}`, {
          state: { deviceID: device.id, groupID },
        })
        break
    }
  }

  const toggleDeviceHealth = useMemo(() => device && showDeviceHealth(device), [
    device,
  ])

  return (
    <>
      <StyledTrackerDetails>
        <div className="minidashboard">
          {toggleDeviceHealth && (
            <div key="device_health" className="category" tw="mb-4">
              <span className="category_title" tw="flex">
                {t("tracker.device_status")}
                <span tw="ml-2 block mt-0.5 text-brand-red-300">
                  <Icon icon="exclamation-triangle" size={IconSizeEnum.SM} />
                </span>
              </span>
              <div className="category_items full">
                <LostGSM device={device} showDetails />
                <LostGPS device={device} showDetails />
                <ExternalPowerLost device={device} showDetails />
                <BatteryState device={device} showDetails />
              </div>
            </div>
          )}
          {categories &&
            categories.map((category) => {
              // Assert if there's any data in the category that doesn't resolve to null through formatDataFnc
              return category.data.length > 0 ? (
                <div key={category.category} className="category">
                  <span className="category_title">
                    <span>{t(category.category)}</span>
                    {category.category === "activity_today" &&
                      (!!user?.is_admin ||
                        user?.feature.includes(FEATURE.HISTORY)) && (
                        <button onClick={(e) => onActionClick(e, "history")}>
                          <StyledButtonContent>
                            <Icon icon="history" />
                            <span>{t("tracker.go_to_history")}</span>
                            <Icon icon="chevron-right" />
                          </StyledButtonContent>
                        </button>
                      )}
                  </span>
                  <div className="category_items">
                    {category.data
                      .sort((a, b) =>
                        a.sortOrder && b.sortOrder
                          ? a.sortOrder > b.sortOrder
                            ? 1
                            : -1
                          : 0
                      )
                      .map((cat, i) => (
                        <KPIWidget key={`cat_${i}`} {...cat} />
                      ))}
                  </div>
                </div>
              ) : null
            })}
          {(!!user?.is_admin ||
            user?.feature.includes(FEATURE.DEVICESETTINGS)) && (
            <Button
              variant="primary"
              size="sm"
              onClick={(e) => onActionClick(e, "settings")}
              tw="mx-auto block w-full mt-6"
            >
              <StyledButtonContent>
                <Icon icon="cog" />
                <span>{t("tracker.settings")}</span>
                <Icon icon="chevron-right" />
              </StyledButtonContent>
            </Button>
          )}
        </div>
      </StyledTrackerDetails>
      <Responsive
        phone={
          <StyledPopOver show={toggleSettings} fromBottom>
            <Settings
              user={user}
              tracker={device}
              closePanel={() => setToggleSettings(false)}
            />
          </StyledPopOver>
        }
        tabletLandscape={
          <StyledPopOver show={toggleSettings} fromLeft>
            <Settings
              user={user}
              tracker={device}
              closePanel={() => setToggleSettings(false)}
            />
          </StyledPopOver>
        }
      />
    </>
  )
}

export default TrackerDetails

TrackerDetails.defaultProps = {}
TrackerDetails.propTypes = {
  children: PropTypes.node,
}
