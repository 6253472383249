import { AlarmHintEnum } from "routes/Alarms/alarm.types"
import { HistoryHintEnum } from "routes/HistoryV2/history.types"
import { DriverIDHintEnum } from "routes/Drivers/driver.types"
import { UsersHintEnum } from "routes/Users/users.types"
import { HintKey, IHintContent } from "./hint.types"
import { PlatformStatusHintEnum } from "app/NotificationCenter/PlatformStatus/platformstatus.types"
import { getTranslationText } from "utils/i18n"
export const getHintContent = (key: HintKey, language?: string): IHintContent => {
  switch (key) {
    case HistoryHintEnum.NoActivityDays:
      return {
        content: getTranslationText("hint.history_no_activity_days", language),
      }
    case DriverIDHintEnum.ImportTemplate:
      return {
        content: getTranslationText("hint.driver_id_import_template", language),
      }
    case DriverIDHintEnum.AccessCodeInUse:
      return {
        content: getTranslationText("hint.driver_id_access_code_in_use", language),
      }
    case AlarmHintEnum.NoSelectedDevices:
      return {
        content: getTranslationText("hint.alarm_no_selected_devices", language),
      }
    case AlarmHintEnum.NoSelectedCriteria:
      return {
        content: getTranslationText("hint.alarm_no_selected_criteria", language),
      }
    case AlarmHintEnum.NoSelectedNotifications:
      return {
        content: getTranslationText("hint.alarm_no_selected_notifications", language),
      }
    case AlarmHintEnum.NoSelectedSchedule:
      return {
        content: getTranslationText("hint.alarm_no_selected_schedule", language),
      }
    case AlarmHintEnum.ScheduleWeek:
      return {
        content: getTranslationText("hint.alarm_schedule_week", language),
      }
    case AlarmHintEnum.SchedulePeriod:
      return {
        content: getTranslationText("hint.alarm_schedule_period", language),
      }
    case AlarmHintEnum.UnsavedChanges:
      return {
        content: getTranslationText("hint.alarm_unsaved_changes", language),
      }
    case UsersHintEnum.MissingDeviceAssignment:
      return {
        content: getTranslationText("hint.users_missing_device_assignment", language),
      }
    case UsersHintEnum.EmailSent:
      return {
        content: getTranslationText("hint.email_sent_info", language),
      }
    case PlatformStatusHintEnum.DataSecurity:
      return {
        content: getTranslationText("hint.platform_status_data_security", language),
      }
    default:
      return {
        content: "",
      }
  }
}
