import { create } from "zustand"
import { NotificationActionMap, NotificationTypes } from "./actions"
import { PlatformStatusNotificationProps } from "./notificationcenter.types"

type NotificationCenterState = {
    platformUpdates: PlatformStatusNotificationProps[]
}

type NotificationCenterActions = {
    dispatch: (action: NotificationActionMap) => void
}

const initialState: NotificationCenterState = {
    platformUpdates: [],
}

const useNotificationCenterStore = create<
    NotificationCenterState & NotificationCenterActions
>((set, get) => ({
    ...initialState,
    dispatch: (action) => {
        const state = get()

        switch (action.type) {
            case NotificationTypes.SetPlatformUpdates:
                set({ ...state, platformUpdates: action.payload.updates })
                break

            case NotificationTypes.Reset:
                set({ ...initialState })
                break

            default:
                break
        }
    },
}))

export default useNotificationCenterStore
