import React, { useState, useEffect, useContext, FC } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { ZonesContext } from "../context"
import Expander from "lib/Expander"
import { SearchContext, SearchProvider } from "app/Search/context"
import { ZonesTypes } from "../actions"
import { useLocation } from "@reach/router"
import { ZonesToggle } from "./ZonesToggle"
import { ZonesSearch } from "./ZonesSearch"
import { ZonesEditModeBanner } from "./ZonesEditModeBanner"
import { ZonesFooter } from "./ZonesFooter"
import { ZonesList } from "./ZonesList"
import { Responsive } from "@clevertrack/shared"
import { UserVisibilitySettings } from "app/User/types"
import { useUser } from "app/User/hooks"
import { IZone } from "../zones.types"
import { MapContext } from "app/GoogleMap/context"
import { useSmoothZoomAndPan } from "app/GoogleMap/hooks"
import { useFirestoreZones } from "services/firestore/zones"
import { UserContext } from "app/User/context"

interface ZonesPanelProps {
  toggled: boolean
  className?: string
}

const Panel = styled(Expander)<{ toggled: boolean; isHistoryRoute: boolean }>`
  ${tw`fixed bg-white shadow-lg z-[1000] min-w-[32rem] print:hidden`}
  ${({ toggled }) => toggled && tw`px-4 py-2`}
  display: flex;
  flex-direction: column;
  top: ${(props) => (props.isHistoryRoute ? "1.5rem" : "6.8rem")};
  margin-top: ${(props) => (props.isHistoryRoute ? "0rem" : "2rem")};
  right: 6rem;
  box-shadow: ${(props) => props.theme.mapButtonShadow};

  ${(props) => props.theme.media.tablet_landscape_up`
    top: ${(props) => (props.isHistoryRoute ? "6rem" : "14rem")};
    right: ${(props) => (props.isHistoryRoute ? "6.5rem" : "6rem")};
    margin-top: 0;
  `}

  ${(props) => props.theme.media.desktop_up`
    top: ${(props) => (props.isHistoryRoute ? "14rem" : "14rem")};
    right: ${(props) => (props.isHistoryRoute ? "6.5rem" : "6rem")};
    margin-top: 0;
  `}

  .inner {
    white-space: nowrap;
    ${tw`text-xl space-y-2`}

    > :first-child {
      padding: 1.25rem 1rem 0.5rem;
    }

    > :only-child {
      padding: 1.25rem 1rem;
    }

    a {
      padding: 0.5rem 1rem 1rem;
      ${tw`flex items-center text-brand-black-base transition-all pt-4 hover:(text-brand-500) border-solid border-0 border-t border-gray-200 mt-4`}

      svg {
        ${tw`fill-current ml-auto`}
      }
    }
  }
`

const ZonesPanelComponent: FC<ZonesPanelProps> = ({ toggled, className }) => {
  const location = useLocation()
  const { toggleUserVisibilitySetting, getUserVisibilitySetting } = useUser()
  const { getZoneVisibility, updateZoneVisibility } = useFirestoreZones()
  const { smoothlyAnimatePanTo } = useSmoothZoomAndPan()

  const [isHistoryRoute, setIsHistoryRoute] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isZonesToggleOn, setIsZonesToggleOn] = useState(false)
  const [visibleZones, setVisibleZones] = useState<Record<string, boolean>>({})
  const [tempVisibleZones, setTempVisibleZones] = useState<
    Record<string, boolean>
  >({})

  const { state: { user } } = useContext(UserContext)

  const {
    state: { zones },
    dispatch,
  } = useContext(ZonesContext)

  const {
    state: { query, results, suggestions },
  } = useContext(SearchContext)

  const {
    state: { mapsInstance },
  } = useContext(MapContext)

  const filteredZones =
    query.length > 0
      ? [...results, ...suggestions].map((result) => result.item)
      : zones

  const visibleZonesList = filteredZones.filter((zone) => visibleZones[zone.id])
  const hiddenZonesList = filteredZones.filter((zone) => !visibleZones[zone.id])

  useEffect(() => {
    setIsHistoryRoute(location.pathname.includes("/history"))
  }, [location])

  useEffect(() => {
    const isUserVisibilitySettingEnabled = getUserVisibilitySetting(
      UserVisibilitySettings.MapZones
    )

    setIsZonesToggleOn(isUserVisibilitySettingEnabled ?? false)
  }, [getUserVisibilitySetting])

  useEffect(() => {
    const loadZoneVisibility = async () => {
      if (!user?.id) return
  
      try {
        const { hiddenZones } = await getZoneVisibility(user.id.toString())
        const newVisibleZones: Record<string, boolean> = {}

        zones.forEach(zone => {
          newVisibleZones[zone.id!] = !hiddenZones.includes(zone.id?.toString()!)
        })

        setVisibleZones(newVisibleZones)
        setTempVisibleZones(newVisibleZones)

        const isUserVisibilitySettingEnabled = getUserVisibilitySetting(
          UserVisibilitySettings.MapZones
        )
        
        if (isUserVisibilitySettingEnabled) {
          const visibleZonesList = zones.filter(zone => !hiddenZones.includes(zone.id?.toString()!))
          dispatch({
            type: ZonesTypes.SetDisplayZoneList,
            payload: { list: visibleZonesList },
          })
        }
      } catch (error) {
        console.error("Error loading zone visibility:", error)
      }
    }
  
    loadZoneVisibility()
  }, [user?.id, zones, dispatch, getUserVisibilitySetting])

  const handleZoneClick = (zone: IZone) => {
    if (!isEditMode && visibleZones[zone.id!]) {
      const bounds = new google.maps.LatLngBounds()

      zone.coordinates.forEach((coord) => {
        bounds.extend(new google.maps.LatLng(coord.lat, coord.lng))
      })

      const center = bounds.getCenter()

      // Calculate the appropriate zoom level without actually zooming
      const map = mapsInstance?.map
      if (map) {
        const width = map.getDiv().offsetWidth
        const height = map.getDiv().offsetHeight
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()

        // Calculate the zoom level that would fit these bounds
        const WORLD_DIM = { height: 256, width: 256 }
        const ZOOM_MAX = 14

        const latFraction = Math.abs(ne.lat() - sw.lat()) / 180
        const lngFraction = Math.abs(ne.lng() - sw.lng()) / 360

        const latZoom = Math.floor(
          Math.log(height / WORLD_DIM.height / latFraction) / Math.LN2
        )
        const lngZoom = Math.floor(
          Math.log(width / WORLD_DIM.width / lngFraction) / Math.LN2
        )

        const finalZoom = Math.min(Math.min(latZoom, lngZoom), ZOOM_MAX)

        smoothlyAnimatePanTo(map, center, finalZoom)
      }
    }

    if (isEditMode) {
      toggleZoneVisibility(zone.id!)
    }
  }

  const toggleZoneVisibility = (zoneId: string) => {
    if (isEditMode) {
      setTempVisibleZones((prev) => ({
        ...prev,
        [zoneId]: !prev[zoneId],
      }))
    }
  }

  const handleToggleVisibility = () => {
    const newToggleState = !isZonesToggleOn
    setIsZonesToggleOn(newToggleState)
    toggleUserVisibilitySetting(UserVisibilitySettings.MapZones)

    dispatch({
      type: ZonesTypes.SetDisplayZoneList,
      payload: { list: newToggleState ? zones.filter(zone => visibleZones[zone.id!]) : [] },
    })
  }

  const handleToggleEditMode = () => {
    if (!isEditMode) {
      setTempVisibleZones({ ...visibleZones })
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }

  const handleSaveChanges = async () => {
    setVisibleZones(tempVisibleZones)
    const hiddenZones = Object.entries(tempVisibleZones)
      .filter(([_, visible]) => !visible)
      .map(([id]) => id)
    
    await updateZoneVisibility(hiddenZones)

    const updatedDisplayZones = zones.filter(
      (zone) => tempVisibleZones[zone.id!]
    )

    dispatch({
      type: ZonesTypes.SetDisplayZoneList,
      payload: { list: updatedDisplayZones },
    })

    setIsEditMode(false)
  }

  const handleCancelChanges = () => {
    setTempVisibleZones(visibleZones)
    setIsEditMode(false)
  }

  return (
    <Panel
      toggled={toggled}
      className={className}
      isHistoryRoute={isHistoryRoute}
    >
      <ZonesToggle
        visibleCount={visibleZonesList.length}
        totalCount={zones.length}
        onToggleVisibility={handleToggleVisibility}
      />

      <Responsive
        phone={false}
        tabletLandscape={
          <ZonesSearch
            zones={zones}
            visibleZones={isEditMode ? tempVisibleZones : visibleZones}
          />
        }
      />

      {isEditMode && (
        <ZonesEditModeBanner
          onCancel={handleCancelChanges}
          onSave={handleSaveChanges}
        />
      )}

      <ZonesList
        visibleZonesList={
          isEditMode
            ? filteredZones.filter((zone) => tempVisibleZones[zone.id])
            : visibleZonesList
        }
        hiddenZonesList={
          isEditMode
            ? filteredZones.filter((zone) => !tempVisibleZones[zone.id])
            : hiddenZonesList
        }
        visibleZones={isEditMode ? tempVisibleZones : visibleZones}
        isEditMode={isEditMode}
        toggleZoneVisibility={toggleZoneVisibility}
        toggleEditMode={handleToggleEditMode}
        onZoneClick={handleZoneClick}
        isHistoryRoute={isHistoryRoute}
        isZonesToggleOn={isZonesToggleOn}
      />

      <ZonesFooter />
    </Panel>
  )
}

export const ZonesPanel = (props: ZonesPanelProps) => {
  return (
    <SearchProvider>
      <ZonesPanelComponent {...props} />
    </SearchProvider>
  )
}
