import React, { useState, useEffect, useRef, useContext } from "react"
import "twin.macro"
import styled from "styled-components"
import cogoToast from "@clevertrackdk/cogo-toast"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Button, Form, FormField, Textarea } from "@clevertrack/shared"
import {
  readVehicleCalibration,
  updateVehicleCalibration,
} from "services/calibration"
import { UserContext } from "app/User/context"
import { useUser } from "app/User/hooks"
import { useTranslation } from "react-i18next"

type ServiceInformationProps = {
  deviceID: number
}

const Title = styled.div`
  padding-bottom: 2rem;
  font-weight: bold;
  h3 {
    margin-bottom: 0;
  }
`

export const ServiceInformation: React.FC<ServiceInformationProps> = ({
  deviceID,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    state: { user },
  } = useContext(UserContext)
  const [showServiceInformation, setShowServiceInformation] = useState(false)
  const [serviceInformation, setServiceInformation] = useState("")
  const [vehicleCalibrationData, setVehicleCalibrationData] = useState({})
  const [editMode, setEditMode] = useState(false)
  const textAreaRef = useRef(null)
  const { canWrite } = useUser()

  useEffect(() => {
    readVehicleCalibration(deviceID).then((res) => {
      // debugger
      setVehicleCalibrationData(res.data.vehicle)
      if (res && res.data.vehicle.note2) {
        setServiceInformation(res.data.vehicle.note2)
        if (res.data.vehicle.note2 !== "") {
          setShowServiceInformation(true)
        }
      }
    })

    return () => {
      setShowServiceInformation(false)
      setServiceInformation("")
      setVehicleCalibrationData({})
    }
  }, [deviceID])

  const onSubmitHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const payload = {
      ...vehicleCalibrationData,
      note2: serviceInformation,
    }
    onSave(payload).then((res) => {
      if (res === "OK") {
        setEditMode(false)
        cogoToast.success(t("service.service_information_saved"))
      }
    })
  }

  const onDeleteHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const payload = {
      ...vehicleCalibrationData,
      note2: "",
    }
    onSave(payload).then((res) => {
      if (res === "OK") {
        cogoToast.success(t("service.service_information_deleted"))
        setServiceInformation("")
        setShowServiceInformation(false)
        setEditMode(false)
      }
    })
  }

  const onSave = async (payload) => {
    const res = await updateVehicleCalibration(deviceID, payload)
    if (res?.status === 200) {
      return "OK"
    } else {
      cogoToast.error(t("error.service_information"))
      return "Error"
    }
  }

  const onToggleEditHandler = () => {
    setShowServiceInformation(true)
    setEditMode(true)
  }

  return (
    <>
      {!showServiceInformation ? (
        canWrite ? (
          <div tw="my-8">
            <span
              tw="flex items-center text-brand-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
              onClick={onToggleEditHandler}
            >
              <span tw="mr-2 text-xl border-dotted border-0 border-b ">
                {t("service.add_service_information")}
              </span>
              <Icon size={IconSizeEnum.SM} icon="plus" />
            </span>
          </div>
        ) : null
      ) : (
        <>
          <Title tw="pb-0 mb-4">
            <h3>{t("service.service_information")}</h3>
          </Title>
          {editMode || serviceInformation === "" ? (
            <>
              <Form onSubmit={onSubmitHandler} tw="mb-8">
                <FormField
                  label={t("service.service_information")}
                  validationKey="description"
                >
                  <Textarea
                    rows="6"
                    tw="text-xl bg-white"
                    disabled={!canWrite}
                    value={serviceInformation}
                    onChange={(e) => setServiceInformation(e.target.value)}
                    placeholder={t("service.service_information_placeholder")}
                    forwardRef={textAreaRef}
                  />
                </FormField>
                {(user?.is_admin === 1 || canWrite) && (
                  <div tw="mt-4 flex justify-end">
                    <Button
                      tw="w-24 text-brand-red-500"
                      size="sm"
                      variant="transparent"
                      onClick={onDeleteHandler}
                    >
                      {t("common.delete")}
                    </Button>
                    <Button tw="w-24" type="submit" size="sm">
                      {t("common.save")}
                    </Button>
                  </div>
                )}
              </Form>
            </>
          ) : (
            <>
              <p tw="text-xl leading-snug whitespace-pre-line">
                {serviceInformation}
              </p>
              <span
                tw="flex items-center text-brand-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
                onClick={() => {
                  setEditMode(true)
                }}
              >
                <span tw="mr-2 text-xl border-dotted border-0 border-b">
                  {t("service.edit")}
                </span>
              </span>
            </>
          )}
        </>
      )}
    </>
  )
}
