import React, { FC } from "react"
import styled from "styled-components"
import tw from "twin.macro"

type BadgeVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
type BadgeSize = "sm" | "md" | "lg"

interface BadgeProps {
  children?: React.ReactNode
  variant?: BadgeVariant
  size?: BadgeSize
  rounded?: boolean
  className?: string
}

interface StyledBadgeProps {
  variant: BadgeVariant
  size: BadgeSize
  rounded: boolean
}

const StyledBadge = styled.span<StyledBadgeProps>`
  ${tw`inline-flex items-center justify-center font-medium`}
  
  ${({ size }) =>
    size === "sm" && tw`text-sm font-semibold px-2 py-0.5 min-w-[40px]`}
  ${({ size }) =>
    size === "md" && tw`text-lg font-semibold px-3 py-1 min-w-[35px]`}
  ${({ size }) =>
    size === "lg" && tw`text-xl font-semibold px-3.5 py-1.5 min-w-[55px]`}
  
  ${({ rounded }) => (rounded ? tw`rounded-full` : tw`rounded`)}
  
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  ${({ variant, theme }) => {
    switch (variant) {
      case "primary":
        return `
          background-color: ${theme.colors.brand500};
          color: white;
        `
      case "secondary":
        return `
          background-color: ${theme.colors.grayDark};
          color: white;
        `
      case "success":
        return `
          background-color: ${theme.colors.success};
          color: white;
        `
      case "danger":
        return `
          background-color: ${theme.colors.danger};
          color: white;
        `
      case "warning":
        return `
          background-color: ${theme.colors.warning};
          color: ${theme.colors.grayDarker};
        `
      case "info":
        return `
          background-color: ${theme.colors.info};
          color: white;
        `
      case "light":
        return `
          background-color: ${theme.colors.grayLight};
          color: ${theme.colors.grayDarker};
        `
      case "dark":
        return `
          background-color: ${theme.colors.grayDarker};
          color: white;
        `
      default:
        return `
          background-color: ${theme.colors.grayLight};
          color: ${theme.colors.grayDarker};
        `
    }
  }}
`

const Badge: FC<BadgeProps> = ({
  children,
  variant = "primary",
  size = "md",
  rounded = false,
  className,
  ...props
}) => {
  return (
    <StyledBadge
      variant={variant}
      size={size}
      rounded={rounded}
      className={className}
      {...props}
    >
      {children}
    </StyledBadge>
  )
}

export { Badge }
