import { CompanyContext } from "app/Company/context"
import { ConversationWidget } from "app/Conversations"
import { UserContext } from "app/User/context"
import { useUser } from "app/User/hooks"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { MenuTypeEnum } from "context/App/app.types"
import FEATURE, { COMPANYFUNCTIONS } from "data/featureFlags"
import { useTranslation } from "react-i18next"
import { ITapBarItem } from "lib/Navigation/navigation.types"
import React, { useContext, useMemo } from "react"

export const useNavigationItems = () => {
  const {
    state: { company },
  } = useContext(CompanyContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { toggledMenus },
    dispatch,
  } = useContext(AppContext)
  const { deviceMountingUrl } = useUser()
  const { t } = useTranslation()

  const onToggleInstallation = () => {
    dispatch(
      GlobalAppActions(GlobalAppTypes.ToggleMenu, {
        key: MenuTypeEnum.DeviceInstallation,
      })
    )
  }

  const tapBarNavigationItems: ITapBarItem[] = useMemo(() => {
    const items: ITapBarItem[] = [
      {
        key: "map",
        icon: "map",
        link: "/app",
        title: t("navigation.start"),
        featureFlag: FEATURE.START,
      },
      {
        key: "service",
        icon: "car-mechanic",
        link: "/app/service",
        title: t("navigation.service"),
        featureFlag: FEATURE.SERVICE,
        partiallyActive: true,
      },
      {
        key: "history",
        icon: "history",
        link: "/app/history",
        title: t("navigation.history"),
        featureFlag: FEATURE.HISTORY,
        partiallyActive: true,
      },
      {
        key: "messenger",
        render: () => <ConversationWidget />,
        title: "Messenger",
        activeOnKey: MenuTypeEnum.Conversations,
      },
    ]
      .filter((item) => {
        if (
          item.key === "messenger" &&
          (!company?.msisdn ||
            (!user.is_admin &&
              !user?.feature.includes(COMPANYFUNCTIONS.MESSENGER)))
        )
          return null
        return item
      })
      .filter(Boolean)

    if (deviceMountingUrl) {
      items.push({
        key: "mounting",
        icon: "hard-hat",
        onClick: onToggleInstallation,
        title: t("navigation.mounting"),
        activeOnKey: MenuTypeEnum.DeviceInstallation,
      })
    }

    items.push({
      key: "nav_toggle",
      icon: "menu",
      title: t("navigation.more"),
      isNavToggle: true,
    })

    return items
  }, [deviceMountingUrl, company, user])

  return { tapBarNavigationItems }
}
