import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import "twin.macro"
import cogoToast from "@clevertrackdk/cogo-toast"
import { Button, ButtonGroup, Responsive } from "@clevertrack/shared"
import Page from "app/Page"
import UserForm from "app/UserForm"
import PasswordForm from "app/PasswordForm"
import { UserContext } from "app/User/context"
import { UserActions, UserTypes } from "app/User/actions"
import { updateUser, updatePassword } from "services/user"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const StyledGrid = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: 1fr;
  `}
`

function Settings({ children, ...props }) {
  const [resettingPassword, setResettingPassword] = useState(false)
  const { t } = useTranslation()
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext)

  function onChangeUser(e, key) {
    const editedUser = {
      ...user,
      [key]: e.target.value,
    }
    dispatch(UserActions(UserTypes.SetUser, { user: editedUser }))
  }

  function onSubmitHandler(e, invalidFields) {
    if (invalidFields.length > 0) {
      cogoToast.error(t("error.user_validation"))
    } else {
      user.username = user.email
      updateUserAsync(user.id, user)
    }
  }

  function onUpdatePasswordHandler(user) {
    if (updatePasswordAsync(user.id, user)) {
      setTimeout(() => {
        setResettingPassword(false)
        cogoToast.success(t("setting.password_changed"))
      }, 3250)
    }
  }

  async function updateUserAsync(id, user) {
    const response = await updateUser(id, user)
    if (response.data.result === "OK")
      cogoToast.success(t("setting.user_updated"))
  }

  async function updatePasswordAsync(id, user) {
    const response = await updatePassword(id, user)
    if (response.data.result === "OK")
      cogoToast.loading(t("setting.changing_password"))
    return true
  }

  function renderContent() {
    return (
      <Page>
        <StyledGrid tw="mx-auto max-w-5xl">
          {!resettingPassword ? (
            <>
              <UserForm
                onFieldChange={onChangeUser}
                user={user}
                header={
                  <header tw="flex items-center justify-between">
                    <h3>{t("navigation.my_settings")}</h3>
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      onClick={() => setResettingPassword(true)}
                    >
                      {t("setting.change_password")}
                    </Button>
                  </header>
                }
                onSubmit={onSubmitHandler}
                tw="px-6"
              >
                <ButtonGroup position="center" sticky tw="px-0 mt-8 md:hidden">
                  <Button type="submit" variant="primary">
                    {t("setting.save_profile")}
                  </Button>
                </ButtonGroup>
                <ButtonGroup position="center" tw="hidden px-0 md:flex">
                  <Button type="submit" variant="primary">
                    {t("setting.save_profile")}
                  </Button>
                </ButtonGroup>
              </UserForm>
            </>
          ) : (
            <PasswordForm
              header={
                <header tw="flex items-center justify-between">
                  <h3>{t("setting.change_password")}</h3>
                </header>
              }
              onSubmit={(user) => onUpdatePasswordHandler(user)}
              onCancel={() => setResettingPassword(false)}
              tw="px-8"
            />
          )}
        </StyledGrid>
      </Page>
    )
  }

  return (
    <Responsive phone={renderContent()} tabletLandscape={renderContent()} />
  )
}

export default Settings

Settings.defaultProps = {}
Settings.propTypes = {
  children: PropTypes.node,
}
