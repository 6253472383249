import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Button } from "@clevertrack/shared"

interface ZonesEditModeBannerProps {
  onCancel: () => void
  onSave: () => void
}

const BannerContainer = styled.div`
  ${tw`bg-gray-100 p-3 border-b border-gray-200 max-w-full`}
`

const BannerContent = styled.div`
  ${tw`flex items-center`}
`

const TextContainer = styled.div`
  ${tw`flex flex-col`}
`

const Title = styled.span`
  ${tw`text-lg font-semibold text-gray-700`}
`

const Subtitle = styled.span`
  ${tw`text-lg text-gray-500 mt-1`}
`

const ActionContainer = styled.div`
  ${tw`bg-gray-50 border-b border-gray-200 flex items-center justify-center gap-4 h-20`}
`

const CancelButton = styled(Button)`
  ${tw`text-base text-gray-500 hover:text-gray-700`}
  border-radius: 0;
`

const SaveButton = styled(Button)`
  ${tw`text-base text-green-500 hover:text-green-600`}
  border-radius: 0;
`

const ActionText = styled.p`
  ${tw`text-lg`}
`

export const ZonesEditModeBanner: FC<ZonesEditModeBannerProps> = ({
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <BannerContainer>
        <BannerContent>
          <Icon icon="edit" size={IconSizeEnum.MD} tw="mr-2 text-gray-600" />
          <TextContainer>
            <Title>{t("zones.edit_mode_title")}</Title>
            <Subtitle>{t("zones.edit_mode_subtitle")}</Subtitle>
          </TextContainer>
        </BannerContent>
      </BannerContainer>

      <ActionContainer>
        <CancelButton variant="transparent" size="sm" onClick={onCancel}>
          <Icon icon="close" size={IconSizeEnum.SM} />
          <ActionText> {t("common.cancel")}</ActionText>
        </CancelButton>
        <SaveButton variant="transparent" size="sm" onClick={onSave}>
          <Icon icon="check" size={IconSizeEnum.SM} />
          <ActionText> {t("common.save")}</ActionText>
        </SaveButton>
      </ActionContainer>
    </>
  )
}
