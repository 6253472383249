import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import { httpsCallable, HttpsCallableResult } from "firebase/functions"
import {
  IContact,
  IMessage,
  INewMessagePayload,
  IReadMessagePayload,
  IResolvedConversation,
} from "app/Conversations/conversations.types"
import { CSVImportResponseType } from "app/CSVImport"
import { DocumentUpload } from "app/DocumentUpload/documentupload.types"

export enum FirebaseFunctionsEnum {
  GetCoordinatesFromAddress = "getCoordsFromAddress",
  GetAddressFromCoords = "getAddressFromCoords",
  GetDawaAddressFromCoords = "getDawaAddressFromCoords",
  SendMessage = "sendMessage",
  ReadMessages = "readMessages",
  ContactHasConversation = "contactHasConversation",
  GetDeviceCurrentTripMap = "getDeviceCurrentTripMap",
  ParseURLMeta = "parseURLMeta",
  ParseCSVFile = "parseCSV",
  GetCompanyConversations = "getCompanyConversations",
  GetUserUnreadMessages = "getUserUnreadMessages",
  GetConversationMessagesByID = "getConversationMessagesByID",
  UploadDocument = "uploadDocument",
}

export const useFirebaseFunctions = () => {
  const { functions } = useContext(FirebaseContext)

  const getCoordinatesFromAddress = httpsCallable(
    functions,
    FirebaseFunctionsEnum.GetCoordinatesFromAddress
  )

  const getAddressFromCoords = httpsCallable(
    functions,
    FirebaseFunctionsEnum.GetAddressFromCoords
  )

  const getDawaAddressFromCoords = httpsCallable(
    functions,
    FirebaseFunctionsEnum.GetDawaAddressFromCoords
  )

  const sendMessage = httpsCallable<INewMessagePayload>(
    functions,
    FirebaseFunctionsEnum.SendMessage
  )

  const setMessagesRead = httpsCallable<IReadMessagePayload>(
    functions,
    FirebaseFunctionsEnum.ReadMessages
  )

  const contactHasConversation = httpsCallable<
    { id: string; path: string }[],
    string[]
  >(functions, FirebaseFunctionsEnum.ContactHasConversation)

  const getDeviceCurrentTripMap = httpsCallable<{
    devices: string[]
    companyID: string
  }>(functions, FirebaseFunctionsEnum.GetDeviceCurrentTripMap)

  const parseURLMeta = httpsCallable<{ url: string }>(
    functions,
    FirebaseFunctionsEnum.ParseURLMeta
  )

  const parseCSVFile = httpsCallable<
    { fileData: string },
    CSVImportResponseType
  >(functions, FirebaseFunctionsEnum.ParseCSVFile)

  const getCompanyConversations = httpsCallable<
    {
      companyID: string
      userID: string
    },
    IResolvedConversation[]
  >(functions, FirebaseFunctionsEnum.GetCompanyConversations)

  const getUserUnreadMessages = httpsCallable<
    {
      companyID: string
      userID: string
      contactIDs: string[]
    },
    number
  >(functions, FirebaseFunctionsEnum.GetUserUnreadMessages)

  const getConversationMessagesByID = httpsCallable<
    {
      companyID: string
      contactID: string
    },
    IMessage[]
  >(functions, FirebaseFunctionsEnum.GetConversationMessagesByID)

  const uploadDocument = httpsCallable<
    DocumentUpload,
    string // Should return a path to the file
  >(functions, FirebaseFunctionsEnum.UploadDocument)

  return {
    getCoordinatesFromAddress,
    getAddressFromCoords,
    getDawaAddressFromCoords,
    sendMessage,
    setMessagesRead,
    contactHasConversation,
    getDeviceCurrentTripMap,
    parseURLMeta,
    parseCSVFile,
    getCompanyConversations,
    getUserUnreadMessages,
    getConversationMessagesByID,
    uploadDocument,
  }
}
