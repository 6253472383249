import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "twin.macro"
import { lighten } from "polished"
import { useTranslation } from "react-i18next"
import TrackerSearchSelector from "app/TrackerSearch/Selector"
import { freetextSearch } from "app/Search/helper"

const StyledGroupTrackerSearch = styled.div`
  input {
    margin-top: 0;
    border: 1px solid ${(props) => props.theme.colors.grayLight};
  }
`

const StyledInfo = styled.div`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid ${(props) => lighten(0.05, props.theme.colors.blue)};
  background: ${(props) => lighten(0.5, props.theme.colors.blue)};
  font-size: 1.4rem;
`

function GroupTrackerSearch({
  onAddTrackerToGroup,
  onRemoveTrackerFromGroup,
  trackers,
  trackersInGroup,
  selectedGroup,
  ...props
}) {
  const { t } = useTranslation()

  const dataset = freetextSearch(trackers, {
    threshold: 0.05,
    distance: 20,
    ignoreLocation: true,
    includeScore: true,
    keys: ["name", "note", { name: "imei", weight: 2 }, "id"],
  })

  function onRemoveHandler(id) {
    onRemoveTrackerFromGroup(id)
  }

  function onAddHandler(id) {
    onAddTrackerToGroup(id)
  }

  const availableTrackers = trackers.filter(
    (tracker) => !trackersInGroup.find((item) => item.id === tracker.id)
  )

  return (
    <StyledGroupTrackerSearch {...props}>
      <header>
        <h3>
          {selectedGroup ? (
            <>
              {t("groups.trackers_in")}{" "}
              {`"${selectedGroup?.name}" (${selectedGroup?.vehicleCount})`}
            </>
          ) : (
            <>{t("groups.trackers_in_group")}</>
          )}
        </h3>
      </header>
      <StyledInfo>{t("groups.tracker_move_hint")}</StyledInfo>
      <form>
        <TrackerSearchSelector
          dataset={dataset}
          inSelectionTitle={<h4>{t("groups.trackers_in_selection")}</h4>}
          availableForSelectionTitle={
            <h4>{t("groups.trackers_add_to_group")}</h4>
          }
          inSelection={trackersInGroup}
          availableForSelection={availableTrackers}
          onAddToSelection={onAddHandler}
          onRemoveFromSelection={onRemoveHandler}
        />
      </form>
    </StyledGroupTrackerSearch>
  )
}

export default GroupTrackerSearch

GroupTrackerSearch.defaultProps = {}
GroupTrackerSearch.propTypes = {
  children: PropTypes.node,
}
