import { differenceInMonths, parseJSON } from "date-fns"
import { getTranslationText } from "utils/i18n";
import { worthy } from "utils/worthy"

interface IntervalConfig {
  monthsUnit?: string;
  language?: string;
}

const DEFAULT_CONFIG: IntervalConfig = {
  monthsUnit: "service_months",
  language: "da",
}

const getIntervalValue = (data: any, config: IntervalConfig = {}) => {
  const { monthsUnit, language } = { ...DEFAULT_CONFIG, ...config }

  return data.unit !== getTranslationText(monthsUnit!, language)
    ? data.intervalValue
    : differenceInMonths(parseJSON(data.endDate), parseJSON(data.startDate))
}

const serviceLogInjectOriginal = (initial, injection) => {
  if (!!initial && !!injection) {
    const newEntry = { ...injection }
    const copyOriginal = [
      "id",
      "edit",
      "author",
      "date",
      "interval",
      "intervalstamp",
    ]
    const worthyChecks = ["realHours", "realMileage", "note"]
    for (const [key, _] of Object.entries(newEntry)) {
      const value = newEntry[key]

      const key_inside = (list) => list.some((check) => check === key)
      if (key_inside(worthyChecks) && !worthy(value)) {
        newEntry[key] = initial[key]
      }
      if (key_inside(copyOriginal)) {
        newEntry[key] = initial[key]
      }
    }
    return newEntry
  } else {
    return null
  }
}

const serviceLogIsDifferent = (originalEntry, newEntry) => {
  const virtual = {
    ...originalEntry,
    ...newEntry,
  }
  delete virtual.edit
  delete originalEntry.edit
  return JSON.stringify(virtual) !== JSON.stringify(originalEntry)
}

const formatDateStylish = (d: string) => {
  const newDate = d ? new Date(d) : new Date()
  const date = parseJSON(newDate)
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

export {
  getIntervalValue,
  serviceLogInjectOriginal,
  serviceLogIsDifferent,
  formatDateStylish,
}
