import React, { useState, useContext } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { ITrackerDailyLog } from "routes/HistoryV2/history.types"
import { Day } from "./Day"
import { HistoryActions, HistoryTypes } from "routes/HistoryV2/actions"
import { HistoryContext } from "routes/HistoryV2/context"
import { useUser } from "app/User/hooks"
import { UserVisibilitySettings } from "app/User/types"
import { useTranslation } from "react-i18next"

const StyledMessage = styled.div`
  ${tw`flex justify-center items-center flex-col h-full text-center`}
  p {
    font-size: 1.8rem;
    opacity: 0.5;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }
`

export const TrackerLog: React.FC<{ history: ITrackerDailyLog[] }> = ({
  history,
  tracker,
  ...props
}) => {
  const { values: displayKeys, deviceType, deviceHasCAN } = tracker
  const { toggleUserVisibilitySetting, getUserVisibilitySetting } = useUser()
  const { t } = useTranslation()

  const { dispatch } = useContext(HistoryContext)

  const showSeconds = getUserVisibilitySetting(
    UserVisibilitySettings.HistorySeconds
  )
  const showDefaultExpanded = getUserVisibilitySetting(
    UserVisibilitySettings.HistoryDefaultExpandedStatusChanges
  )

  const [toggled, setToggled] = useState(null)

  const onClickHandler = (id, trips) => {
    const toggleValue = toggled !== id ? id : null
    setToggled(toggleValue)

    dispatch(HistoryActions(HistoryTypes.Clear, {}))
  }

  const renderDay = (day) => {
    const ID = btoa(day.timestamp)
    return (
      <Day
        key={day.timestamp}
        day={day}
        deviceType={deviceType}
        deviceHasCAN={deviceHasCAN}
        displayKeys={displayKeys}
        onClick={() => onClickHandler(ID, day.trips)}
        dayToggled={toggled === ID}
        showSeconds={showSeconds}
        showDefaultExpanded={showDefaultExpanded}
      />
    )
  }

  if (history.length === 0) {
    return (
      <StyledMessage>
        <p>{t("history.no_data_in_interval")}</p>
      </StyledMessage>
    )
  }

  return (
    <>
      {history.length > 0 &&
        history.map((day) => {
          return renderDay(day)
        })}
    </>
  )
}
