import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import Icon from "lib/Icon"
import { format } from "date-fns"
import { Tooltip as DefaultTooltip } from "@clevertrack/shared"
import { HistoryContext } from "../context"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { googleMapsAddressHelper } from "app/GoogleMap/helper"
import tw from "twin.macro"
import { useTranslation } from "react-i18next"

const StyledTooltipWrapper = styled.div<{ toggled: boolean }>`
  position: relative;
  z-index: 4000;

  ${(props) => (props.toggled ? `display: block;` : `display: none;`)}
`

const StyledTooltip = styled(DefaultTooltip)`
  display: flex;
  flex-direction: column;
  span {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    svg {
      margin-right: 0.5em;
    }
  }
`

export const Tooltip: React.FC = ({ ...props }) => {
  const {
    state: { toggledTooltip },
  } = useContext(HistoryContext)
  const [address, setAddress] = useState("")
  const [lookupInProgress, setLookupInProgress] = useState(false)
  const { getAddressFromCoords } = useFirebaseFunctions()

  const { t } = useTranslation()

  const updateAddress = (data) => {
    const newAddress = googleMapsAddressHelper(data.data.results)
    setAddress(newAddress)
    setLookupInProgress(false)
  }

  useEffect(() => {
    const lookupTooltip = async () => {
      if (toggledTooltip) {
        setLookupInProgress(true)
        const data = await getAddressFromCoords(toggledTooltip.properties.pos)
        updateAddress(data)
      }
    }
    if (!!toggledTooltip && !lookupInProgress) {
      lookupTooltip()
    }
  }, [toggledTooltip])

  if (!toggledTooltip) return null

  const { properties } = toggledTooltip
  const kmPerHours = `${properties.speed} ${t("common.kmh")}`
  const time = format(
    new Date(
      `${
        properties.timestamp.indexOf("Z") > 0
          ? properties.timestamp
          : `${properties.timestamp}Z`
      }`
    ),
    "HH:mm:ss"
  )

  return (
    <StyledTooltipWrapper toggled={!!toggledTooltip}>
      <StyledTooltip omitToggleCss={true}>
        {<span>{address}</span>}
        <span>
          <Icon icon="clock" size="sm" />
          {`${time}`}
        </span>
        <span>
          <Icon icon="speed" size="sm" />
          {kmPerHours}
        </span>
      </StyledTooltip>
    </StyledTooltipWrapper>
  )
}
