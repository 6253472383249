import React from "react"
import "twin.macro"
import Search, { ISearchProps } from "app/Search"
import { freetextSearch } from "app/Search/helper"
import { useConversation } from "../hooks"

export const ContactSearch: React.FC<Partial<ISearchProps>> = ({
  placeholder,
  wrapperProps,
  data,
  ...props
}) => {
  const { contactsAndGroupsSorted } = useConversation()


  const dataset = freetextSearch(data || contactsAndGroupsSorted, {
    threshold: 0.05,
    distance: 20,
    ignoreLocation: true,
    includeScore: true,
    keys: ["first_name", "full_name", "last_name", "contact_ids.full_name", "title", "phone", "email"],
  })

  return (
    <div tw="px-4 bg-white" {...wrapperProps}>
      <Search placeholder={placeholder} dataset={dataset} {...props} />
    </div>
  )
}
