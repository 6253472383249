import React, {
  useState,
  useMemo,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react"
import styled from "styled-components"
import { Polyline } from "@react-google-maps/api"
import tw from "twin.macro"
import isEqual from "lodash-es/isEqual"
import HaversineGeolocation from "haversine-geolocation"
import GoogleMap from "app/GoogleMap"
import Cluster from "app/GoogleMap/Cluster"
import Point from "app/GoogleMap/Point"
import { HistoryContext } from "../context"
import { IMapPointOfInterest } from "../history.types"
import { HistoryActions, HistoryTypes } from "../actions"
import { Tooltip } from "./Tooltip"
import { Responsive } from "@clevertrack/shared"
import { isViewport } from "helpers/viewport"
import { ViewportContext } from "context"
import { PlaybackPanel } from "./PlaybackPanel"
import {
  arrowIntervalHelper,
  pointsOfInterestRenderer,
  setPoints,
} from "./helper"
import { BackButton } from ".."
import { useUser } from "app/User/hooks"
import { ZonesContext } from "routes/Zones/context"
import { UserVisibilitySettings } from "app/User/types"
import { ZonesActions, ZonesTypes } from "routes/Zones/actions"
import { DeviceStatusEnum } from "app/Device/types"
import sum from "lodash-es/sum"
import { flatMap } from "lodash-es"
import { useTranslation } from "react-i18next"
import { ZonesPanel } from "routes/Zones/components/ZonesPanel"
import { ZonesIconToggle } from "routes/Zones/components/ZonesIconToggle"

const StyledPoint = styled(Point)`
  cursor: pointer;

  > span {
    border-radius: 3rem;
    box-shadow: ${(props) => props.theme.mapButtonShadow};
    border: 2px solid ${(props) => props.theme.colors.white};
  }
`

const StyledTripsLoader = styled.div`
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  min-width: 30rem;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  ${tw`bg-white px-4 py-4 absolute inset-auto`}
`

const StyledRoutePoint = styled.div`
  position: relative;
  z-index: 100;
  width: 5px;
  height: 5px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  display: block;
`

export const HistoryMap: React.FC<{}> = ({ children, ...props }) => {
  const {
    dispatch,
    state: {
      filters,
      routes,
      pinnedRoutes,
      toggledRoute,
      toggledLogs,
      toggleMap,
      toggledTooltip,
      toggledRouteTimestamp,
      loadingRenderCriticalData,
      promisesCompleted,
      promisesTotal,
      animating,
    },
  } = useContext(HistoryContext)
  const [loaded, setLoaded] = useState(false)
  const [mapOptions, setMapOptions] = useState({})
  const mapRef = useRef(null)
  const mapsRef = useRef(null)
  const tripsPctLoaded = ((promisesCompleted / promisesTotal) * 100).toFixed(0)
  const vp = useContext(ViewportContext)
  const isPhoneOrTablet = isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
  const {
    state: { zoneSettingsToggled },
    dispatch: zonesDispatch,
  } = useContext(ZonesContext)
  const {
    toggleUserVisibilitySetting,
    getUserVisibilitySetting,
    user,
  } = useUser()

  const { t } = useTranslation()

  const {
    mappedRoutes,
    geoPoints,
    pointsOfInterest,
    tooltipGeoPoints,
  } = useMemo(() => {
    return !loadingRenderCriticalData && routes.length > 0
      ? setPoints(
          routes.filter((x) => x.route.some((point) => point.status !== 0)),
          pinnedRoutes,
          toggledRoute,
          toggledRouteTimestamp
        )
      : {
          mappedRoutes: [],
          geoPoints: [],
          pointsOfInterest: [],
          tooltipGeoPoints: [],
        }
  }, [
    animating,
    loadingRenderCriticalData,
    routes,
    pinnedRoutes,
    toggledRoute,
    toggledRouteTimestamp,
    toggledLogs,
    // mapOptions,
    toggleMap,
    filters,
  ])

  function onChange({ bounds, zoom }) {
    // Here, we get the initial bounds and zoom of the Google Map.
    // If any trackers are selected, we calculate the bounds based on those points instead.
    setMapOptions({ bounds, zoom })
  }

  function onMapsLoadHandler({ map, maps }) {
    mapRef.current = map
    mapsRef.current = maps
    setLoaded(true)
  }

  function renderPoint(
    item: IMapPointOfInterest,
    isFirst: boolean = false,
    isLast: boolean = false
  ) {
    if (!item || !item.hasOwnProperty("properties")) return null

    const {
      properties: { status, timestamp },
      geometry: { coordinates },
    } = item

    const [lng, lat] = coordinates
    const zIndex = [DeviceStatusEnum.RUNNING, DeviceStatusEnum.STOPPED].some(
      (x) => x === +status
    )
      ? +status === DeviceStatusEnum.RUNNING
        ? 100
        : 90
      : 10

    return (
      <StyledPoint
        key={item.key}
        hideInfo={true}
        lat={lat}
        lng={lng}
        style={{ zIndex: isFirst || isLast ? 200 : zIndex }}
        emphasize={
          isFirst &&
          toggledRouteTimestamp === null &&
          status !== DeviceStatusEnum.IDLE
        }
        emphasizeLast={isLast && toggledRouteTimestamp === null}
        toggleChildrenOnClick={true}
        onMouseOver={onResetTooltipHandler}
        // onClick={onPointClickHandler}
        {...item}
      />
    )
  }

  const onResetTooltipHandler = () => {
    dispatch(
      HistoryActions(HistoryTypes.SetTooltip, {
        toggledTooltip: null,
      })
    )
  }

  function onPolylineHoverHandler(e) {
    if (
      e.domEvent.path &&
      e.domEvent.path.some(
        (elm) =>
          elm.classList && elm.classList.value.includes("AnimatedPosition")
      )
    ) {
      onResetTooltipHandler()
      return
    }

    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    const needle = { longitude: lng, latitude: lat, accuracy: 1 }
    const closestTooltipGeopoint = HaversineGeolocation.getClosestPosition(
      needle,
      tooltipGeoPoints,
      "m"
    )
    const closestPointOfInterest = HaversineGeolocation.getClosestPosition(
      needle,
      pointsOfInterest,
      "m"
    )
    const areTooltipAndPOIEqual = isEqual(
      closestTooltipGeopoint.properties.pos,
      closestPointOfInterest.properties.pos
    )

    if (!areTooltipAndPOIEqual) {
      dispatch(
        HistoryActions(HistoryTypes.SetTooltip, {
          toggledTooltip: closestTooltipGeopoint,
        })
      )
    }
  }

  const timeoutRef = useRef(null)
  function onPolylineMouseUpHandler() {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => onResetTooltipHandler(), 4000)
  }

  function renderClusters(points, clusterInstance): React.ReactElement[] {
    const ptsAndClusters = points.map((item, i) => {
      const {
        properties: { cluster },
        geometry: { coordinates },
      } = item
      const [lng, lat] = coordinates
      return cluster ? (
        <Cluster
          key={item.key}
          cluster={clusterInstance}
          showProps={["address", "timestamp", "duration"]}
          lat={lat}
          lng={lng}
          keepOpen
          onClick={onResetTooltipHandler}
          onMouseOver={(e) => e.stopPropagation() && onResetTooltipHandler()}
          // onLeafClick={onPointClickHandler}
          {...item}
        />
      ) : (
        <StyledPoint
          key={item.key}
          lat={lat}
          lng={lng}
          hideInfo={true}
          toggleChildrenOnClick={true}
          onMouseOver={(e) => e.stopPropagation() && onResetTooltipHandler()}
          onClick={onResetTooltipHandler}
          {...item}
        />
      )
    })

    return ptsAndClusters
  }

  function renderPointsOfInterest() {
    const pointsSource = playRoute
      ? pointsOfInterest.filter((point) =>
          encounteredPointOfInterest.includes(
            point.properties.pointOfInterestKey
          )
        )
      : pointsOfInterest

    return pointsOfInterestRenderer(
      pointsSource,
      renderPoint,
      renderClusters,
      mapOptions
    )
  }

  const onToggleZonesOverlay = (toggled) => {
    // if (toggled !== getUserVisibilitySetting(UserVisibilitySettings.MapZones)) {
    toggleUserVisibilitySetting(UserVisibilitySettings.MapZones)
  }

  const onToggleZoneSettings = () => {
    zonesDispatch(
      ZonesActions(ZonesTypes.ToggleZoneSettings, {
        toggled: !zoneSettingsToggled,
      })
    )
  }

  const [playbackRoute, setPlaybackRoute] = useState([])
  const [playRoute, setPlayRoute] = useState(false)
  const [pausePlayback, setPausePlayback] = useState(false)
  const [pausePlaybackAtCount, setPausePlaybackAtCount] = useState<number>(0)
  const [encounteredPointOfInterest, setEncounteredPointOfInterest] = useState(
    []
  )
  const maxFrames = geoPoints.length
  const currentFrame = useRef(null)

  const onPlaybackPlayHandler = (e) => {
    e.stopPropagation()
    onResetTooltipHandler()
    setPlayRoute(true)
    if (pausePlayback) {
      setPausePlayback(false)
    }
    startAnimation()
  }

  const onPlaybackPauseHandler = (e) => {
    e.stopPropagation()
    if (currentFrame.current) clearTimeout(currentFrame.current)
    setPausePlaybackAtCount(playbackRoute.length)
    setPausePlayback(true)
  }

  const onPlaybackStopHandler = (e) => {
    e.stopPropagation()
    resetPlayback()
  }

  const resetPlayback = () => {
    if (currentFrame.current) clearTimeout(currentFrame.current)
    setPlayRoute(false)
    setEncounteredPointOfInterest([])
    setPlaybackRoute([])
    setPausePlayback(false)
    setPausePlaybackAtCount(0)
  }

  const startAnimation = useCallback(() => {
    let count = pausePlaybackAtCount

    function addNextFrame(nextPoint) {
      const nextTick = new google.maps.LatLng(
        nextPoint.latitude,
        nextPoint.longitude
      )
      currentFrame.current = setTimeout(() => {
        if (nextPoint.properties.pointOfInterestKey) {
          setEncounteredPointOfInterest((prev) => [
            ...prev,
            nextPoint.properties.pointOfInterestKey,
          ])
        }
        setPlaybackRoute((prev) => [...prev, nextTick])
        if (count < maxFrames) {
          if (geoPoints[count + 1]) {
            count = count + 1
            setPausePlaybackAtCount(count)
            addNextFrame(geoPoints[count])
          } else {
            resetPlayback()
          }
        }
      }, 30)
    }

    addNextFrame(geoPoints[count])
  }, [pausePlaybackAtCount, currentFrame.current, geoPoints])

  const arrow = () => {
    return {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      fillColor: "#000",
      fillOpacity: 1,
    }
  }

  const getArrowInterval = (k: number) =>
    Math.floor(arrowIntervalHelper(mapOptions, k))

  useEffect(() => {
    if (playRoute && routes.length === 0) {
      resetPlayback()
    }
  }, [routes])

  const onClickHandler = () => {
    resetPlayback()
    dispatch(HistoryActions(HistoryTypes.ToggleMap, {}))
    dispatch(
      HistoryActions(HistoryTypes.SetTooltip, {
        toggledTooltip: null,
      })
    )
  }

  const [arrowMap, entireRoutePath] = useMemo(() => {
    const sumOfGeopoints = sum(
      mappedRoutes.map((route) => route.geoPoints.length)
    )
    const insertAtEvery = getArrowInterval(sumOfGeopoints * 6)
    const memoArrowMap = new Array(sumOfGeopoints)
      .fill(null)
      .map((x, index) => {
        return index % insertAtEvery === 0
          ? {
              icon: arrow(),
              offset: `${Math.round(
                (index / sumOfGeopoints) * 100
              ).toString()}%`,
            }
          : null
      })
      .filter(Boolean)
    const memoEntireRoutePath = flatMap(mappedRoutes, (route) => {
      const {
        polyline: { path },
      } = route
      return path
    })

    return [memoArrowMap, memoEntireRoutePath]
  }, [mapOptions?.zoom, loaded, mappedRoutes])

  return (
    <>
      <Responsive
        phone={
          <BackButton
            backButtonText={t("common.back")}
            onClick={onClickHandler}
          />
        }
        desktop={<></>}
      />
      <GoogleMap
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={onMapsLoadHandler}
        onChange={onChange}
        forceAnimate
        useRawFit
        untoggleUserInteractedOnEvents={isPhoneOrTablet ? [] : ["mouseout"]}
        // omitOnChangeAfterUserInteraction={true}
        geoPoints={geoPoints}
        forceRefresh
        toggleZones={getUserVisibilitySetting(UserVisibilitySettings.MapZones)}
      >
        {animating && loadingRenderCriticalData && (
          <StyledTripsLoader>
            <h3 tw="m-0 opacity-80">{t("map.loading")}</h3>
            <p tw="m-0 mt-4 text-3xl opacity-80">{tripsPctLoaded}%</p>
          </StyledTripsLoader>
        )}
        {pointsOfInterest.length > 0 && renderPointsOfInterest()}
        {loaded && playRoute && (
          <Polyline
            key={`playback_line`}
            path={playbackRoute}
            // onLoad={animateArrow}
            options={{
              strokeColor: "#374649",
              strokeOpacity: 1,
              strokeWeight: 2,
              zIndex: 15,
              map: mapRef.current,
              icons: [{ icon: arrow(), offset: "100%" }],
            }}
          />
        )}
        {loaded &&
          !playRoute &&
          mappedRoutes.length > 0 &&
          mappedRoutes.map((route, i) => {
            const {
              polyline: { path, ...options },
            } = route
            /* const insertAtEvery = getArrowInterval(route.geoPoints.length * 10)
            const arrowMap = new Array(route.geoPoints.length)
              .fill(null)
              .map((x, index) => {
                return index % insertAtEvery === 0
                  ? {
                      icon: arrow(),
                      offset: `${Math.round(
                        (index / route.geoPoints.length) * 100
                      ).toString()}%`,
                    }
                  : null
              })
              .filter(Boolean) */
            /* let arrowMap = highDensityArrowMap
            if (route.geoPoints.length >= 21 && route.geoPoints.length < 60) {
              arrowMap = mediumDensityArrowMap
            } else if (route.geoPoints.length < 20) {
              arrowMap = lowDensityArrowMap
            } */
            return [
              <Polyline
                key={`line_${i}`}
                path={path}
                options={{
                  ...options,
                  zIndex: 15,
                  map: mapRef.current,
                  icons: arrowMap,
                }}
              />,
              <Polyline
                key={`line_${i}_bg`}
                path={path}
                options={{
                  ...options,
                  zIndex: 10,
                  strokeColor: "#fff",
                  strokeWeight: 6,
                  map: mapRef.current,
                }}
              />,
              <Responsive
                key={`line_${i}_tooltip`}
                phone={
                  <Polyline
                    path={path}
                    onMouseDown={onPolylineHoverHandler}
                    onMouseUp={onPolylineMouseUpHandler}
                    options={{
                      ...options,
                      zIndex: 20,
                      strokeColor: "transparent",
                      strokeWeight: 72,
                      map: mapRef.current,
                    }}
                  />
                }
                tabletLandscape={
                  <Polyline
                    path={path}
                    onMouseMove={onPolylineHoverHandler}
                    onMouseOut={onResetTooltipHandler}
                    options={{
                      ...options,
                      zIndex: 20,
                      strokeColor: "transparent",
                      strokeWeight: 72,
                      map: mapRef.current,
                    }}
                  />
                }
              />,
            ]
          })}
        {loaded && (
          <Polyline
            key={`playback_line`}
            path={entireRoutePath}
            // onLoad={animateArrow}
            options={{
              strokeColor: "transparent",
              strokeOpacity: 1,
              strokeWeight: 2,
              zIndex: 40,
              map: mapRef.current,
              icons: arrowMap,
            }}
          />
        )}
        {loaded && toggledTooltip && (
          <span lat={toggledTooltip.latitude} lng={toggledTooltip.longitude}>
            <Tooltip />
          </span>
        )}
      </GoogleMap>
      {loaded && !loadingRenderCriticalData && mappedRoutes.length > 0 && (
        <PlaybackPanel
          tw="print:hidden absolute top-4 left-4 lg:(bottom-8 top-auto)"
          onPlay={onPlaybackPlayHandler}
          onPause={onPlaybackPauseHandler}
          onStop={onPlaybackStopHandler}
          playing={playRoute}
          paused={pausePlayback}
        />
      )}
      <ZonesPanel
        toggled={zoneSettingsToggled}
        onToggleZonesOverlay={onToggleZonesOverlay}
      />
      <ZonesIconToggle
        toggled={zoneSettingsToggled}
        onClick={onToggleZoneSettings}
      />
    </>
  )
}
