import React, { useState, useContext } from "react"
import "twin.macro"
import styled from "styled-components"
import CrudList from "app/CrudList"
import Icon from "app/Icon"
import { Card, Button, ButtonGroup, Tag } from "@clevertrack/shared"
import { useAlarms } from "../hooks"
import { Alarm } from "../alarm.types"
import { useTranslation } from "react-i18next"

const StyledTags = styled.div`
  display: flex;
  position: absolute;
  top: 0;
`

const StyledCard = styled(Card)`
  position: relative;
  ${(props) => props.theme.media.tablet_landscape_up`
    transition: all .2s ease-out;

    .edit-mode & {
      opacity: 0.5;

      &.selected {
        opacity: 1;
        z-index: 100;
      }
    }
  `}
`

export const AlarmSelector: React.FC<{
  onNewAlarm: () => void
  onEditAlarm: (alarm: Alarm) => void
  onDelete: (alarmID: string) => void
  onCancel?: () => void
}> = ({ onNewAlarm, onEditAlarm, onDelete, onCancel, ...props }) => {
  const { alarms, editAlarm } = useAlarms()
  const [confirmDelete, setConfirmDelete] = useState<null | string>(null)
  const { t } = useTranslation()

  async function onDeleteAlarm(id) {
    // await deleteAlarm(id)
    setConfirmDelete(null)
    if (onDelete) onDelete(id)
  }

  function onDeleteHandler(id?: string) {
    if (id) {
      setConfirmDelete(id)
    } else setConfirmDelete(null)
  }

  return (
    <>
      <header tw="hidden md:block">
        <h3>{t("alarms.title")}</h3>
        <Button
          icon="right"
          variant="primary"
          size="sm"
          onClick={onNewAlarm}
          disabled={editAlarm && !editAlarm?.updated}
        >
          <span>{t("alarms.new")}</span>
          <Icon size="sm" icon="notification_add" />
        </Button>
      </header>
      <CrudList {...props}>
        {alarms &&
          alarms
            .sort((a, b) => (a?.title > b?.title ? 1 : -1))
            .map((alarm) => {
              const { id, title, description, disabled } = alarm
              return (
                <li key={id} className={`${editAlarm ? "edit-mode" : ""}`}>
                  <StyledCard
                    size="sm"
                    className={`${
                      editAlarm && id === editAlarm?.id ? "selected" : ""
                    }`}
                  >
                    <div>
                      <h4>{t(title)}</h4>
                      {description !== "" && <small>{t(description!)}</small>}
                      <StyledTags>
                        {disabled === false ? null : (
                          <Tag
                            title={t("common.inactive")}
                            variant="danger"
                            show
                          />
                        )}
                        {!alarm.updated && (
                          <Tag title={t("common.draft")} show />
                        )}
                      </StyledTags>
                    </div>
                    <div className="actions">
                      {confirmDelete === id ? (
                        <>
                          <Button
                            invert
                            variant="default"
                            size="sm"
                            onClick={() => onDeleteHandler()}
                          >
                            {t("common.cancel")}
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => onDeleteAlarm(alarm)}
                          >
                            {t("common.confirm")}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            invert
                            variant="danger"
                            size="sm"
                            onClick={() => onDeleteHandler(id)}
                          >
                            {t("common.delete")}
                          </Button>
                          {editAlarm && id === editAlarm.id ? (
                            <Button
                              variant="default"
                              size="sm"
                              onClick={() => onCancel()}
                            >
                              {t("common.cancel")}
                            </Button>
                          ) : (
                            <Button
                              variant="default"
                              size="sm"
                              onClick={() => onEditAlarm(alarm)}
                            >
                              {t("common.edit")}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </StyledCard>
                </li>
              )
            })}
      </CrudList>
      <ButtonGroup tw="mt-auto md:hidden px-8 bg-white" sticky="bottom">
        <Button icon="right" variant="primary" size="md" onClick={onNewAlarm}>
          <span>{t("alarms.new")}</span>
          <Icon size="sm" icon="plus" />
        </Button>
      </ButtonGroup>
    </>
  )
}
