import React from "react"
import "twin.macro"
import Search, { ISearchProps } from "app/Search"
import { freetextSearch } from "app/Search/helper"
import useConversationStore from "../store"

export const ConversationSearch: React.FC<Partial<ISearchProps>> = ({
  placeholder,
  wrapperProps,
  ...props
}) => {
  const { conversations } = useConversationStore((state) => ({
    conversations: state.conversations,
  }))

  const dataset = freetextSearch(conversations, {
    threshold: 0.05,
    distance: 20,
    ignoreLocation: true,
    includeScore: true,
    keys: [
      "messages.message",
      "contact.first_name",
      "contact.last_name",
      "contact.email",
      "contact.msisdn",
    ],
  })

  return (
    <div tw="px-4 bg-white" {...wrapperProps}>
      <Search placeholder={placeholder} dataset={dataset} {...props} />
    </div>
  )
}
