import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import styled from "styled-components"
import { Badge } from "lib/Badge"
import Checkbox from "lib/Checkbox"
import { UserVisibilitySettings } from "app/User/types"
import { useUser } from "app/User/hooks"

interface ZonesToggleProps {
  visibleCount: number
  totalCount: number
  onToggleVisibility: () => void
}

const PanelHeader = styled.div`
  ${tw`px-4 border-b border-t-0 border-l-0 border-r-0 mb-4 border-gray-200 border-solid flex items-center justify-between h-20`}
`

const SubHeader = styled.div`
  ${tw`flex items-center h-full`}
`

const Title = styled.h4`
  ${tw`text-2xl font-semibold`}
`

const StyledBadge = styled(Badge)`
  ${tw`ml-4 tracking-wide`}
`

export const ZonesToggle: FC<ZonesToggleProps> = ({
  visibleCount,
  totalCount,
  onToggleVisibility,
}) => {
  const { t } = useTranslation()
  const { getUserVisibilitySetting } = useUser()

  return (
    <PanelHeader>
      <SubHeader>
        <Title>{t("zones.show")}</Title>
        <StyledBadge size="md" variant="light" rounded>
          {visibleCount}/{totalCount}
        </StyledBadge>
      </SubHeader>
      <Checkbox
        onChange={onToggleVisibility}
        appearance="toggle"
        checked={getUserVisibilitySetting(UserVisibilitySettings.MapZones)}
      />
    </PanelHeader>
  )
}
