import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { parseISO, isToday } from "date-fns"
import { formatDate } from "utils/datetime"
import { SettingsContext } from "routes/Settings/context"
import { useTranslation } from "react-i18next"

const StyledDate = styled.span``

function DateComponent({ date, format, altPrefixIfToday, ...props }) {
  const {
    state: { lang },
  } = useContext(SettingsContext)

  const { t } = useTranslation()

  const localDate = parseISO(date)
  const jsDate = new Date(localDate)
  const lastChanged = formatDate(jsDate, lang, format)

  if (isToday(jsDate))
    return (
      <StyledDate>
        {altPrefixIfToday || t("common.today")}{" "}
        {formatDate(jsDate, lang, "HH:mm")}
      </StyledDate>
    )

  return <StyledDate {...props}>{lastChanged}</StyledDate>
}

export default DateComponent

DateComponent.defaultProps = {
  format: null,
}
DateComponent.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
}
