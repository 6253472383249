import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Form, FormField, Input, ValidationInput } from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"
import { useTranslation } from "react-i18next"

const StyledUserForm = styled(Form)`
  padding-top: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 0;
  `}
`

function UserForm({ onFieldChange, user, title, header, children, ...props }) {
  const { t } = useTranslation()
  const defaultValidationMessages = getDefaultValidationMessagesByLang(
    user.language
  )
  const validationMessages = {
    ...defaultValidationMessages,
    customError: t("error.non_valid_email"),
  }

  return (
    <StyledUserForm {...props}>
      {title && <h1>{title}</h1>}
      {header && <div>{header}</div>}
      <div>
        <FormField label={t("common.first_name")}>
          <Input
            placeholder={t("user.form_first_name_placeholder")}
            value={user.firstName}
            tabIndex={0}
            onChange={(e) => onFieldChange(e, "firstName")}
          />
        </FormField>
        <FormField label={t("common.last_name")}>
          <Input
            placeholder={t("user.form_last_name_placeholder")}
            value={user.lastName}
            tabIndex={0}
            onChange={(e) => onFieldChange(e, "lastName")}
          />
        </FormField>
        <FormField validationKey="email" label={t("common.email")}>
          <ValidationInput
            id="email"
            type="email"
            required
            placeholder={t("user.form_email_placeholder")}
            value={user.email}
            onChange={(e) => onFieldChange(e, "email")}
            customValidation={emailValidation}
            tabIndex={0}
            validationMessages={validationMessages}
          />
        </FormField>
        <FormField validationKey="phoneNumber" label={t("common.phone")}>
          <Input
            id="phoneNumber"
            type="text"
            placeholder={t("user.form_phone_placeholder")}
            value={user.phoneNumber}
            tabIndex={0}
            onChange={(e) => onFieldChange(e, "phoneNumber")}
          />
        </FormField>
      </div>
      {children}
    </StyledUserForm>
  )
}

export default UserForm

UserForm.defaultProps = {}

UserForm.propTypes = {
  children: PropTypes.node.isRequired,
}
