import React, { useState, useMemo } from "react"
import "twin.macro"
import {
  DeviceHealthCriterium,
  DeviceSpeedCriterium,
} from "routes/Alarms/alarm.types"

import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import Checkbox from "lib/Checkbox"
import Accordion from "lib/Accordion"
import Icon from "app/Icon"
import { IconSizeEnum } from "lib/Icon"
import { FormField, Input } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"

interface IDeviceHealthCriteriaProps {
  criterium: DeviceHealthCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceHealthCriteriaElement extends IDeviceHealthCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

export const DeviceHealthCriteriaElement: React.FC<IDeviceHealthCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  const { t } = useTranslation()

  const description = useMemo(() => {
    const parts: string[] = []
    if (criterium.connectionLost) parts.push(t("alarms.device_health_gps_lost"))
    if (criterium.powerLost) parts.push(t("alarms.device_health_power_lost"))
    if (criterium.powerTreshold) {
      parts.length === 2
        ? parts.push(
            `${t("common.or_decapitalize")} ${t(
              "alarms.device_health_voltage_under_decapitalize"
            )} ${criterium.powerTreshold} ${t("common.volt_decapitalize")}`
          )
        : parts.push(
            `${t("alarms.device_health_voltage_under_decapitalize")} ${
              criterium.powerTreshold
            } ${t("common.volt_decapitalize")}`
          )
    }

    return `${t("alarms.device_health_fulfilled_when")}${": "} ${
      parts.length === 2
        ? parts.join(` ${t("common.or_decapitalize")} `)
        : parts.join(", ")
    }`
  }, [criterium, t])

  return (
    <AlarmConfigurationComponent tw="border-b-0">
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">{t("alarms.device_health_title")}</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">{t("common.cancel")}</span>
            </>
          ) : (
            <>
              <span tw="text-lg">{t("common.edit")}</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">{description}</span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceHealthCriteria: React.FC<IDeviceHealthCriteriaProps> = ({
  criterium,
  onSave,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation()
  const [showTreshold, setShowTreshold] = useState<boolean>(
    !!criterium.powerTreshold
  )
  const [connectionLost, setConnectionLost] = useState<boolean>(
    criterium.connectionLost
  )
  const [powerLost, setPowerLost] = useState<boolean>(criterium.powerLost)
  const [powerTreshold, setPowerTreshold] = useState<number | null>(
    criterium.powerTreshold ?? 0
  )
  const [powerTresholdUnder, setPowerTresholdUnder] = useState<boolean>(
    criterium.powerTresholdUnder
  )
  const onSaveHandler = () => {
    if (onSave)
      onSave({
        ...criterium,
        connectionLost,
        powerLost,
        powerTreshold,
        powerTresholdUnder,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>{t("alarms.device_health_title")}</CriteriumTypeTitle>
      <p tw="text-xl mt-0 text-brand-gray-base">
        {t("alarms.device_health_description")}
      </p>
      <div tw="mt-8">
        <CriteriumTypeTitle tw="mb-4 block">
          {t("alarms.device_health_trigger_when")}
        </CriteriumTypeTitle>
        <div tw="text-xl">
          <Checkbox
            checked={connectionLost}
            onChange={(checked) => setConnectionLost(checked)}
          >
            {t("alarms.device_health_gps_lost")}
          </Checkbox>
          <Checkbox
            checked={powerLost}
            onChange={(checked) => setPowerLost(checked)}
          >
            {t("alarms.device_health_power_lost")}
          </Checkbox>
        </div>
      </div>
      <div tw="mt-12">
        <CriteriumTypeTitle tw="block">
          {t("alarms.device_health_voltage_title")}
        </CriteriumTypeTitle>
        <p tw="text-xl mt-0 max-w-3xl">
          {t("alarms.device_health_voltage_description")}
        </p>

        {!showTreshold && (
          <span
            tw="flex mt-2 items-center text-brand-500 cursor-pointer cursor-pointer hover:opacity-60 transition"
            onClick={() => {
              setPowerTreshold(11.0)
              setShowTreshold(true)
            }}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b ">
              {t("alarms.device_health_setup_voltage")}
            </span>
            <Icon size={IconSizeEnum.SM} icon="plus" />
          </span>
        )}
        <Accordion toggled={!!showTreshold}>
          <div tw="pt-4">
            <FormField label={t("alarms.device_health_voltage_label")}>
              <Input
                type="number"
                step=".01"
                placeholder={t("alarms.device_health_voltage_placeholder")}
                value={powerTreshold}
                onChange={(e) => setPowerTreshold(e.target.value)}
              />
            </FormField>
            <span
              tw="flex items-center text-brand-red-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
              onClick={() => {
                setPowerTreshold(null)
                setShowTreshold(false)
                setPowerTresholdUnder(true)
              }}
            >
              <span tw="mr-2 text-xl border-dotted border-0 border-b">
                {t("alarms.device_health_remove_voltage")}
              </span>
              <Icon size={IconSizeEnum.SM} icon="close" />
            </span>
          </div>
        </Accordion>
      </div>
      {onSave && onCancel && (
        <EditorButtonGroup onSave={onSaveHandler} onCancel={onCancelHandler} />
      )}
    </>
  )
}
