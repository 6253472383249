import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import "twin.macro"
import { Button, ButtonGroup, Card } from "@clevertrack/shared"
import PasswordForm from "app/PasswordForm"
import { checkPasswordToken, setPasswordWithToken } from "services/public"
import { queryResolver } from "utils/queryResolver"
import cogoToast from "@clevertrackdk/cogo-toast"
import { SEO } from "components/SEO"
import SignedOutWrapper from "app/SignedOutWrapper"
import tw from "twin.macro"
import { useTranslation } from "react-i18next"
const StyledNewPassword = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.media.tablet_portrait_up`
    margin: 2rem auto 0;
    max-width: 60vw;
  `}

  ${(props) => props.theme.media.tablet_portrait_up`
    max-width: 60rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 75%;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 55%;
  `}
`

const StyledCard = styled(Card)`
  margin-top: 2rem;
  min-width: 30rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    min-width: 50rem;
  `}
`

const StyledSysMsg = styled.small`
  color: ${(props) => props.theme.colors[props.color]};
  ${tw`text-center mt-4 block`}
`

function NewPassword({ ...props }) {
  const [sysMsg, setSysMsg] = useState<string | null>(null)
  const [redirectCountdown, setRedirectCountdown] = useState(3)
  const [tokenValid, setTokenValidity] = useState(null)

  const { t } = useTranslation()

  const { token } = queryResolver(window.location.href)

  useEffect(() => {
    const IIFE = (async () => {
      try {
        const result = await checkPasswordToken(token)
        setTokenValidity({ validity: result })
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  const submitHandler = async (pass, e) => {
    e.preventDefault()
    try {
      const response = await setPasswordWithToken(token, pass)
      if (response.result === "OK") {
        cogoToast.loading(t("reset_passwordnew_password.loading"))
        setTimeout(() => {
          cogoToast.success(t("reset_password.new_password.success"))
          setTimeout(() => {
            setSysMsg(
              `${t(
                "reset_password.new_password.success_redirect"
              )} ${" "} ${redirectCountdown}`
            )
            setTimeout(() => setRedirectCountdown(2), 1000)
            setTimeout(() => setRedirectCountdown(1), 2000)
            setTimeout(() => {
              navigate("/app")
            }, 3000)
          }, 1500)
        }, 3250)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (redirectCountdown !== 3) {
      setSysMsg(
        `${t(
          "reset_password.new_password.success_redirect"
        )} ${" "} ${redirectCountdown}`
      )
    }
  }, [redirectCountdown])

  const renderForm = () => {
    if (!!tokenValid) {
      if (tokenValid.validity) {
        return (
          <>
            <h2 tw="text-center">{t("reset_password.new_password.title")}</h2>
            <PasswordForm onSubmit={submitHandler}>
              {sysMsg ? (
                <StyledSysMsg color="success"> {sysMsg} </StyledSysMsg>
              ) : (
                <ButtonGroup position="center" tw="mx-0">
                  <Button type="submit" variant="primary" tw="mx-0">
                    {t("reset_password.new_password.confirm")}
                  </Button>
                </ButtonGroup>
              )}
            </PasswordForm>
          </>
        )
      } else {
        return (
          <>
            <h2 tw="text-center">{t("new_password_expired_title")}</h2>
            <p tw="text-center">
              {t("reset_password.new_password.expired_description")}
            </p>
            <ButtonGroup position="between" tw="px-0">
              <Button
                type="button"
                variant="cancel"
                onClick={() => navigate("/")}
              >
                {t("reset_password.new_password.login")}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate("/app/reset-password")}
              >
                {t("reset_password.new_password.get_new_link")}
              </Button>
            </ButtonGroup>
          </>
        )
      }
    } else {
      return <p>{t("reset_password.new_password.wait")}</p>
    }
  }

  return (
    <SignedOutWrapper>
      <SEO
        title={t("reset_password.new_password.title")}
        slug="/app/new-password"
      />
      <StyledNewPassword>{renderForm()}</StyledNewPassword>
    </SignedOutWrapper>
  )
}

export default NewPassword

NewPassword.defaultProps = {}

NewPassword.propTypes = {}
