import React, { useEffect, useMemo, useState } from "react"
import { Icon } from "@clevertrack/shared"
import styled from "styled-components"
import tw from "twin.macro"

import DateComponent from "app/Date"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import { IconSizeEnum } from "lib/Icon"
import { useHistory } from "routes/HistoryV2/hooks"
import { getDisplayKey } from "../helper"
import { DeviceStatusEnum, IDevice } from "../types"
import { formatSecondsToDuration } from "utils/datetime"
import { useTranslation } from "react-i18next"

const StyledKeyInformation = styled.div`
  padding: 1rem 1rem 0 1.5rem;
  display: flex;
  flex-direction: column;

  > span {
    display: grid;
    grid-template-columns: 3rem auto auto;
    ${tw`text-xl py-1`}

    > span {
      ${tw`opacity-60`}
    }

    > svg {
      opacity: 80%;
      align-self: center;
      justify-self: center;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 1rem 1rem 0;
  `}
`

type KeyInformationProps = {
  device: IDevice
}

export const KeyInformation: React.FC<KeyInformationProps> = ({
  device,
  ...props
}) => {
  const { t } = useTranslation()
  const { getFirstLogOfCurrentTrip } = useHistory()
  const [firstTripState, setFirstTripState] = useState<IDevice | null>(null)
  const address = getDisplayKey(device.values, DisplayKeyEnum.Address)
  const lastUpdated = getDisplayKey(
    device.values,
    DisplayKeyEnum.LastConfirmationTime
  )
  const fuelLevel = getDisplayKey(device.values, DisplayKeyEnum.FuelLevel)
  /* const lastStop = useMemo(
    () => getDisplayKey(device.values, DisplayKeyEnum.LastIgnitionStop),
    [device]
  ) */
  const formatStr = `dd.MM.yyyy '${t("common.at")}' HH:mm`
  const speed = getDisplayKey(device.values, DisplayKeyEnum.Speed)

  /* const deviceIsStoppedOrUnknown = useMemo(
    () =>
      [DeviceStatusEnum.UNKNOWN, DeviceStatusEnum.STOPPED].includes(
        device.status
      ),
    [device]
  ) */

  useEffect(() => {
    const fetchFirstTripLog = async () => {
      const d = await getFirstLogOfCurrentTrip(device.id)
      if (d) setFirstTripState(Object.entries(d)[0][1] as IDevice)
    }
    if (device?.id) {
      fetchFirstTripLog()
    }
  }, [device])

  /*   const durationSinceStartOrStopped = useMemo(
    () =>
      (firstTripState || deviceIsStoppedOrUnknown) && lastStop.value
        ? formatSecondsToDuration(
            (+new Date() -
              +new Date(
                deviceIsStoppedOrUnknown
                  ? lastStop.value
                  : firstTripState?.timestamp
              )) /
              1000,
            { shortUnits: true }
          )
        : null,
    [firstTripState, lastStop, deviceIsStoppedOrUnknown]
  ) */

  return (
    <StyledKeyInformation>
      <span>
        {/* Current address */}
        <Icon icon="map-marker-alt" tw="w-7 h-7" />
        <span>{address.value || "Opdaterer adresse"}</span>
        {address.value && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${address.value}`}
            target="_blank"
            rel="noopener,noreferrer"
            tw="flex items-center ml-auto mr-2 md:hidden text-brand-500 border border-0 border-dotted border-b whitespace-nowrap mb-auto text-right opacity-100"
          >
            <span>Find vej </span>
            <span tw="h-4 w-4 -mt-3 ml-2">
              <Icon icon="chevron-right" size={IconSizeEnum.SM} />
            </span>
          </a>
        )}
      </span>
      {lastUpdated?.value && (
        <span>
          {/* Last updated */}
          <Icon icon="signal-gsm" tw="w-5 h-5" />
          <span>
            {`${t("device.key_information_last_updated")} `}
            <DateComponent
              date={lastUpdated.value}
              format={formatStr}
              altPrefixIfToday={`${t("common.today").toLocaleLowerCase()} ${t(
                "common.at"
              )} `}
            />
          </span>
        </span>
      )}
      {speed?.value && speed.value > 0 && (
        <span>
          {/* Current speed */}
          <Icon icon="speed" tw="w-5 h-5" />
          <span>{`${t("device.key_information_current_speed")} ${
            speed.value
          } ${t("common.kmh")}`}</span>
        </span>
      )}
      {fuelLevel?.value && (
        <span>
          {/* Current fuel level */}
          <Icon icon="fuel" tw="w-5 h-5" />
          <span>{`${t("device.key_information_fuel_level")} ${
            fuelLevel.value
          }%`}</span>
        </span>
      )}
      {/* {durationSinceStartOrStopped && (
        <span>
          {/* Duration of current trip
          <Icon icon="timer" size={IconSizeEnum.MD} />
          <span>{`${
            deviceIsStoppedOrUnknown ? "Stoppet" : "Startet"
          } for ${durationSinceStartOrStopped} siden`}</span>
        </span>
      )}

      <span>
        {/* Working
        <Icon icon="hammer-solid" size={IconSizeEnum.MD} />
      </span>
      <span>
        {/* Idle
        <Icon icon="slow-mo-video" size={IconSizeEnum.MD} />
      </span>
      <span>
        {/* Driving
        <Icon icon="play" size={IconSizeEnum.MD} />
      </span>
      <span>
        {/* Moved
        <Icon icon="dolly-solid" size={IconSizeEnum.MD} />
      </span> */}
    </StyledKeyInformation>
  )
}
