import { useContext } from "react"
import {
  doc,
  onSnapshot,
  collection,
  getDocs,
  query,
  orderBy,
  Unsubscribe,
  QuerySnapshot,
  DocumentData,
  QueryDocumentSnapshot,
  CollectionReference,
  where,
} from "firebase/firestore"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  IContact,
  IContactGroup,
  IContactGroupResponse,
  IMessage,
  IResolvedConversation,
} from "app/Conversations/conversations.types"
import { CompanyContext } from "app/Company/context"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import _orderBy from "lodash-es/orderBy"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { UserContext } from "app/User/context"
import subDays from "date-fns/subDays"
import set from "date-fns/set"
import useConversationStore from "app/Conversations/store"

export const useFirestoreConversation = () => {
  const { db } = useContext(FirebaseContext)
  const {
    state: { company },
  } = useContext(CompanyContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const { dispatch, subscriptionActive } = useConversationStore((state) => ({
    dispatch: state.dispatch,
    subscriptionActive: state.subscriptionActive,
  }))

  const { getCompanyConversations } = useFirebaseFunctions()

  const contactsSubscriptions: Unsubscribe[] = []
  const subscribeToContacts = async (): Promise<IContact[]> => {
    try {
      const c = doc(db, "companies", company.id.toString())
      const contactsCollection = collection(
        db,
        c.path,
        "contacts"
      ) as CollectionReference<IContact>
      const contacts = await getDocs(contactsCollection)

      const resolveContacts = (
        docs: QueryDocumentSnapshot<IContact>[]
      ): IContact[] => {
        return docs.map(
          (d): IContact => {
            const contactData = d.data()
            /* if (contactData.new_received_messages === true) {
              // This is only being set, when we receive messages.
              // Thus we can ensure we subscribe to conversations with contacts that initiate conversation by wrting our number.
              console.log("Subscribing to contact", contactData.first_name)
              subscribeToUserConversations(d.id)
            } */
            return {
              ...contactData,
              id: d.id,
              full_name: `${contactData.first_name} ${contactData.last_name}`,
            }
          }
        )
      }

      const updateContacts = (updatedContacts) => {
        // Update address book
        dispatch(
          ConversationActions(ConversationTypes.SetContacts, {
            contacts: updatedContacts,
          })
        )
      }

      const initialContacts = resolveContacts(contacts.docs)
      updateContacts(initialContacts)

      // Set up a subscription
      const onNext = (snapshot) => {
        const updatedContacts = resolveContacts(snapshot.docs)
        updateContacts(updatedContacts)
      }

      contactsSubscriptions.push(onSnapshot(contactsCollection, onNext))

      return initialContacts
    } catch (error) {
      throw new Error(error)
    }
  }

  // Stub
  const conversationsSubscriptions: Unsubscribe[] = []
  let contactIDsWithConversations: string[] = []

  const subscribeToUserConversations = async (
    id?: string
  ): Promise<string[] | null> => {
    try {
      const resolveConversation = async (
        docs,
        conversation: IResolvedConversation
      ): Promise<IResolvedConversation | null> => {
        const messages = docs.map((message) => message.data())
        const messagesSorted: IMessage[] = _orderBy(messages, "time", "desc")

        return messagesSorted && messagesSorted[0]
          ? {
            ...conversation,
            last_message_timestamp: messagesSorted[0].time,
            messages: messagesSorted,
          }
          : null
      }

      const onNext = async (
        snapshot: QuerySnapshot<DocumentData>,
        conversation: IResolvedConversation
      ) => {
        const updatedConversation = await resolveConversation(
          snapshot.docs,
          conversation
        )

        if (updatedConversation && !id) {
          updateConversationByID(updatedConversation)
        }
      }

      const onError = (err) => { }

      const updateConversations = (updatedConversations) => {
        dispatch(
          ConversationActions(ConversationTypes.SetConversations, {
            conversations: updatedConversations.filter(Boolean),
          })
        )
        dispatch(
          ConversationActions(ConversationTypes.SetLoading, { loading: false })
        )
      }

      const updateConversationByID = (updatedConversation) => {
        dispatch(
          ConversationActions(ConversationTypes.UpdateConversationByID, {
            conversation: updatedConversation,
            userID: user?.id.toString(),
          })
        )
      }

      if (db && company?.id && user?.id && !subscriptionActive) {
        // const companyDoc = doc(db, "companies", company.id.toString())

        const contactsRes = await getCompanyConversations({
          companyID: company.id.toString(),
          userID: user.id.toString(),
        })

        const initialConversations: IResolvedConversation[] =
          contactsRes.data ?? []
        const contactIDs = contactsRes.data.map((x) => x.id)

        const dataRetentionLimit = +set(subDays(new Date(), 90), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        })

        if (contactsRes.data) {
          for (const contact of contactsRes.data) {
            const conversationRef = query(
              collection(
                db,
                `companies/${company.id.toString()}/contacts/${contact.id
                }/conversation`
              ),
              where("time", ">", dataRetentionLimit),
              orderBy("time", "desc")
            )

            conversationsSubscriptions.push(
              onSnapshot(
                conversationRef,
                (snapshot) => onNext(snapshot, contact),
                onError
              )
            )
          }

          if (!id) {
            updateConversations(
              initialConversations.filter((x) => !!x.messages)
            )
          }
        }

        return contactIDs
      }

      return null
    } catch (error) {
      console.log(error)
    }
  }

  const contactGroupSubscriptions: Unsubscribe[] = []
  const subscribeToContactGroups = async (
    contacts: IContact[]
  ): Promise<IContactGroup[]> => {
    try {
      const c = doc(db, "companies", company.id.toString())
      const contactGroupsCollection = collection(db, c.path, "contactgroups")
      const contactGroups = await getDocs(contactGroupsCollection)

      const updateContactGroups = (updatedGroups) => {
        dispatch(
          ConversationActions(ConversationTypes.SetGroups, {
            groups: updatedGroups,
          })
        )
      }

      const resolveContactGroups = (
        docs: QueryDocumentSnapshot<IContactGroupResponse>[]
      ): IContactGroup[] => {
        const mappedGroups = docs.map((group) => {
          const groupData = group.data()
          return {
            ...groupData,
            contact_ids: groupData.contact_ids.reduce(
              (results: IContact[], x) => {
                const groupContact = contacts.find(
                  (contact) => contact.id === x
                )
                if (groupContact) results.push(groupContact)
                return results
              },
              []
            ),
          }
        })
        return mappedGroups
      }

      // Set up a subscription
      const onNext = (snapshot: QuerySnapshot<DocumentData>) => {
        const updatedContacts = resolveContactGroups(
          snapshot.docs as QueryDocumentSnapshot<IContactGroupResponse>[]
        )
        updateContactGroups(updatedContacts)
      }

      contactGroupSubscriptions.push(
        onSnapshot(contactGroupsCollection, onNext)
      )

      const initialContactGroups = resolveContactGroups(
        contactGroups.docs as QueryDocumentSnapshot<IContactGroupResponse>[]
      )
      updateContactGroups(initialContactGroups)
      return initialContactGroups
    } catch (error) {
      console.log(error)
    }
  }

  const unsubscribeToUserConversations = () => {
    for (const unsubcribe of conversationsSubscriptions) {
      unsubcribe()
    }
  }

  const unsubscribeToContacts = () => {
    for (const unsubcribe of contactsSubscriptions) {
      unsubcribe()
    }
  }

  const unsubscribeToGroups = () => {
    for (const unsubcribe of contactGroupSubscriptions) {
      unsubcribe()
    }
  }

  const getCompanyUnreadConversationsSubscription = (
    onNext,
    onError,
    companyID
  ) => {
    const c = doc(db, "companies", companyID.toString())
    return onSnapshot(c, onNext, onError)
  }

  const getConversationSubscription = (onNext, onError, contactID) => {
    const companyRef = doc(db, "companies", company.id.toString())
    const convo = query(
      collection(companyRef, `contacts/${contactID}/conversation`)
    )
    return onSnapshot(convo, onNext, onError)
  }

  return {
    subscribeToContactGroups,
    subscribeToUserConversations,
    unsubscribeToUserConversations,
    unsubscribeToContacts,
    unsubscribeToGroups,
    subscribeToContacts,
    getCompanyUnreadConversationsSubscription,
    getConversationSubscription,
  }
}
