import { formatDistanceToNowStrict, parseJSON } from "date-fns"
import { da, enGB } from "date-fns/locale"
import { formatFloat } from "utils"
import { getTranslationText } from "utils/i18n";

interface TimeLeftConfig {
  formatLang?: string;
  monthsUnit?: string;
  timeLeftText?: string;
  exceededText?: string;
}

const DEFAULT_CONFIG: TimeLeftConfig = {
  formatLang: "da-DK",
  monthsUnit: "mdr",
  timeLeftText: "tilbage",
  exceededText: "overskredet"
}

const serviceTimeLeft = (date: Date, ServiceIndex: number, config: TimeLeftConfig = {}) => {
  const { timeLeftText, exceededText, formatLang } = { ...DEFAULT_CONFIG, ...config }

  const distance = formatDistanceToNowStrict(date, {
    locale: formatLang === "da" ? da : enGB,
  })
  return ServiceIndex < 100
    ? `${distance} ${timeLeftText}`
    : `${distance} ${exceededText}`
}

export const hoverContentByUnit = (unit: string, data: any, config: TimeLeftConfig = {}) => {
  const { formatLang } = { ...DEFAULT_CONFIG, ...config }
  const { currentValue, endValue, endDate, index } = data

  return unit !== "mdr"
    ? `${formatFloat(Math.floor(currentValue), formatLang)}
    /
    ${formatFloat(Math.floor(endValue), formatLang)} ${getTranslationText(`global_${unit}`, formatLang)}`
    : serviceTimeLeft(parseJSON(endDate), index, config)
}
