import React, { useState } from "react"
import tw from "twin.macro"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { IconFlip } from "app/IconFlip"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { Input, Loading, ButtonGroup, Button } from "@clevertrack/shared"
import { baseHintStyle, hintTheme } from "app/Hint"
import { useTranslation } from "react-i18next"
type CSVImportProps = {
  templateFilename?: string // Place in static folder on the /import-templates-path
  onAccept?: <T>(data: T) => void
  onCancel?: () => void
  onImport?: () => void
  onValidateEntry?: (entry) => boolean
  acceptResult: React.ReactElement
  cancelResult: React.ReactElement
}

const StyledDropZone = styled.div<{ dragActive: boolean }>`
  ${tw`relative h-64 p-8 flex flex-col items-center justify-center border border-dashed border-brand-gray-base transition`}
  ${(props) => (props.dragActive ? tw`border-solid` : ``)}
  .inner {
  }

  svg {
    max-height: none;
    height: 4rem;
    width: 4rem;
    margin-right: 0;
  }
`

const StyledResults = styled.section``

type UploadResultMeta = {
  aborted: boolean
  cursor: number
  delimiter: string
  fields: string[]
  linebreak: string
  truncated: boolean
}

type UploadResult = {
  data: any[]
  errors: any[]
  meta: UploadResultMeta
}

export type CSVImportResponseType = {
  data: UploadResult
}

export const CSVImport: React.FC<CSVImportProps> = ({
  onImport,
  onAccept,
  onCancel,
  onValidateEntry,
  acceptResult,
  cancelResult,
  ...props
}) => {
  const { t } = useTranslation()
  const { parseCSVFile } = useFirebaseFunctions()
  const [uploadData, setUploadData] = useState<any[]>([])
  const [uploadDataKeys, setUploadDataKeys] = useState<string[]>([])
  const [fileTypeError, setFileTypeError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<boolean>(false)
  const [duplicates, setDuplicates] = useState<any[]>([])
  const onDrop = (acceptedFiles) => {
    setLoading(true)
    const file = acceptedFiles[0]

    if (!file.name.endsWith(".csv")) {
      setLoading(false)
      setFileTypeError(true)
      return
    }

    const reader = new FileReader()

    reader.onabort = () => console.log("file reading was aborted")
    reader.onerror = () => console.log("file reading has failed")
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      if (typeof binaryStr === "string") {
        parseCSVFile({ fileData: binaryStr })
          .then((res) => {
            if (res.data) {
              const duplicatesFound = res.data.data.filter((x) =>
                onValidateEntry(x)
              )
              if (duplicatesFound.length > 0) setDuplicates(duplicatesFound)
              const keys = Object.keys(res.data.data[0])
              setUploadData(res.data.data.filter((x) => !onValidateEntry(x)))
              setUploadDataKeys(keys)
              if (onImport) onImport()
            }
          })
          .catch((e) => {
            setUploadError(true)
          })

        setLoading(false)
      }
    }
    reader.readAsText(file)
    // Do something with the files
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const renderDropZone = () => {
    return (
      <StyledDropZone
        {...getRootProps()}
        multiple={false}
        dragActive={isDragActive}
      >
        {loading ? (
          <Loading loadingText="Behandler fil" />
        ) : (
          <>
            <input {...getInputProps()} />
            <IconFlip
              tw="h-24 w-24"
              size={IconSizeEnum.XL}
              iconOff="file-csv"
              iconOn="upload"
              toggled={isDragActive}
            />
            <h4>{!isDragActive ? "Upload fil" : "Slip"}</h4>
          </>
        )}

        {fileTypeError ? (
          <span tw="text-xl text-brand-red-500 block mb-8">
            Ugyldig filtype (skal være .csv)
          </span>
        ) : null}
        {uploadError ? (
          <span tw="text-xl text-brand-red-500 block mb-8">
            Der skete en fejl under behandling af filen. Prøv igen.
          </span>
        ) : null}
      </StyledDropZone>
    )
  }

  const onAcceptHandler = () => {
    if (onAccept) onAccept(uploadData)
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  const onChangeFieldHandler = (e, index, key) => {
    const newItems = [...uploadData]
    newItems[index][key] = e.target.value
    setUploadData(newItems)
  }

  const onAddRowHandler = () => {
    const newObj = {}

    for (const k of uploadDataKeys) {
      newObj[k] = ""
    }

    setUploadData((prev) => [...prev, newObj])
  }

  const onAddDuplicatesHandler = () => {
    setUploadData((prev) => [...prev, ...duplicates])
    setDuplicates([])
  }

  const onClearDuplicatesHandler = () => {
    setDuplicates([])
  }

  const onDeleteRowHandler = (removeIndex) => {
    const newUploadData = [...uploadData].filter((x, i) => i !== removeIndex)
    setUploadData(newUploadData)
  }

  const renderResults = () => {
    return (
      <StyledResults>
        <header tw="mt-8 mb-2 text-xl pb-0 flex">
          {uploadDataKeys.map((key) => {
            return (
              <span key={key} tw="flex-1">
                {t(key)}
              </span>
            )
          })}
        </header>
        <div tw="my-0 text-2xl space-y-4">
          {uploadData.map((entry, i) => {
            return (
              <div tw="flex" key={`row_${i}`}>
                {uploadDataKeys.map((k) => {
                  return (
                    <Input
                      key={`${k}_${i}`}
                      type="text"
                      defaultValue={entry[k]}
                      onChange={(e) => onChangeFieldHandler(e, i, k)}
                      placeholder={t(k)}
                      tw="p-2 my-2 flex-1 mr-4 border border-brand-gray-light border-solid"
                    />
                  )
                })}
                <span
                  tw="flex items-center text-brand-red-500 cursor-pointer cursor-pointer hover:opacity-60 transition"
                  onClick={() => onDeleteRowHandler(i)}
                >
                  <Icon size={IconSizeEnum.SM} icon="close" />
                </span>
              </div>
            )
          })}
          <span
            tw="flex items-center text-brand-500 cursor-pointer cursor-pointer hover:opacity-60 transition"
            onClick={onAddRowHandler}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b ">
              Tilføj række
            </span>
            <Icon size={IconSizeEnum.SM} icon="plus" />
          </span>
        </div>
        <ButtonGroup sticky tw="mt-8 top-0 bottom-auto">
          <cancelResult.type
            key={cancelResult.key}
            {...cancelResult.props}
            onClick={onCancelHandler}
          />
          <acceptResult.type
            key={acceptResult.key}
            {...acceptResult.props}
            onClick={onAcceptHandler}
          />
        </ButtonGroup>
      </StyledResults>
    )
  }

  return (
    <>
      {duplicates.length > 0 && (
        <div
          tw="flex flex-col text-xl mb-8"
          css={[baseHintStyle, hintTheme.warning]}
        >
          <span tw="block mb-2 text-2xl font-bold">
            Duplikater identificeret
          </span>
          <span tw="block">
            Der var <span tw="font-bold">{duplicates.length}</span> duplikat(er)
            i det indlæste dokument.
          </span>
          <ButtonGroup position="right" tw="mt-4 ml-auto">
            <Button
              type="button"
              variant="transparent"
              onClick={onClearDuplicatesHandler}
              size="sm"
            >
              Kassér duplikater
            </Button>
            <Button
              type="button"
              variant="default"
              onClick={onAddDuplicatesHandler}
              size="sm"
            >
              Tilføj duplikater
            </Button>
          </ButtonGroup>
        </div>
      )}
      {uploadData?.length > 0 ? renderResults() : renderDropZone()}
    </>
  )
}
