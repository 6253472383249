export enum RouteTypeEnum {
  Conversations = "Conversations", // P2P Messages (sms)
  Contacts = "Contacts", // Phone Book
  Messages = "Messages", // System messages, alarms, notifications, warnings etc.
}

export enum PopOverRoutesEnum {
  CreateContact = "CreateContact",
  EditContact = "EditContact",
  CreateGroup = "CreateGroup",
  EditGroup = "EditGroup",
  NewMessage = "NewMessage",
  ViewConversation = "ViewConversation",
}

export type ConversationRoutes = RouteTypeEnum | PopOverRoutesEnum

export interface IContact {
  id?: string // Unique ID
  msisdn?: number
  phone: number | string
  area_code: number
  email: string
  first_name: string
  full_name?: string
  last_name?: string
  last_read_by?: LastReadByType
  new_received_messages?: boolean
}

export type LastReadByType = {
  [key: string]: number
}

export interface IContactGroupPayload {
  id: string
  title: string
  contact_ids: string[]
}

export interface IContactGroupResponse {
  id: string
  title: string
  contact_ids: string[]
}

export interface IContactGroup {
  id?: string
  title: string
  contact_ids: IContact[]
}

export interface IConversation {
  id: string
  group_id: string | null
  contact_id: string | null
  messages: IMessage[]
}

export interface IResolvedConversation {
  id: string
  contact: IContact
  last_message_timestamp: number
  messages: IMessage[]
  unread_count: number
  stale_unread_count?: boolean
}

export interface IContactOrContactGroup
  extends Partial<IContact & IContactGroup> { }

export enum MessageStatusEnum {
  UNKNOWN = "UNKNOWN", // Initial value for before processing begins, waiting for actual status.
  SCHEDULED = "SCHEDULED", // Used for messages where you set a sendtime in the future.
  BUFFERED = "BUFFERED", // The message is held in our internal queue and awaits delivery to the mobile network.
  ENROUTE = "ENROUTE", // Message has been sent to mobile network, and is on its way to its final destination.
  DELIVERED = "DELIVERED", // The end users mobile device has confirmed the delivery, and if message is charged the charge was successful.
  EXPIRED = "EXPIRED", // Message has exceeded its validity period without getting a delivery confirmation. No further delivery attempts.
  DELETED = "DELETED", // Message was canceled.
  UNDELIVERABLE = "UNDELIVERABLE", // Message is permanently undeliverable. Most likely an invalid MSISDN.
  ACCEPTED = "ACCEPTED", // The mobile network has accepted the message on the end users behalf.
  REJECTED = "REJECTED", // The mobile network has rejected the message. If this message was charged, the charge has failed.
  SKIPPED = "SKIPPED", // The message was accepted, but was deliberately ignored due to network-specific rules.
}

export interface IMessage {
  id?: number // The GatewayAPI message ID
  internal_id: string // Clevertracks own internal message ID to keep track of messages in frontend instantly.
  time: number
  message: string
  message_read: boolean
  status?: MessageStatusEnum
  error?: string
  code?: string
  country_code?: string
  country_prefix?: string
  sent?: boolean
  received?: boolean
  sent_to_group?: string
}

type Recipient = {
  msisdn: number
}

export interface INewMessagePayload {
  internalID: string
  companyID: string
  message: string
  recipients: IContact[]
  isGroupMessage?: boolean
  sent_to_group?: string
}

export interface IReadMessagePayload {
  companyID: string
  contactID: string
  userID: string
}

export interface IMessagePayload {
  message: string
  recipients: Recipient[]
}

/**
 * Status Callback from GatewayAPI
 */
export interface IMessageStatusCallback {
  id: number // The ID of the SMS the notification concerns
  msisdn: number // The MSISDN of the mobile recipient.
  time: number // The UNIX Timestamp for the delivery status event
  status: MessageStatusEnum // One of the states, in all-caps, ie. DELIVERED
  error?: string // Optional error description, if available.
  code?: string // Optional numeric code, in hex, see SMS Errors, if available. (see: https://gatewayapi.com/docs/error-codes/#sms-errors)
  userref?: string // If you specified a reference when sending the message, it is returned to you
  country_code?: string // Optional country code of the msisdn.
  country_prefix?: string // Optional country prefix of the msisdn.
}
