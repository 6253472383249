import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import isEqual from "lodash-es/isEqual"
import "twin.macro"
import GoogleMapReact, { fitBounds } from "google-map-react"
import { ViewportContext } from "context/ViewportContext"
import { MapContext } from "./context"
import { isViewport } from "helpers/viewport"
import {
  createMapOptions,
  getBounds,
  getSinglePoint,
  uxModFitBounds,
} from "./helper"
import { ZonesContext } from "routes/Zones/context"
import { setGoogleMapsInstance } from "./actions"
import tw from "twin.macro"
import { CompanyContext } from "app/Company/context"
import { UserContext } from "app/User/context"

const StyledGoogleMapsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;

  .gm-style {
    font: inherit;
  }

  .gm-style-mtc button {
    font: inherit !important;

    &[aria-checked="true"] {
      ${tw`!font-bold`}
    }
  }
`

function GoogleMap({
  maxZoom,
  geoPoints,
  onChange,
  onGoogleApiLoaded,
  omitOnChangeAfterUserInteraction,
  useRawFit,
  customFit,
  otherAnimationsEnded = true,
  forceAnimate,
  toggleUserInteractedOnEvents = ["dragstart", "mouseover"],
  untoggleUserInteractedOnEvents = ["mouseout"],
  children,
  ...props
}) {
  const userInteractedRef = useRef(false)
  const {
    state: { allowScroll },
    dispatch,
  } = useContext(MapContext)
  const vp = useContext(ViewportContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const lang = user?.language ? user.language.split("-")[0] : "da"
  const mapContainerRef = useRef(null)
  const {
    state: {
      companySettings: { map },
    },
  } = useContext(CompanyContext)

  const [defaultZoom, defaultCenter] = useMemo(() => {
    if (map && map.center)
      return [map.defaultZoom, { lat: map.center[0], lng: map.center[1] }]
    return [
      7,
      {
        lat: 56.0,
        lng: 9.85,
      },
    ]
  }, [map])

  const [zoomAndCenter, setZoomAndCenter] = useState({
    zoom: defaultZoom,
    center: defaultCenter,
  })

  const geoPointIDs = useRef(null)
  const googleMapsRef = useRef<{
    map: google.maps.Map
    maps: typeof google.maps
  } | null>(null)
  const mapOptions = useRef<google.maps.MapOptions | null>()
  const isPhoneOrTablet = isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])

  const {
    state: { displayZones },
  } = useContext(ZonesContext)

  function getZoomAndCenter() {
    if (userInteractedRef.current) return zoomAndCenter
    if (geoPoints.length > 0 && mapContainerRef.current) {
      // First, handle cases where there's only one geoPoint selected.
      if (geoPoints.length === 1) {
        const singlePointCenter = getSinglePoint(geoPoints)
        return {
          center: singlePointCenter,
          zoom: 11,
        }
      } else if (geoPoints.length > 0) {
        // Use helper function to get bounds based on geopoints
        const boundsFromPoints = getBounds(geoPoints)

        if (boundsFromPoints.hasOwnProperty("multibounds")) {
          const { ne, sw, ...bounds } = boundsFromPoints.multibounds
          // Get the size of the map container
          const size = {
            width: mapContainerRef.current.clientWidth,
            height: mapContainerRef.current.clientHeight,
          }

          // Use fitBounds helper function to calculate new bounds and zoom
          const raw_fit = fitBounds(bounds, size)
          // Modify fitBounds output for better UX
          const { center, zoom } = useRawFit
            ? raw_fit
            : uxModFitBounds(
                raw_fit,
                customFit
                  ? customFit
                  : {
                      zoomFactor: isPhoneOrTablet
                        ? 82
                        : forceAnimate
                        ? 87
                        : 100,
                      offsetFactor: isPhoneOrTablet ? [0, 0] : [0, 0],
                    }
              )
          return {
            center,
            zoom: isPhoneOrTablet && !forceAnimate ? zoom + 0.5 : zoom - 1,
          }
        }
        const singlePointCenter = getSinglePoint(geoPoints)
        return {
          center: singlePointCenter,
          zoom: 15,
        }
        // Set new bounds and zoom.
      } else {
        return {
          zoom: defaultZoom,
          center: defaultCenter,
        }
      }
    } else {
      // If no points are selected, show default zoom.
      return {
        center: defaultCenter,
        zoom: defaultZoom,
      }
    }
  }

  function onUpdateZoomAndCenterHandler() {
    const { zoom, center } = getZoomAndCenter()
    if (geoPoints.length === 0) {
      setZoomAndCenter({ zoom: defaultZoom, center: defaultCenter })
    } else {
      setZoomAndCenter({ zoom, center })
    }

    if (isPhoneOrTablet) {
      setZoomAndCenter({ zoom, center })
    }
  }

  function onChangeHandler(options) {
    /* const { zoom, center } = options
    setZoomAndCenter({ zoom: zoom, center: center }) */
    if (onChange && !omitOnChangeAfterUserInteraction) {
      onChange(options)
    } else if (onChange && !userInteractedRef.current) {
      onChange(options)
    }
  }

  const onGoogleApiLoadedHandler = useCallback(
    ({ map, maps }) => {
      if (onGoogleApiLoaded) onGoogleApiLoaded({ map, maps })
      googleMapsRef.current = { map, maps }
      setMapOptions()
    },
    [allowScroll, vp]
  )

  const handleGeopointsUpdate = () => {
    const newIDs = geoPoints.map((p) => p.id)
    if (!isEqual(newIDs, geoPointIDs.current)) {
      geoPointIDs.current = newIDs
      userInteractedRef.current = false
    }
    if (geoPoints.length === 0 && !userInteractedRef.current) {
      if (googleMapsRef.current && googleMapsRef.current.map) {
        googleMapsRef.current.map.panTo(defaultCenter)
        googleMapsRef.current.map.setZoom(defaultZoom)
      }
      setZoomAndCenter({ zoom: defaultZoom, center: defaultCenter })
    } else {
      onUpdateZoomAndCenterHandler()
    }
  }

  // const zoomAndCenter = getZoomAndCenter()

  const renderedZones = useRef<google.maps.Polygon[]>([])
  const renderedOverlays = useRef<google.maps.OverlayView[]>([])

  const createZoneOverlay = (zone: any, map: google.maps.Map) => {
    const coordinates = zone.Coordinates ?? zone.coordinates
    // Find the northernmost point
    const northernmostPoint = coordinates.reduce((prev: any, curr: any) => {
      return prev.lat > curr.lat ? prev : curr
    })

    class ZoneOverlay extends google.maps.OverlayView {
      private div: HTMLDivElement | null = null

      onAdd() {
        const div = document.createElement('div')
        div.style.position = 'absolute'
        div.style.backgroundColor = 'white'
        div.style.padding = '5px'
        div.style.boxShadow = '0 2px 6px rgba(0,0,0,0.3)'
        div.style.zIndex = '1000'
        div.style.borderRadius = '4px'

        // Create content container
        const content = document.createElement('div')
        div.appendChild(content)

        // Create arrow element
        const arrow = document.createElement('div')
        arrow.style.position = 'absolute'
        arrow.style.left = '50%'
        arrow.style.bottom = '-8px'
        arrow.style.transform = 'translateX(-50%)'
        arrow.style.width = '0'
        arrow.style.height = '0'
        arrow.style.borderLeft = '8px solid transparent'
        arrow.style.borderRight = '8px solid transparent'
        arrow.style.borderTop = '8px solid white'
        div.appendChild(arrow)
        
        this.div = div
        const panes = this.getPanes()
        panes?.overlayLayer.appendChild(div)
      }

      draw() {
        if (!this.div) return
        const overlayProjection = this.getProjection()
        const position = overlayProjection.fromLatLngToDivPixel(
          new google.maps.LatLng(northernmostPoint.lat, northernmostPoint.lng)
        )
        
        if (position) {
          const zoom = this.getMap()?.getZoom() || 0
          const content = this.div.firstChild as HTMLDivElement
          
          if (zoom < 10) {
            content.innerHTML = zone.name ? `${zone.name}` : ''
            this.div.style.fontSize = '10px'
            this.div.style.padding = '3px'
            this.div.style.minWidth = '80px'
            this.div.style.maxWidth = '120px'
          } else {
            content.innerHTML = `<strong>${zone.name || ''}</strong>${zone.description ? `<br/>${zone.description}` : ''}`
            this.div.style.fontSize = '12px'
            this.div.style.padding = '5px'
            this.div.style.minWidth = '150px'
            this.div.style.maxWidth = '200px'
          }

          // Common styles
          this.div.style.transform = 'translate(-50%, -130%)' // Adjusted for arrow
          this.div.style.left = position.x + 'px'
          this.div.style.top = position.y + 'px'
          this.div.style.textAlign = 'center'
          this.div.style.pointerEvents = 'none'
          
          // Scale opacity based on zoom (more transparent when zoomed out)
          const opacity = Math.min(1, Math.max(0.6, (zoom - 8) / 4))
          this.div.style.opacity = opacity.toString()
        }
      }

      onRemove() {
        if (this.div) {
          this.div.parentNode?.removeChild(this.div)
          this.div = null
        }
      }
    }

    const overlay = new ZoneOverlay()
    overlay.setMap(map)
    return overlay
  }

  const renderZones = useCallback(() => {
    // Clear existing zones and overlays
    if (renderedZones.current.length > 0) {
      renderedZones.current.forEach((z) => {
        z.setMap(null)
      })
      renderedZones.current = []
    }

    if (renderedOverlays.current.length > 0) {
      renderedOverlays.current.forEach((o) => {
        o.setMap(null)
      })
      renderedOverlays.current = []
    }

    if (googleMapsRef.current?.maps && googleMapsRef.current.map) {
      const activeZones = displayZones.filter((zone) => zone.active)
      renderedZones.current = activeZones.map((zone) => {
        const coordinates = zone.Coordinates ?? zone.coordinates
        const z = new googleMapsRef.current!.maps.Polygon({
          paths: coordinates,
        })

        z.setMap(googleMapsRef.current!.map)
        return z
      })

      // Create overlays for each active zone
      renderedOverlays.current = activeZones.map((zone) => 
        createZoneOverlay(zone, googleMapsRef.current!.map!)
      )
    }
  }, [displayZones, googleMapsRef.current])

  const eventListeners: google.maps.MapsEventListener[] = []

  const setMapOptions = () => {
    if (
      googleMapsRef.current &&
      googleMapsRef.current.map &&
      googleMapsRef.current.maps
    ) {
      for (const event of toggleUserInteractedOnEvents) {
        eventListeners.push(
          googleMapsRef.current.map.addListener(event, (e) => {
            userInteractedRef.current = true
          })
        )
      }

      for (const event of untoggleUserInteractedOnEvents) {
        eventListeners.push(
          googleMapsRef.current.map.addListener(event, (e) => {
            if (!e.domEvent?.relatedTarget?.className?.includes("gm")) {
              userInteractedRef.current = false
            }
          })
        )
      }

      const customOptions: google.maps.MapOptions = {}

      customOptions.scrollwheel = !isViewport(vp, [
        "PHONE_ONLY",
        "TABLET_PORTRAIT_UP",
      ])
        ? allowScroll
        : false

      mapOptions.current = createMapOptions(vp, customOptions)

      googleMapsRef.current.map.setOptions(mapOptions.current)
    }
  }

  useLayoutEffect(() => {
    handleGeopointsUpdate()
  }, [geoPoints])

  useLayoutEffect(() => {
    if (googleMapsRef.current) renderZones()
  }, [displayZones, googleMapsRef.current, renderZones])

  // This useEffect updates the map instance with options.
  useEffect(() => {
    if (googleMapsRef.current && googleMapsRef.current.map) {
      googleMapsRef.current.map.set("scrollwheel", allowScroll)
    }
  }, [googleMapsRef.current, allowScroll, vp])

  useEffect(() => {
    if (googleMapsRef.current && googleMapsRef.current.map) {
      dispatch(
        setGoogleMapsInstance(
          googleMapsRef.current.map,
          googleMapsRef.current.maps
        )
      )
    }
  }, [googleMapsRef.current])

  useEffect(() => {
    return () => {
      if (eventListeners.length > 0) {
        for (const listener of eventListeners) {
          listener.remove()
        }
      }
    }
  }, [])

  return (
    <StyledGoogleMapsWrapper ref={mapContainerRef}>
      <GoogleMapReact
        onGoogleApiLoaded={onGoogleApiLoadedHandler}
        bootstrapURLKeys={{
          key: process.env.GATSBY_MAPS_API_KEY,
          libraries: ["drawing", "geometry"],
          language: lang,
          mapIds: ["49f51d48b88a1ee1"],
        }}
        options={{
          mapId: "49f51d48b88a1ee1",
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onChange={onChangeHandler}
        center={forceAnimate || isPhoneOrTablet ? zoomAndCenter.center : null}
        zoom={forceAnimate || isPhoneOrTablet ? zoomAndCenter.zoom : null}
        yesIWantToUseGoogleMapApiInternals
        {...props}
      >
        {children}
      </GoogleMapReact>
    </StyledGoogleMapsWrapper>
  )
}

export default GoogleMap

GoogleMap.defaultProps = {
  maxZoom: 18,
  children: null,
  geoPoints: [],
}

GoogleMap.propTypes = {
  children: PropTypes.node,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  geoPoints: PropTypes.array,
}
