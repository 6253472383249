import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import Datepicker from "lib/Datepicker"
import "twin.macro"
import Input from "lib/Input"
import { format, isSameDay } from "date-fns"
import { default as _isEqual } from "lodash-es/isEqual"
import { FormField, Button, Responsive } from "@clevertrack/shared"
import styled from "styled-components"
import useOnClickOutside from "hooks/useOnClickOutside"

import DatepickerDropdown from "../DatepickerDropdown"
import { useTranslation } from "react-i18next"

const StyledDateFilter = styled.div`
  position: relative;
  // z-index: 3000;

  & + & {
    margin-top: 2rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    // width: 50%;
    & + & {
      margin-top: 0;
      margin-left: -1px;
    }
  `}
`

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
`

const StyledTimepicker = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: center;
`

const StyledCancelButton = styled(Button)`
  font-size: 1.2rem;
  padding: 0 1rem 0 0;
`

const StyledDatepickerDropdown = styled(DatepickerDropdown)`
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  position: absolute;
  top: 100%;
  z-index: 2000;
  ${(props) => (props.dropdownAttachment === "right" ? `right: 0;` : ``)}

  > div {
    padding-bottom: 1rem;
  }
`

const StyledInput = styled(Input)`
  border: none;
  -webkit-appearance: none;
  display: block;
  a {
    color: ${(props) => props.theme.baseBodyColor};
  }

  [type="date"] {
    color: ${(props) => props.theme.baseBodyColor};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &::-webkit-input-placeholder {
      visibility: hidden !important;
    }
  `}
`

enum DropDownAttachment {
  Right = "right",
  Left = "left",
  Top = "top",
  Bottom = "bottom",
}

interface IDateFilterProps {
  date?: Date
  label?: string
  dateLower?: Date
  dateUpper?: Date
  onSelectDate: (date: any) => {}
  dropdownAttachment?: DropDownAttachment
  toggled?: boolean
  children?: React.ReactNode
  inputComponent?: React.ReactElement
  readOnly?: boolean
  closeOnSelect?: boolean
  untoggleOnBlur?: boolean
}

const DateFilter: React.FC<IDateFilterProps> = ({
  date,
  label,
  dateLower,
  dateUpper,
  onSelectDate,
  dropdownAttachment,
  children,
  inputComponent: InputComponent,
  readOnly = false,
  closeOnSelect = false,
  untoggleOnBlur = false,
  ...props
}) => {
  const [toggled, setToggled] = useState(false)
  const inputRef = useRef(null)
  const dpRef = useRef(null)
  const { t } = useTranslation()

  useOnClickOutside(dpRef, () => setToggled(false), { desktop: "mousedown" })

  const onChangeHandler = ({ startDate }) => {
    // e = event
    // type = date or time
    if (!isSameDay(startDate, date)) {
      onSelectDateHandler(startDate)
    }
  }

  function onCancelHandler() {
    setToggled(false)
  }

  function onConfirmHandler() {
    // onSelectDate(currentDate, currentTime)
    setToggled(false)
  }

  function onFocusHandler() {
    if (!readOnly) setToggled(true)
  }

  function onCustomInputClickHandler() {
    if (!readOnly) inputRef.current.focus()
  }

  function onBlurHandler() {
    if (untoggleOnBlur) {
      setToggled(false)
    }
  }

  function onSelectDateHandler(d) {
    onSelectDate(d)
    if (closeOnSelect) {
      setToggled(false)
    }
  }

  let selectedDate
  try {
    selectedDate = date
      ? format(new Date(date), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd")
  } catch (error) {}

  return (
    <StyledDateFilter ref={dpRef}>
      <Responsive
        phone={
          InputComponent ? (
            <>
              <InputComponent.type
                onClick={onCustomInputClickHandler}
                {...InputComponent.props}
              />
              <StyledInput
                tw="absolute p-0 border-0 left-0 top-0 opacity-0 m-0"
                forwardRef={inputRef}
                type="date"
                value={selectedDate}
                onChange={(e) => onSelectDate(new Date(e.target.value))}
              />
            </>
          ) : (
            <FormField label={label}>
              <StyledInput
                forwardRef={inputRef}
                type="date"
                tw="bg-white leading-10 inline-block"
                // onFocus={() => toggleDatepicker()}
                value={selectedDate}
                onChange={(e) => onSelectDate(new Date(e.target.value))}
              />
            </FormField>
          )
        }
        desktop={
          <>
            {InputComponent ? (
              <InputComponent.type
                ref={inputRef}
                onClick={onFocusHandler}
                {...InputComponent.props}
              />
            ) : (
              <FormField label={label}>
                <StyledInput
                  tw="bg-white"
                  forwardRef={inputRef}
                  type="date"
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                  value={selectedDate}
                  readOnly={true}
                />
              </FormField>
            )}
            <StyledDatepickerDropdown
              dropdownAttachment={dropdownAttachment}
              toggled={toggled}
            >
              <Datepicker
                onChange={(d) => toggled && onChangeHandler(d)}
                initialVisibleMonth={date}
                startDate={date}
                datepickerProps={{
                  minBookingDate: dateLower,
                  maxBookingDate: dateUpper,
                }}
              />
              <StyledFooter>
                <StyledCancelButton
                  size="sm"
                  variant="transparent"
                  onClick={onCancelHandler}
                  tabIndex={-1}
                >
                  {t("common.cancel")}
                </StyledCancelButton>
                <Button
                  type="button"
                  size="sm"
                  variant="primary"
                  onClick={onConfirmHandler}
                  tabIndex={-1}
                >
                  {t("common.ok")}
                </Button>
              </StyledFooter>
            </StyledDatepickerDropdown>
          </>
        }
      />
    </StyledDateFilter>
  )
}

export default DateFilter

DateFilter.defaultProps = {
  dateLower: null,
  dateUpper: null,
  label: "Vælg dato",
}
DateFilter.propTypes = {
  children: PropTypes.node,
}
