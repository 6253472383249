import React, { FC, MouseEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import tw from "twin.macro"
import styled from "styled-components"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Button } from "@clevertrack/shared"
import { Badge } from "lib/Badge"
import { ZonesItem } from "./ZonesItem"
import { IZone } from "../zones.types"
import { ZonesEmptyState } from "./ZonesEmptyState"

interface ZonesHiddenSectionProps {
  hiddenZones: IZone[]
  showHiddenZones: boolean
  setShowHiddenZones: (show: boolean) => void
  zonesEnabled: boolean
  editMode: boolean
  toggleEditMode: () => void
  onZoneClick: (zone: IZone) => void
  onToggleVisibility: (zoneId: string) => void
  isZonesToggleOn: boolean
}

const SectionContainer = styled.div`
  ${tw`w-full`}
`

const HeaderContainer = styled.div<{ isZonesToggleOn: boolean }>`
  ${tw`p-2 flex items-center justify-between`}
  ${({ isZonesToggleOn }) =>
    isZonesToggleOn ? tw`cursor-pointer hover:bg-gray-50` : tw`cursor-default`}
`

const HeaderContent = styled.div`
  ${tw`flex items-center text-lg`}
`

const ChevronIcon = styled(Icon)<{ isOpen: boolean; isZonesToggleOn: boolean }>`
  ${tw`mr-2 transition-transform`}
  ${({ isOpen, isZonesToggleOn }) => isOpen && isZonesToggleOn && tw`transform rotate-90`}
  ${({ isZonesToggleOn }) => !isZonesToggleOn && tw`text-gray-300`}
`

const SectionTitle = styled.span<{ isZonesToggleOn: boolean }>`
  ${({ isZonesToggleOn }) => isZonesToggleOn ? tw`text-gray-500` : tw`text-gray-300`}
`

const ZoneCount = styled(Badge)<{ isZonesToggleOn: boolean }>`
  ${({ isZonesToggleOn }) => isZonesToggleOn ? tw`text-gray-500` : tw`text-gray-300`}
  ${tw`ml-3 bg-gray-100 px-2 py-0.5 font-normal border-2 border-solid border-gray-200 text-lg rounded-full`}
`

const EditButton = styled(Button)<{ isZonesToggleOn: boolean }>`
  ${tw`h-auto px-2 text-lg text-gray-500 font-normal hover:text-black`}
  border-radius: 0;
  ${({ isZonesToggleOn }) => !isZonesToggleOn && tw`opacity-50 pointer-events-none`}
`

const ZonesContainer = styled.div`
  ${tw`bg-gray-50`}
`

export const ZonesHiddenSection: FC<ZonesHiddenSectionProps> = ({
  hiddenZones,
  editMode,
  toggleEditMode,
  onZoneClick,
  onToggleVisibility,
  isZonesToggleOn,
}) => {
  const { t } = useTranslation()
  const [showHiddenZones, setShowHiddenZones] = useState(false)

  const handleHeaderClick = () => {
    setShowHiddenZones(!showHiddenZones)
  }

  const handleEditClick = (e: MouseEvent) => {
    e.stopPropagation()
    toggleEditMode()
  }

  return (
    <SectionContainer>
      <HeaderContainer isZonesToggleOn={isZonesToggleOn} onClick={handleHeaderClick}>
        <HeaderContent>
          <ChevronIcon
            isZonesToggleOn={isZonesToggleOn}
            icon={showHiddenZones ? "chevron-down" : "chevron-right"}
            size={IconSizeEnum.SM}
            isOpen={showHiddenZones}
          />
          <SectionTitle isZonesToggleOn={isZonesToggleOn}>
            {t("zones.hidden_zones")}
          </SectionTitle>
          <ZoneCount isZonesToggleOn={isZonesToggleOn}>{hiddenZones.length}</ZoneCount>
        </HeaderContent>

        {!editMode && (
          <EditButton variant="transparent" size="sm" onClick={handleEditClick} isZonesToggleOn={isZonesToggleOn}> 
            {t("common.edit")}
          </EditButton>
        )}
      </HeaderContainer>

      {showHiddenZones && (
        <ZonesContainer>
          {hiddenZones.length === 0 ? (
            <ZonesEmptyState isVisible={false} />
          ) : (
            hiddenZones.map((zone) => (
              <ZonesItem
                key={zone.id}
                zone={zone}
                isVisible={false}
                isEditMode={editMode}
                onToggleVisibility={onToggleVisibility}
                onZoneClick={() => onZoneClick(zone)}
              />
            ))
          )}
        </ZonesContainer>
      )}
    </SectionContainer>
  )
}
