export const useSmoothZoomAndPan = () => {
  /**
   * Handy functions to project lat/lng to pixel
   * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
   */
  const project = (latLng) => {
    const TILE_SIZE = 256

    let siny = Math.sin((latLng.lat() * Math.PI) / 180)

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999)

    return new google.maps.Point(
      TILE_SIZE * (0.5 + latLng.lng() / 360),
      TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
    )
  }

  /**
   * Handy functions to project lat/lng to pixel
   * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
   */
  const getPixel = (latLng, zoom) => {
    const scale = 1 << zoom
    const worldCoordinate = project(latLng)
    return new google.maps.Point(
      Math.floor(worldCoordinate.x * scale),
      Math.floor(worldCoordinate.y * scale)
    )
  }

  /**
   * Given a map, return the map dimension (width and height)
   * in pixels.
   */
  const getMapDimenInPixels = (map) => {
    const zoom = map.getZoom()
    const bounds = map.getBounds()
    const southWestPixel = getPixel(bounds.getSouthWest(), zoom)
    const northEastPixel = getPixel(bounds.getNorthEast(), zoom)
    return {
      width: Math.abs(southWestPixel.x - northEastPixel.x),
      height: Math.abs(southWestPixel.y - northEastPixel.y),
    }
  }

  /**
   * Given a map and a destLatLng returns true if calling
   * map.panTo(destLatLng) will be smoothly animated or false
   * otherwise.
   *
   * optionalZoomLevel can be optionally be provided and if so
   * returns true if map.panTo(destLatLng) would be smoothly animated
   * at optionalZoomLevel.
   */
  const willAnimatePanTo = (map, destLatLng, optionalZoomLevel = null) => {
    const dimen = getMapDimenInPixels(map)

    const mapCenter = map.getCenter()
    optionalZoomLevel = optionalZoomLevel !== null ? optionalZoomLevel : map.getZoom()

    const destPixel = getPixel(destLatLng, optionalZoomLevel)
    const mapPixel = getPixel(mapCenter, optionalZoomLevel)
    const diffX = Math.abs(destPixel.x - mapPixel.x)
    const diffY = Math.abs(destPixel.y - mapPixel.y)

    return diffX < dimen.width && diffY < dimen.height
  }

  /**
   * Returns the optimal zoom value when animating
   * the zoom out.
   *
   * The maximum change will be currentZoom - 3.
   * Changing the zoom with a difference greater than
   * 3 levels will cause the map to "jump" and not
   * smoothly animate.
   */
  const getOptimalZoomOut = (map, latLng, currentZoom) => {
    if (willAnimatePanTo(map, latLng, currentZoom - 1)) {
      return currentZoom - 1
    } else if (willAnimatePanTo(map, latLng, currentZoom - 2)) {
      return currentZoom - 2
    } else {
      return currentZoom - 3
    }
  }

  /**
   * Creates a promise that resolves when a specified event occurs on the map
   */
  const createMapEventPromise = (map, eventName) => {
    return new Promise((resolve) => {
      const listener = google.maps.event.addListenerOnce(map, eventName, () => {
        resolve(void 0)
      })

      // Add timeout to prevent hanging if event doesn't fire
      setTimeout(() => {
        google.maps.event.removeListener(listener)
        resolve(void 0)
      }, 180)
    })
  }

  /**
   * Smoothly changes the zoom level by one step at a time
   * @param map - The Google Maps instance
   * @param targetZoom - The final zoom level to reach
   * @param stepDelay - Delay between zoom steps in ms
   * @returns Promise that resolves when zooming is complete
   */
  const smoothZoom = async (map, targetZoom, stepDelay = 150) => {
    const currentZoom = map.getZoom()

    if (currentZoom === targetZoom) {
      return Promise.resolve()
    }

    const isZoomingIn = targetZoom > currentZoom
    const step = isZoomingIn ? 1 : -1

    // Disable controls during animation
    const originalOptions = {
      draggable: map.get('draggable'),
      zoomControl: map.get('zoomControl'),
      scrollwheel: map.get('scrollwheel'),
      disableDoubleClickZoom: map.get('disableDoubleClickZoom')
    }

    map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true
    })

    let nextZoom = currentZoom

    try {
      while (isZoomingIn ? nextZoom < targetZoom : nextZoom > targetZoom) {
        nextZoom += step
        map.setZoom(nextZoom)
        await Promise.all([
          createMapEventPromise(map, 'idle'),
          new Promise(resolve => setTimeout(resolve, stepDelay))
        ])
      }
    } finally {
      // Restore original map options
      map.setOptions(originalOptions)
    }

    return Promise.resolve()
  }

  /**
   * Smoothly pans and zooms the map to a destination
   * @param map - The Google Maps instance
   * @param destLatLng - The destination LatLng
   * @param targetZoom - Optional target zoom level
   * @returns Promise that resolves when animation completes
   */
  const smoothlyAnimatePanTo = async (
    map: google.maps.Map,
    destLatLng,
    targetZoom?: number
  ) => {
    if (!map) return Promise.resolve()

    const currentZoom = map.getZoom()

    if (!willAnimatePanTo(map, destLatLng)) {
      const originalOptions = {
        draggable: map.get('draggable'),
        zoomControl: map.get('zoomControl'),
        scrollwheel: map.get('scrollwheel'),
        disableDoubleClickZoom: map.get('disableDoubleClickZoom')
      }

      map.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true
      })

      try {
        map.panTo(destLatLng)
        await createMapEventPromise(map, 'idle')

        const finalZoom = targetZoom !== undefined ? targetZoom : currentZoom
        await smoothZoom(map, finalZoom)
      } finally {
        map.setOptions(originalOptions)
      }
    } else {
      map.panTo(destLatLng)
      await createMapEventPromise(map, 'idle')

      if (targetZoom !== undefined && targetZoom !== currentZoom) {
        await smoothZoom(map, targetZoom)
      }
    }

    return Promise.resolve()
  }

  return {
    smoothlyAnimatePanTo,
    smoothZoom,
    willAnimatePanTo
  }
}