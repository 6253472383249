import React, { useContext } from "react"
import { FormField, ValidationInput } from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"
import { useTranslation } from "react-i18next"
import { UserContext } from "app/User/context"

function EmailForm({ ...props }) {
  const { t } = useTranslation()
  const {
    state: { user },
  } = useContext(UserContext)
  const lang = user?.language ? user.language.split("-")[0] : "da"

  const validationMessages = {
    ...getDefaultValidationMessagesByLang(lang),
    customError: t("error.non_valid_email"),
  }

  return (
    <FormField validationKey="email" label="Email">
      <ValidationInput
        required
        id="email"
        type="email"
        placeholder={t("user.form_email_placeholder")}
        customValidation={emailValidation}
        validationMessages={validationMessages}
      />
    </FormField>
  )
}

export default EmailForm

EmailForm.defaultProps = {}
EmailForm.propTypes = {}
