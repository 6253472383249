import React, { useContext, useEffect } from "react"
import "twin.macro"
import styled from "styled-components"
import { useConversation } from "app/Conversations/hooks"
import { ContactList } from "app/Conversations/Contact/List"
import Panel from "app/Panel"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Button } from "@clevertrack/shared"

import { IConversationsSharedProps } from "app/Conversations"
import {
  PopOverRoutesEnum,
  IContact,
  IContactOrContactGroup,
  IContactGroup,
  RouteTypeEnum,
} from "app/Conversations/conversations.types"
import { ContactSearch } from "app/Conversations/Contact/Search"
import { ContactItem } from "app/Conversations/Contact/Item"
import {
  ConversationActions,
  ConversationTypes,
} from "app/Conversations/actions"
import { StyledGrid } from "../StyledGrid"
import { SearchContext } from "app/Search/context"
import { SearchActions, SearchTypes } from "app/Search/actions"
import useConversationStore from "app/Conversations/store"
import { useTranslation } from "react-i18next"

const StyledHeader = styled.header``

interface IContactsProps extends IConversationsSharedProps {
  children?: React.ReactNode
}

export const Contacts: React.FC<IContactsProps> = ({
  onNavigate,
  onToggleScreen,
  ...props
}) => {
  const { dispatch: searchDispatch } = useContext(SearchContext)
  const { toggledRoutes, dispatch } = useConversationStore((state) => ({
    toggledRoutes: state.toggledRoutes,
    dispatch: state.dispatch,
  }))

  const { t } = useTranslation()

  const { contactsAndGroupsSorted } = useConversation()

  const onEditContact = (c: IContact) => {
    dispatch(ConversationActions(ConversationTypes.EditContact, { contact: c }))
  }

  const onEditGroup = (g: IContactGroup) => {
    dispatch(
      ConversationActions(ConversationTypes.SetSelectedContacts, {
        contacts: g.contact_ids,
      })
    )
    dispatch(ConversationActions(ConversationTypes.EditGroup, { group: g }))
  }

  const onContactListItemSelectHandler = (c: IContactOrContactGroup) => {
    const isGroup = c.hasOwnProperty("contact_ids")
    if (!isGroup) onEditContact(c as IContact)
    else if (isGroup) onEditGroup(c as IContactGroup)
  }

  useEffect(() => {
    if (toggledRoutes.includes(RouteTypeEnum.Conversations)) {
      searchDispatch(SearchActions(SearchTypes.ResetSearch))
    }
  }, [toggledRoutes])

  return (
    <StyledGrid>
      <StyledHeader>
        <ContactSearch
          tw="mt-4"
          withPhoneBorder
          placeholder={t("conversation.find_contact_or_enter_phone_number")}
        />
      </StyledHeader>
      {contactsAndGroupsSorted.length > 0 ? (
        <ContactList onSearchResultSelect={onContactListItemSelectHandler}>
          {contactsAndGroupsSorted.map((contact) => {
            return (
              <ContactItem
                key={contact.id}
                contact={contact}
                onSelect={onContactListItemSelectHandler}
              />
            )
          })}
        </ContactList>
      ) : (
        <Panel tw="flex flex-col items-center pt-24">
          <span tw="w-24 h-24 bg-gray-200 flex text-gray-600 items-center justify-center rounded-full">
            <Icon icon="people" size={IconSizeEnum.LG} />
          </span>
          <h3>{t("conversation.no_contacts")}</h3>
          <Button
            variant="primary"
            onClick={() => onToggleScreen(PopOverRoutesEnum.CreateContact)}
          >
            {t("conversation.create_first_contact")}
          </Button>
        </Panel>
      )}
    </StyledGrid>
  )
}
