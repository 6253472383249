import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore"
import { UserContext } from "app/User/context"

interface ZoneVisibility {
  hiddenZones: string[]
  updated: any
}

export const useFirestoreZones = () => {
  const { db } = useContext(FirebaseContext)
  const {
    state: { user },
  } = useContext(UserContext)

  if (!db) {
    throw new Error("Firebase context not found")
  }

  const getZoneVisibility = async (userId: string) => {
    try {
      const userRef = doc(db, `users/${userId}`)
      const userDoc = await getDoc(userRef)
  
      if (!userDoc.exists()) {
        console.log("User document not found")
        return { hiddenZones: [] as string[] }
      }
  
      const userData = userDoc.data()
      
      if (!userData.zones) {
        console.log("Zones not found in user document, setting default")
        await updateDoc(userRef, {
          zones: {
            hiddenZones: [] as string[],
            updated: serverTimestamp(),
          }
        })
        console.log("Zones set to default in user document")
        return { hiddenZones: [] as string[] }
      }
  
      return userData.zones as ZoneVisibility
    } catch (error) {
      console.error("Error getting zone visibility:", error)
      return { hiddenZones: [] as string[] }
    }
  }

  const updateZoneVisibility = async (hiddenZones: string[]) => {
    if (!user?.id) return
  
    try {
      const userRef = doc(db, `users/${user.id}`)
      await updateDoc(userRef, {
        zones: {
          hiddenZones,
          updated: serverTimestamp(),
        }
      })
    } catch (error) {
      console.error("Error updating zone visibility:", error)
    }
  }

  return {
    getZoneVisibility,
    updateZoneVisibility,
  }
}