import React, { useState, useContext, useEffect, useMemo } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { HistoryContext } from "../context"
import {
  ITrackerDailyLog,
  HistoryHintEnum,
  HistoryLogViewSettings,
} from "../history.types"
import { analyseData } from "../helper"
import { useHistory } from "routes/HistoryV2/hooks"

import { TrackerLog } from "./TrackerLog"
import { Hint } from "app/Hint"
import { ErrorBoundary } from "app/ErrorBoundary"
import { Loading } from "@clevertrack/shared"
import { useDevices } from "app/Device/hooks"
import { useTranslation } from "react-i18next"
const StyledMessage = styled.div`
  /* ${tw`flex justify-center items-center flex-col h-full text-center`} */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
  height: 100%;

  p {
    font-size: 1.8rem;
    opacity: 0.5;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }
`

export const HistoryLog: React.FC = ({ ...props }) => {
  const {
    state: { history, filters, loadingHistoryLogs, logViewSettings },
  } = useContext(HistoryContext)
  const { toggledDevices, devices } = useDevices(false)
  const { getCurrentTrip } = useHistory()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [tracker, setTracker] = useState(null)
  const [currentTripData, setCurrentTripData] = useState(null)

  const analyseTrackerData = () => {
    if (currentTripData === null && history[0]) {
      getCurrentTrip(history[0].vehicle_id.toString(), (data) => {
        setCurrentTripData(data)
      })
    }
  }

  useMemo(() => {
    if (history[0]?.vehicle_id) {
      const tr = toggledDevices.find((r) => history[0].vehicle_id === +r.id)
      if (history[0].data.logs.length > 0) {
        const days: ITrackerDailyLog[] = analyseData(
          history[0].data.logs,
          filters.date,
          tr,
          currentTripData,
          logViewSettings as HistoryLogViewSettings
        )
        setTracker({
          tracker: tr,
          history: days,
        })
      } else {
        setTracker({
          tracker: tr,
          history: [],
        })
      }
    }
  }, [currentTripData, toggledDevices, history[0], logViewSettings])

  useEffect(() => {
    if (toggledDevices[0]?.id) analyseTrackerData()
  }, [history, filters, toggledDevices[0]?.id])

  useEffect(() => {
    setCurrentTripData(null)
  }, [toggledDevices])

  if (loadingHistoryLogs) {
    return (
      <StyledMessage>
        <Loading tw="bg-transparent" />
      </StyledMessage>
    )
  }

  return !tracker ? (
    <StyledMessage>
      <p>{t("history.no_device_selected")}</p>
    </StyledMessage>
  ) : (
    <ErrorBoundary>
      {loading && (
        <StyledMessage>
          <Loading />
        </StyledMessage>
      )}
      {tracker.tracker && (
        <>
          <Hint type={HistoryHintEnum.NoActivityDays} tw="mb-4" />
          <TrackerLog history={tracker.history} tracker={tracker.tracker} />
        </>
      )}
    </ErrorBoundary>
  )
}
