import React, { useContext, useMemo } from "react"
import { v4 } from "uuid"
import "twin.macro"
import styled from "styled-components"
import {
  AlarmSetupPanels,
  AlertCriterium,
  AlertCriteriumTypeEnum,
} from "routes/Alarms/alarm.types"
import { AlarmsContext } from "routes/Alarms/context"
import { AlarmActions, AlarmTypes } from "routes/Alarms/actions"
import { Group as ListGroup } from "lib/List/Group"
import Icon from "app/Icon"
import { EditorPanelHeader } from "../editor/Header"
import { StyledEditIcon } from "../AlarmForm"
import { DeviceStatusCriteria } from "./DeviceStatus"
import { DeviceZoneCriteria } from "./DeviceZone"
import { DeviceSpeedCriteria } from "./DeviceSpeed"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { DeviceHealthCriteria } from "./DeviceHealth"
import { Responsive } from "@clevertrack/shared"
import PopOver from "app/PopOver"
import { Flow, IScreen } from "app/Flow"
import { useTranslation } from "react-i18next"

type CriteriaEditorProps = {}

type CriteriaMeta = {
  title: string
  description: string
  icon: string
}

type DefaultCriteria = {
  criteria: AlertCriterium
  meta: CriteriaMeta
}

const StyledCriteriaEditor = styled.div`
  z-index: 100;
`

export const NewCriteriaSelector: React.FC = () => {
  const { dispatch } = useContext(AlarmsContext)
  const { t } = useTranslation()

  const defaultCriteria = useMemo<DefaultCriteria[]>(
    () => [
      {
        criteria: {
          id: "default_status",
          type: AlertCriteriumTypeEnum.DeviceStatus,
          triggerFromStatus: [],
          triggerOnStatus: [],
          created: 0,
        },
        meta: {
          title: t("alarms.criteria_status_title"),
          icon: "signal-stream",
          description: t("alarms.criteria_status_description"),
        },
      },
      {
        criteria: {
          id: "default_zone",
          type: AlertCriteriumTypeEnum.DeviceZone,
          triggerOnEnter: false,
          triggerOnLeave: false,
          triggerOnAtLocation: false,
          triggerOnOutsideLocation: false,
          zoneID: "",
          created: 0,
        },
        meta: {
          title: t("alarms.criteria_zone_title"),
          icon: "layer-plus",
          description: t("alarms.criteria_zone_description"),
        },
      },
      {
        criteria: {
          id: "default_health",
          type: AlertCriteriumTypeEnum.DeviceHealth,
          created: 0,
          connectionLost: false,
          powerLost: false,
          powerTreshold: null,
          powerTresholdUnder: false,
        },
        meta: {
          title: t("alarms.criteria_health_title"),
          icon: "heartbeat",
          description: t("alarms.criteria_health_description"),
        },
      },
      {
        criteria: {
          id: "default_speed",
          type: AlertCriteriumTypeEnum.DeviceSpeed,
          treshold: 80,
          created: 0,
        },
        meta: {
          title: t("alarms.criteria_speed_title"),
          icon: "speed",
          description: t("alarms.criteria_speed_description"),
        },
      },
      /* {
      criteria: {
        id: "default_update",
        type: AlertCriteriumTypeEnum.DeviceUpdate,
        duration: 3600 * 24 * 7, // Seven day default
        created: 0,
      },
      meta: {
        title: t("criteria_update_title"),
        icon: "clock",
        description: t("criteria_update_description"),
      },
    }, */
    ],
    [t]
  )

  const onSelectNewCriterium = (criterium: AlertCriterium) => {
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentCriterium, {
        criterium: {
          ...criterium,
          id: v4(),
          created: +new Date(),
        },
      })
    )
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
        panel: AlarmSetupPanels.CriteriaEditor,
      })
    )
  }

  const onBackHandler = () => {
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
        panel: null,
      })
    )
  }

  return (
    <>
      <EditorPanelHeader onBack={onBackHandler}>
        {t("alarms.new_criterium")}
      </EditorPanelHeader>
      <ListGroup tw="space-y-4" as="div">
        {defaultCriteria.map((criteria, i) => {
          return (
            <AlarmConfigurationComponent
              key={criteria.criteria.id}
              tw="border-b flex"
              onClick={() => onSelectNewCriterium(criteria.criteria)}
            >
              <Icon tw="mr-4 self-center" icon={criteria.meta.icon} />
              <span tw="flex flex-col flex-grow">
                <span tw="flex text-2xl">
                  <span tw="text-xl font-bold">{criteria.meta.title}</span>
                  <span
                    tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
                    onClick={() => onSelectNewCriterium(criteria.criteria)}
                  >
                    <span tw="text-lg">{t("common.select")}</span>
                    <StyledEditIcon icon="chevron-right" size="sm" />
                  </span>
                </span>
                <span tw="block text-lg opacity-60">
                  {criteria.meta.description}
                </span>
              </span>
            </AlarmConfigurationComponent>
          )
        })}
      </ListGroup>
    </>
  )
}

export const CriteriaEditor: React.FC<CriteriaEditorProps> = ({ ...props }) => {
  const {
    state: { currentCriterium },
    dispatch,
  } = useContext(AlarmsContext)

  const { t } = useTranslation()

  const onSaveCriteriumHandler = (criterium: AlertCriterium) => {
    dispatch(
      AlarmActions(AlarmTypes.UpdateAlarmCriteriumByID, {
        criterium: { ...criterium, updated: +new Date() },
      })
    )
    dispatch(AlarmActions(AlarmTypes.SetCurrentCriterium, { criterium: null }))
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
        panel: null,
      })
    )
  }

  const onCancelCriteriumHandler = () => {
    dispatch(AlarmActions(AlarmTypes.SetCurrentCriterium, { criterium: null }))
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
        panel: null,
      })
    )
  }

  const onBackHandler = () => {
    dispatch(AlarmActions(AlarmTypes.SetCurrentCriterium, { criterium: null }))
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
        panel: AlarmSetupPanels.CriteriaTypeSelector,
      })
    )
  }

  const renderCriterium = (criterium: AlertCriterium) => {
    switch (criterium.type) {
      case AlertCriteriumTypeEnum.DeviceStatus:
        return (
          <DeviceStatusCriteria
            criterium={criterium}
            onSave={onSaveCriteriumHandler}
            onCancel={onCancelCriteriumHandler}
          />
        )
      case AlertCriteriumTypeEnum.DeviceZone:
        return (
          <DeviceZoneCriteria
            criterium={criterium}
            onSave={onSaveCriteriumHandler}
            onCancel={onCancelCriteriumHandler}
          />
        )
      case AlertCriteriumTypeEnum.DeviceHealth:
        return (
          <DeviceHealthCriteria
            criterium={criterium}
            onSave={onSaveCriteriumHandler}
            onCancel={onCancelCriteriumHandler}
          />
        )
      case AlertCriteriumTypeEnum.DeviceSpeed:
        return (
          <DeviceSpeedCriteria
            criterium={criterium}
            onSave={onSaveCriteriumHandler}
            onCancel={onCancelCriteriumHandler}
          />
        )
      default:
        return null
    }
  }

  const renderMobile = () => {
    const screenSet: IScreen[] = [
      {
        key: "criteria",
        children: (
          <div tw="bg-white p-8 pt-24 pb-64 min-h-full">
            <EditorPanelHeader onBack={onBackHandler}>
              {t("common.edit")}
            </EditorPanelHeader>
            {currentCriterium && renderCriterium(currentCriterium)}
          </div>
        ),
        wrapper: <PopOver fromRight show={!!currentCriterium} />,
      },
    ]
    return <Flow screenSet={screenSet} />
  }

  const renderDesktop = () => {
    return currentCriterium ? (
      <>
        <EditorPanelHeader onBack={onBackHandler}>
          {t("common.edit")}
        </EditorPanelHeader>
        {renderCriterium(currentCriterium)}
      </>
    ) : null
  }

  return <Responsive phone={renderMobile()} tabletLandscape={renderDesktop()} />
}
