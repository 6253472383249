import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Expander from "lib/Expander"
import { IconFlip } from "app/IconFlip"
import TrackerStatus from "../TrackerStatus"
import { signatureMap } from "../TrackerStatus/helper"
import LegendItem from "./Item"
import Icon from "app/Icon"
import { UserVisibilitySettings } from "app/User/types"
import { useUser } from "app/User/hooks"
import { useTranslation } from "react-i18next"
const StyledTrackerLegend = styled.div`
  position: absolute;
  z-index: 120;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  top: 14rem;
  right: 6rem;
  overflow: hidden;
  padding: 0.5rem 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 11rem;
    // padding: 1rem 0;
  `}
`

const StyledExpander = styled(Expander)``

const StyledTrackerStatus = styled(TrackerStatus)`
  width: 1.6rem;
  height: 1.6rem;

  ${(props) =>
    props.isSubState
      ? `transform: scale(0.8, 0.8) rotateZ(45deg);`
      : `transform: scale(0.9, 0.9) rotateZ(45deg);`}

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 2rem;
    height: 2rem;
  `}

  svg {
    width: 1rem;
    height: 1rem;
  }
`

const StyledToggleButton = styled.button`
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 0;
  margin-left: auto;
  border: none;
  padding: 0;
  cursor: pointer;
  background: ${(props) => props.theme.colors.white};
  position: absolute;
  z-index: 120;
  top: 14rem;
  right: 1rem;
  box-shadow: ${(props) => props.theme.mapButtonShadow};
  ${tw`text-brand-black-base fill-current`}

  &:focus {
    outline: none;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 11rem;
  `}
`

const TrackerLegend: React.FC = ({ children, ...props }) => {
  const { toggleUserVisibilitySetting, getUserVisibilitySetting } = useUser()
  const [show, toggleShow] = useState(
    !!getUserVisibilitySetting(UserVisibilitySettings.MapLegend)
  )

  const { t } = useTranslation()

  function onClickHandler() {
    toggleUserVisibilitySetting(UserVisibilitySettings.MapLegend)
    toggleShow((prev) => !prev)
  }

  const renderStatus = (status, isSubState) => {
    return (
      <>
        <LegendItem css={[isSubState ? tw`ml-8 md:(ml-12) text-lg` : ``]}>
          <StyledTrackerStatus status={status.code} isSubState={isSubState}>
            {status.icon && (
              <Icon
                icon={status.icon}
                css={[status?.iconStyle, isSubState ? tw`` : ``]}
              />
            )}
          </StyledTrackerStatus>
          <span css={[isSubState ? tw`md:(-ml-2)` : ``]}>
            {t(status.strKey)}
          </span>
        </LegendItem>
        {status.subStates &&
          status.subStates.map((subState) => (
            <React.Fragment key={`status_${subState.key}`}>
              {renderStatus(subState, true)}
            </React.Fragment>
          ))}
      </>
    )
  }

  return (
    <>
      <StyledToggleButton toggled={!show} onClick={onClickHandler}>
        {
          <IconFlip
            toggled={!show}
            iconOn="close"
            iconOff="map-marker-question"
          />
        }
      </StyledToggleButton>
      <StyledTrackerLegend {...props}>
        <StyledExpander toggled={!show}>
          <div tw="py-2">
            {signatureMap.map((status) => {
              return (
                <React.Fragment key={`status_${status.key}`}>
                  {renderStatus(status, null)}
                </React.Fragment>
              )
            })}
          </div>
        </StyledExpander>
      </StyledTrackerLegend>
    </>
  )
}

export default TrackerLegend
