import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ValidationInput from "@clevertrack/shared/src/lib/ValidationInput"
import { useTranslation } from "react-i18next"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  ValidationInput as CustomValidationInput,
} from "@clevertrack/shared"

const StyledGroupForm = styled(Form)``

function GroupForm({ id, name, description, onSave, onCancel, ...props }) {
  const { t } = useTranslation()
  const [newName, setName] = useState(name)
  const [newDescription, setDescription] = useState(description)

  function onSubmitHandler(e) {
    onSave(id, {
      name: newName,
      description: newDescription,
    })
  }

  return (
    <StyledGroupForm onSubmit={onSubmitHandler}>
      <FormField label={t("common.title")}>
        <CustomValidationInput
          value={newName}
          onChange={(e) => setName(e.target.value)}
          required
          type="text"
          placeholder={t("groups.form_title_placeholder")}
        />
      </FormField>
      <FormField label={t("groups.form_description_label")}>
        <ValidationInput
          renderAsFormField
          as="textarea"
          value={newDescription}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t("groups.form_description_placeholder")}
        />
      </FormField>
      <ButtonGroup position="between">
        <Button invert variant="default" type="button" onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button variant="primary" type="submit">
          {t("groups.form_save")}
        </Button>
      </ButtonGroup>
    </StyledGroupForm>
  )
}

export default GroupForm

GroupForm.defaultProps = {
  name: "",
  description: "",
}
GroupForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
}
