import { ActionMap, createActions } from "lib/reducer.types"
import { IZone, IZoneMeta } from "./zones.types"

/* Action types */
export enum ZonesTypes {
  SetCurrentEditingZone = "Zones/SetCurrentEditingZone",
  SetZoneList = "Zones/SetZoneList",
  SetDisplayZoneList = "Zones/SetDisplayZoneList",
  ToggleZoneOverlay = "Zones/ToggleOverlay",
  ToggleZoneSettings = "Zones/ToggleSettings",
  ResetZoneForm = "Zones/ResetZoneForm",
}

/* Action creators */
type Actions = {
  [ZonesTypes.SetCurrentEditingZone]: { zone: IZone | IZoneMeta }
  [ZonesTypes.SetZoneList]: { list: IZone[] }
  [ZonesTypes.SetDisplayZoneList]: { list: IZone[] }
  [ZonesTypes.ToggleZoneOverlay]: { toggled: boolean }
  [ZonesTypes.ToggleZoneSettings]: { toggled: boolean }
  [ZonesTypes.ResetZoneForm]: null
}

export type ZonesActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const ZonesActions = createActions<Actions>()
