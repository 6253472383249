import React, { useState, useMemo } from "react"
import tw from "twin.macro"
import { DeviceStatusCriterium } from "routes/Alarms/alarm.types"
import Card from "lib/Card"
import TrackerStatus from "app/TrackerStatus"
import { getStatusKey } from "app/TrackerStatus/helper"
import { CriteriumTypeTitle } from "./CriteriumTypeTitle"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { StyledEditIcon } from "../AlarmForm"
import { AlarmConfigurationComponent } from "../AlarmConfigurationComponent"
import { useTranslation } from "react-i18next"

interface IDeviceStatusCriteriaProps {
  criterium: DeviceStatusCriterium
  onSave?: (...args) => void
  onCancel?: (...args) => void
}

interface IDeviceStatusCriteriaElement extends IDeviceStatusCriteriaProps {
  onEdit: (...args) => void
  editing: boolean
}

export const DeviceStatusCriteriaElement: React.FC<IDeviceStatusCriteriaElement> = ({
  criterium,
  onEdit,
  children,
  editing,
}) => {
  const { t } = useTranslation()

  const statusText = useMemo(() => {
    const fromStatuses = criterium.triggerFromStatus
      .map((state, i) => {
        const status = t(getStatusKey(state))
        return i === criterium.triggerFromStatus.length - 1 &&
          criterium.triggerFromStatus.length > 1
          ? `${t("common.or_decapitalize")} ${status.toLowerCase()}`
          : criterium.triggerFromStatus.length > 1
          ? `${status.toLowerCase()},`
          : `${status.toLowerCase()}`
      })
      .join(" ")

    const toStatuses = criterium.triggerOnStatus
      .map((state, i) => {
        const status = t(getStatusKey(state))
        return i === criterium.triggerOnStatus.length - 1 &&
          criterium.triggerOnStatus.length > 1
          ? `${t("common.or_decapitalize")} ${status.toLowerCase()}`
          : criterium.triggerOnStatus.length > 1
          ? `${status.toLowerCase()},`
          : `${status.toLowerCase()}`
      })
      .join(" ")

    return {
      from: fromStatuses,
      to: toStatuses,
    }
  }, [criterium.triggerFromStatus, criterium.triggerOnStatus, t])

  return (
    <AlarmConfigurationComponent tw="border-b-0">
      <span tw="flex text-2xl">
        <span tw="text-xl font-bold">{t("alarms.device_status_title")}</span>
        {children}
        <span
          tw="flex ml-auto items-center justify-center transition-all hover:(text-brand-500)"
          onClick={onEdit}
        >
          {editing ? (
            <>
              <span tw="text-lg text-brand-red-500">{t("common.cancel")}</span>
            </>
          ) : (
            <>
              <span tw="text-lg">{t("common.edit")}</span>
              <StyledEditIcon icon="edit" size="sm" />
            </>
          )}
        </span>
      </span>
      <span tw="block text-lg opacity-60">
        {t("alarms.device_status_fulfilled_when")} {statusText.from}{" "}
        {t("alarms.device_status_to_decapitalize")} {statusText.to}.
      </span>
    </AlarmConfigurationComponent>
  )
}

export const DeviceStatusCriteria: React.FC<IDeviceStatusCriteriaProps> = ({
  criterium = {},
  onSave,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation()
  const possibleStates = useMemo(() => [0, 1, 2, 3, 4, 5], [])

  const [triggerFromStatus, setTriggerFromStatus] = useState(
    criterium?.triggerFromStatus ?? []
  )
  const [triggerOnStatus, setTriggerOnStatus] = useState(
    criterium?.triggerOnStatus ?? []
  )

  const onSelectFromHandler = (state) => {
    const newFromState = triggerFromStatus.includes(state)
      ? [...triggerFromStatus.filter((x) => x !== state)]
      : [...triggerFromStatus, state]

    const newToState = triggerOnStatus.filter((x) => x !== state)

    setTriggerFromStatus(newFromState)
    setTriggerOnStatus(newToState)
  }

  const onSelectToHandler = (state) => {
    const newToState = triggerOnStatus.includes(state)
      ? [...triggerOnStatus.filter((x) => x !== state)]
      : [...triggerOnStatus, state]

    const newFromState = triggerFromStatus.filter((x) => x !== state)

    setTriggerFromStatus(newFromState)
    setTriggerOnStatus(newToState)
  }

  const onSaveHandler = () => {
    if (onSave)
      onSave({
        ...criterium,
        triggerFromStatus,
        triggerOnStatus,
      })
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  return (
    <>
      <CriteriumTypeTitle>{t("alarms.device_status_title")}</CriteriumTypeTitle>
      <p tw="text-xl mt-0 text-brand-gray-base">
        {t("alarms.device_status_description")}
      </p>
      <p tw="mt-8">{t("alarms.device_status_from")}</p>
      <div tw="flex items-center justify-between space-y-0 space-x-1">
        {possibleStates.map((state) => {
          return (
            <Card
              key={state}
              size="sm"
              tw="cursor-pointer p-2 text-lg md:(w-32 h-24 text-xl p-4) flex flex-col items-center hover:opacity-100 transition"
              css={[
                !triggerFromStatus.includes(state) &&
                  tw`shadow-none opacity-60`,
              ]}
              onClick={() => onSelectFromHandler(state)}
            >
              <TrackerStatus status={state} showTitle />
            </Card>
          )
        })}
      </div>
      <p>{t("alarms.device_status_to_decapitalize")}</p>
      <div tw="flex items-center justify-between space-y-0 space-x-1">
        {possibleStates.map((state) => {
          return (
            <Card
              key={state}
              size="sm"
              tw="cursor-pointer p-2 text-lg md:(w-32 h-24 text-xl p-4) flex flex-col items-center hover:opacity-100 transition"
              css={[
                !triggerOnStatus.includes(state) && tw`shadow-none opacity-60`,
              ]}
              onClick={() => onSelectToHandler(state)}
            >
              <TrackerStatus status={state} showTitle />
            </Card>
          )
        })}
      </div>
      {onSave && onCancel && (
        <EditorButtonGroup
          onSave={onSaveHandler}
          onCancel={onCancelHandler}
          saveDisabled={
            triggerFromStatus.length === 0 || triggerOnStatus.length === 0
          }
        />
      )}
    </>
  )
}
