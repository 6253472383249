import React from "react"
import "twin.macro"
import { Button, ButtonGroup, Responsive } from "@clevertrack/shared"
import { useTranslation } from "react-i18next"

type EditorButtonGroupProps = {
  onSave: (...args) => void
  onCancel: (...args) => void
  saveDisabled: boolean
}

export const EditorButtonGroup: React.FC<EditorButtonGroupProps> = ({
  onCancel,
  onSave,
  saveDisabled,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Responsive
      phone={
        <ButtonGroup
          sticky
          tw="mt-16 bg-white"
          style={{ zIndex: 2000 }}
          {...props}
        >
          <Button variant="transparent" onClick={onCancel}>
            {t("common.cancel")}
          </Button>
          <Button variant="primary" disabled={saveDisabled} onClick={onSave}>
            {t("common.save")}
          </Button>
        </ButtonGroup>
      }
      tabletLandscape={
        <ButtonGroup tw="mt-4 bg-white" {...props}>
          <Button variant="transparent" onClick={onCancel}>
            {t("common.cancel")}
          </Button>
          <Button variant="primary" disabled={saveDisabled} onClick={onSave}>
            {t("common.save")}
          </Button>
        </ButtonGroup>
      }
    />
  )
}
